import React from 'react';
import { Card } from 'reactstrap';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import StatCardValue from '../../components/StatCardValue';
import { formatCurrency } from '../../../../../../utils/currencyUtils';

const i18nOpts = { scope: 'components.admin.customers.financialReport.financialReportPlaid.loanPaymentsSection.index' };

const LoanPaymentsSection = ({ financialReport }) => {
  const {
    loanPaymentsCarPayment,
    loanPaymentsPersonalLoanPayment,
    loanPaymentsStudentLoanPayment,
    loanPaymentsCreditCardPayment,
    loanPaymentsOtherPayment
  } = financialReport;

  return (
    <Card body>
      <div className="d-flex align-items-center justify-content-around">
        <StatCardValue title={i18n.t('carPayment', i18nOpts)}>
          {formatCurrency(loanPaymentsCarPayment)}
        </StatCardValue>
        <StatCardValue title={i18n.t('personalLoanPayment', i18nOpts)} showSeparator>
          {formatCurrency(loanPaymentsPersonalLoanPayment)}
        </StatCardValue>
        <StatCardValue title={i18n.t('studentLoanPayment', i18nOpts)} showSeparator>
          {formatCurrency(loanPaymentsStudentLoanPayment)}
        </StatCardValue>
        <StatCardValue title={i18n.t('creditCardPayment', i18nOpts)} showSeparator>
          {formatCurrency(loanPaymentsCreditCardPayment)}
        </StatCardValue>
        <StatCardValue title={i18n.t('otherPayment', i18nOpts)} showSeparator>
          {formatCurrency(loanPaymentsOtherPayment)}
        </StatCardValue>
      </div>
    </Card>
  );
};

LoanPaymentsSection.propTypes = {};

LoanPaymentsSection.defaultProps = {};

export default connect((store) => ({
  financialReport: store.customers.financialReport
}))(LoanPaymentsSection);
