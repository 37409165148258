import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import i18n from 'i18n-js';
import v from 'voca';

import { formatCurrency, isValidAmount } from '../../../../../../../../utils/currencyUtils';
import StatCardValue from '../../../../components/StatCardValue';
import CustomerFinancialTrendBadge
  from '../../../../../../../global/customer/CustomerFinancialTrendBadge';

const i18nOpts = { scope: 'components.admin.customers.financialReport.financialReportFlinks.employersSection.employers.employerCard.index' };

const EmployerCard = ({
  name, income, trend, isPrimary
}) => (
  <Card body>
    <div className="d-flex align-items-center justify-content-around">
      <StatCardValue title={isPrimary ? i18n.t('primaryName', i18nOpts) : i18n.t('secondaryName', i18nOpts)}>
        {name}
      </StatCardValue>

      {isValidAmount(income) && (
        <StatCardValue title={i18n.t('income', i18nOpts)} showSeparator>
          {formatCurrency(income)}
        </StatCardValue>
      )}

      {!v.isBlank(trend) && (
        <StatCardValue title={i18n.t('trend', i18nOpts)} showSeparator>
          <CustomerFinancialTrendBadge trend={trend} />
        </StatCardValue>
      )}
    </div>
  </Card>
);

EmployerCard.propTypes = {
  name: PropTypes.string.isRequired,
  income: PropTypes.number,
  trend: PropTypes.string,
  isPrimary: PropTypes.bool
};

EmployerCard.defaultProps = {
  income: null,
  trend: null,
  isPrimary: false
};

export default EmployerCard;
