import api from '../../../../../api';
import { getUtmMediumReportQuery } from '../../../../../graphql';
import { fakeValue } from '../../../../../utils/sampleDataUtils';
import { innerRingChartColors } from '../utils';

export function fetchUtmMediumReportAsync(filter) {
  const variables = { reportFilter: filter };
  return api.graphql(getUtmMediumReportQuery, variables)
    .then(({ data: { results } }) => results);
}

export function generateSampleDataAsync() {
  let data = [
    { utmMedium: 'Email', visitorsCount: fakeValue(100) },
    { utmMedium: 'Social', visitorsCount: fakeValue(100) }
  ];

  data = data.map((item) => ({
    ...item,
    leadsCount: fakeValue(item.visitorsCount)
  }));
  data.sort((a, b) => b.visitorsCount - a.visitorsCount);
  return Promise.resolve(data);
}

export function getVisitorsChartData(utmMediumData) {
  const colors = innerRingChartColors.slice(0, utmMediumData.length);

  return {
    labels: utmMediumData.map((c) => c.utmMedium),
    datasets: [
      {
        backgroundColor: colors,
        data: utmMediumData.map((c) => c.visitorsCount)
      }
    ]
  };
}

export function getLeadsChartData(utmMediumData) {
  const colors = innerRingChartColors.slice(0, utmMediumData.length);

  return {
    labels: utmMediumData.map((c) => c.utmMedium),
    datasets: [
      {
        backgroundColor: colors,
        data: utmMediumData.map((c) => c.leadsCount)
      }
    ]
  };
}
