export const ModalNames = Object.freeze({
  ELEVATION_FORM: 'elevationForm',
  FLOOR_FORM: 'floorForm',
  FLOOR_GROUP_FORM: 'floorGroupForm',
  FLOOR_OPTION_FORM: 'floorOptionForm',
  SPECIFICATION_FORM: 'specificationForm',
  DIRECT_LINK_MODAL: 'directLinkModal',
  MODEL_DIRECT_LINK_MODAL: 'modelDirectLinkModal',
  PRODUCT_TYPE_SALES_REPRESENTATIVES: 'productTypeSalesRepresentatives',
  FLOOR_OPTION_DEPENDENCY: 'floorOptionDependency',
  FLOOR_OPTION_IMAGES: 'floorOptionImages',
  FLOOR_OPTION_PRICE_RULES: 'floorOptionPriceRules',
  PRODUCT_TYPE_SETTINGS: 'productTypeSettings',
  OPTION_FORM: 'OPTION_FORM',
  PHASE_COMMUNITY_FORM: 'phaseCommunityForm',
  BUILDING_FLOOR_FORM: 'buildingFloorForm',
  PROJECT_BUILDING_IMAGES: 'projectBuildingImages',
  WEBHOOK_FORM: 'webhookForm',
  BUILDING_MODEL_PACKAGE_FORM: 'buildingModelPackageForm',
  BUILDING_MODEL_PALETTE_FORM: 'buildingModelPaletteForm',
  DEAL: 'deal',
  QR_CODE_MODAL: 'qrCodeModal',
  PRICE_RULE_FORM: 'priceRuleForm',
  SENDGRID_CONTACT_LIST_FORM: 'sendGridContactListForm',
  SENDGRID_CONTACT_FORM: 'sendGridContactForm',
  FORM_FIELDS_MAPPING: 'formFieldsMapping',
  SEND_TRACKING_CODE: 'sendTrackingCode',
  TRACKING_PAGE_FORM: 'trackingPageForm',
  VERIFICATION_DIRECT_LINK: 'verificationDirectLink',
  SALES_INVITATION: 'salesInvitation',
  EXISTING_CUSTOMER: 'existingCustomer',
  FINANCIAL_REPORT: 'financialReport',
  TEAM_USER_FORM: 'teamUserForm',
  TEAM_FORM: 'teamForm',
  USER_FORM: 'userForm',
  CUSTOMER_COLUMNS_PICKER: 'customerColumnsPicker',
  CUSTOMER_FILTERS: 'customerFilters',
  CUSTOMER_FILTER_DATE_PICKER: 'customerFilterDatePicker',
  CUSTOMER_PROGRESS: 'customerProgress',
  CUSTOMERS_EXPORT: 'customersExport',
  DEAL_FILTERS: 'dealFilters',
  QUICK_POSSESSION_FORM: 'quickPossessionForm',
  QUICK_POSSESSION_COLUMNS_PICKER: 'quickPossessionColumnsPicker',
  QUICK_POSSESSION_FILTERS: 'quickPossessionFilters',
  MORTGAGE_PROVIDER_FORM: 'mortgageProviderForm',
  DEPOSIT_REFUND_FORM: 'depositRefundForm',
  COMPANY_SAVE_CHANGES_MODAL: 'companySaveChangesModal',
  UNIT_FILTERS: 'unitFilters',
  UNIT_COLUMNS_PICKER: 'unitColumnsPicker'
});
