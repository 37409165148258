import api from '../../../../../api';
import { getUtmContentReportQuery } from '../../../../../graphql';
import { fakeValue } from '../../../../../utils/sampleDataUtils';
import { innerRingChartColors } from '../utils';

export function fetchUtmContentReportAsync(filter) {
  const variables = { reportFilter: filter };
  return api.graphql(getUtmContentReportQuery, variables)
    .then(({ data: { results } }) => results);
}

export function generateSampleDataAsync() {
  let data = [
    { utmContent: 'Wix', visitorsCount: fakeValue(100) },
    { utmContent: 'Landing Page', visitorsCount: fakeValue(100) }
  ];

  data = data.map((item) => ({
    ...item,
    leadsCount: fakeValue(item.visitorsCount)
  }));
  data.sort((a, b) => b.visitorsCount - a.visitorsCount);
  return Promise.resolve(data);
}

export function getVisitorsChartData(utmContentData) {
  const colors = innerRingChartColors.slice(0, utmContentData.length);

  return {
    labels: utmContentData.map((c) => c.utmContent),
    datasets: [
      {
        backgroundColor: colors,
        data: utmContentData.map((c) => c.visitorsCount)
      }
    ]
  };
}

export function getLeadsChartData(utmContentData) {
  const colors = innerRingChartColors.slice(0, utmContentData.length);

  return {
    labels: utmContentData.map((c) => c.utmContent),
    datasets: [
      {
        backgroundColor: colors,
        data: utmContentData.map((c) => c.leadsCount)
      }
    ]
  };
}
