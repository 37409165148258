export default {
  date: {
    formats: {
      short: ''
    }
  },
  buttons: {
    dismiss: 'Dismiss',
    update: 'Update',
    save: 'Save',
    ok: 'OK',
    submit: 'Submit',
    cancel: 'Cancel',
    edit: 'Edit',
    notNow: 'Not Now',
    select: 'Select',
    selectAll: 'Select All',
    connect: 'Connect',
    yes: 'Yes',
    no: 'No',
    send: 'Send',
    done: 'Done',
    loadMore: 'Load More',
    delete: 'Delete',
    show: 'Show',
    sort: 'Move to Reorder',
    close: 'Close',
    process: 'Process',
    copy: 'Copy',
    view: 'View',
    continue: 'Continue',
    setAsDefault: 'Set as Default',
    setAsNotDefault: 'Set as Not Default',
    download: 'Download',
    shareLink: 'Share Link',
    confirm: 'Confirm',
    archive: 'Archive',
    restore: 'Restore',
    apply: 'Apply',
    clear: 'Clear',
    back: 'Back',
    add: 'Add',
    invite: 'Invite',
    create: 'Create',
    reset: 'Reset',
    filters: 'Filters',
    editColumns: 'Edit Columns',
    refund: 'Refund',
    issueRefund: 'Issue Refund',
    export: 'Export',
    doNotSave: 'Don\'t Save',
    upload: 'Upload',
    downloadSample: 'Download Sample',
    exports: {
      csv: 'Export to CSV'
    }
  },
  select: {
    select: '- Select -',
    none: '- None -',
    search: 'Type to search',
    all: 'All'
  },
  roles: {
    masterAdmin: 'Master Admin',
    tecSupport: 'Technical Support',
    companyAdmin: 'Company Admin',
    companyUser: 'Company User',
    salesLead: 'Sales Lead',
    salesUser: 'Sales User'
  },
  webhookEvents: {
    contactCreated: 'Contact Created',
    buildCreated: 'Build Created'
  },
  statuses: {
    draft: 'Draft',
    published: 'Published',
  },
  projectAvailabilities: {
    underDevelopment: 'Under Development',
    available: 'Available',
    comingSoon: 'Coming Soon',
  },
  projectBuildingAvailabilities: {
    available: 'Available',
    comingSoon: 'Coming Soon',
  },
  unitAvailabilities: {
    available: 'Available',
    notAvailable: 'Not Available',
    comingSoon: 'Coming Soon',
    sold: 'Sold',
    rented: 'Rented'
  },
  floorPlanProviders: {
    ownly: 'Ownly',
    focus360: 'Focus 360'
  },
  lotMapProviders: {
    lotWorks: 'LotWorks',
    focus360: 'Focus 360',
    streetscape: 'Streetscape'
  },
  emailServices: {
    sendGrid: 'SendGrid',
    awsSes: 'AWS SES'
  },
  enums: {
    idDocumentType: {
      nationalIdentityCard: 'ID Card',
      passport: 'Passport',
      bank: 'Bank',
      drivingLicence: 'Driver\'s License',
      driversLicense: 'Driver\'s License',
      voterId: 'Voter ID',
      identityCard: 'Identity Card',
      workPermit: 'Work Permit',
      residencePermit: 'Residence Permit',
      idCard: 'ID Card',
      residencePermitCard: 'Residence Permit Card',
      residentCard: 'Resident Card',
      visa: 'Visa'
    },
    homePriceDisplayMode: {
      totalPriceOnly: 'Display Total Price Only',
      monthlyPaymentAndTotalPrice: 'Display Monthly Payment and Total Price',
      monthlyPaymentOrTotalPrice: 'Display Monthly Payment or Total Price',
      monthlyPaymentOnly: 'Display Monthly Payment Only',
    },
    formMappableFields: {
      firstName: 'First Name',
      lastName: 'Last Name',
      fullName: 'Full Name',
      email: 'Email',
      phone: 'Phone',
      message: 'Message'
    },
    currency: {
      usd: 'USD - US Dollar',
      cad: 'CAD - Canadian Dollar'
    },
    floorOptionAvailabilityRule: {
      available: 'Available',
      unavailable: 'Unavailable',
    },
    floorOptionAvailabilityRuleType: {
      communities: 'Communities',
      communityPhases: 'Phases',
    },
    contactStage: {
      lead: 'Lead',
      buyer: 'Buyer',
      closed: 'Closed',
      nonCustomer: 'Non Customer',
    },
    trafficSourceType: {
      direct: 'Direct',
      referral: 'Referral',
      organicSearch: 'Organic Search',
      paidSearch: 'Paid Search',
      socialMedia: 'Social Media',
      email: 'Email',
      other: 'Other'
    },
    searchEngine: {
      google: 'Google',
      brave: 'Brave',
      yahoo: 'Yahoo',
      duckduckgo: 'DuckDuckGo',
      bing: 'Bing'
    },
    socialMedia: {
      facebook: 'Facebook',
      linkedIn: 'LinkedIn',
      instagram: 'Instagram',
      twitter: 'Twitter',
      youtube: 'Youtube'
    },
    idVerificationProvider: {
      onfido: 'Onfido',
      secureKey: 'Secure Key',
      plaid: 'Plaid'
    },
    preApprovalVerificationProvider: {
      homewise: 'Homewise',
      manual: 'Manual'
    },
    bankVerificationProvider: {
      flinks: 'Flinks',
      plaid: 'Plaid'
    },
    defaultHomePage: {
      myHomes: 'My Homes',
      newHome: 'New Home',
      getVerified: 'Get Verified'
    },
    customerColumn: {
      status: 'Status',
      buyingPower: 'Buying Power',
      rentalPower: 'Renting Power',
      income: 'Income',
      homeValuation: 'Home Valuation',
      interests: 'Interests',
      priceRange: 'Browsing Price ( Build & Price)',
      preApproval: 'Pre-Approval',
      phone: 'Phone Number',
      region: 'Region',
      assignedTo: 'Assigned to',
      signUpDate: 'Sign-up Date',
      lastActive: 'Last Active'
    },
    customerStatus: {
      accountCreated: 'Account Created',
      idVerified: 'ID Verified',
      financesVerified: 'Income Verified',
      customerVerified: 'Customer Verified',
    },
    customerReservationStatus: {
      smsEmailVerification: 'SMS/Email Verification',
      incomeVerification: 'Income Verification',
      sign: 'Reservation Form',
      deposit: 'Deposit',
    },
    customerVerificationStatus: {
      smsEmailVerification: 'SMS/Email Verification',
      idVerification: 'ID Verification',
      incomeVerification: 'Income Verification',
      homeValuationVerification: 'Home Valuation Verification',
    },
    paymentMethod: {
      card: 'Credit Card',
      achDirectDebit: 'US Bank Account'
    },
    salesInviteMethod: {
      email: 'Email',
      sms: 'SMS'
    },
    rentalWorkflowType: {
      idVerification: 'ID Verification',
      payment: 'Payment',
      homeEvaluation: 'Home Evaluation',
      financialVerification: 'Financial Verification',
    },
    verifiedWorkflowType: {
      idVerification: 'ID Verification',
      homeEvaluation: 'Home Evaluation',
      financialVerification: 'Financial Verification',
    },
    teamUserPermission: {
      member: 'Member',
      lead: 'Lead',
      manager: 'Manager',
    },
    interestInteractionType: {
      saved: 'Saved',
      viewed: 'Viewed'
    },
    quickPossessionAvailability: {
      available: 'Available',
      hold: 'On Hold',
      sold: 'Sold',
    },
    quickPossessionStatus: {
      draft: 'Draft',
      published: 'Published',
      archived: 'Archived'
    },
    quickPossessionStages: {
      active: 'Active',
      scheduledToArchive: 'Scheduled to Archive',
      archived: 'Archived'
    },
    quickPossessionColumn: {
      community: 'Community',
      model: 'Model',
      productType: 'Product Type',
      address: 'Address',
      size: 'Size',
      beds: 'Beds',
      baths: 'Baths',
      price: 'Price',
      availability: 'Availability',
      status: 'Status',
      interests: 'Interests',
      lastModified: 'Last Modified',
      lastCreated: 'Last Created'
    },
    depositStatus: {
      submitted: 'Submitted',
      succeeded: 'Succeeded',
      failed: 'Failed',
      accepted: 'Accepted',
      rejected: 'Rejected'
    },
    refundReasons: {
      dealCompleted: 'Deal Completed',
      dealCanceled: 'Deal Canceled',
      other: 'Other'
    },
    projectBuildAndPriceStartingPoints: {
      model: 'Start from Model Selection',
      unit: 'Start from Unit Selection'
    },
    unitColumn: {
      project: 'Project',
      buildingModel: 'Model',
      suiteNumber: 'Suite Number',
      floor: 'Floor',
      bedrooms: 'Bedrooms',
      bathrooms: 'Bathrooms',
      size: 'Sq. Ft.',
      price: 'Price',
      availability: 'Availability'
    }
  },
  errors: {
    sessionExpired: 'Your session has expired.'
  },
  components: {
    common: {
      listEmpty: {
        index: {
          noData: 'No data to display.'
        }
      },
      reportEmpty: {
        index: {
          noData: 'No data for this report'
        }
      },
      fileUploader: {
        index: {
          dragAndDrop: 'Drag and drop your images here'
        }
      },
      imageUploader: {
        index: {
          clickToUpload: 'Click to upload',
          dragAndDrop: ' or drag and drop',
          helpText: 'SVG, PNG, JPG or GIF (max. 800x400px)'
        }
      },
      designer: {
        panels: {
          editPanel: {
            index: {
              actions: 'Actions',
              edit: 'Edit',
              delete: 'Delete'
            }
          }
        },
        tooltip: {
          index: {
            noUnitAssigned: 'No Unit assigned'
          }
        }
      },
      copyLink: {
        index: {
          copyToClipboard: 'Copy to clipboard',
          copied: 'Copied!'
        }
      },
      searchBar: {
        index: {
          placeholder: 'Search by %{fields}'
        }
      },
      filterDropdown: {
        index: {
          all: 'All'
        }
      },
      addItemDropdown: {
        index: {
          add: 'Add'
        }
      },
      confirmClone: {
        index: {
          clone: 'Clone'
        }
      },
      emailInput: {
        index: {
          email: 'Email'
        }
      },
      verifyCodeInput: {
        index: {
          form: {
            code: 'Enter code'
          },
          buttons: {
            resend: 'Resend'
          },
          noReceived: 'Didn\'t receive the code?',
          resending: 'Resending'
        }
      },
      dateFilter: {
        index: {
          last7Days: 'Last 7 days: ',
        }
      },
      timeAgo: {
        index: {
          yesterday: 'Yesterday'
        }
      },
      showMoreContent: {
        seeMoreButton: {
          index: {
            seeMore: 'See More',
            seeLess: 'See Less'
          }
        }
      }
    },
    global: {
      integrationsBreadcrumbs: {
        index: {
          integrations: 'Integrations'
        }
      },
      salesRepDropdown: {
        index: {
          salesTeams: 'Sales Teams',
          salesReps: 'Sales Representatives'
        }
      },
      customer: {
        customerTypeLabel: {
          index: {
            fullyVerified: 'Fully verified',
            preApproved: 'Pre-approved',
            idVerified: 'ID verified',
            prospect: 'Prospect'
          }
        },
        customerVerifiedStatusIcon: {
          index: {
            idVerified: 'ID Verified',
            prospect: 'Prospect',
            preApproval: 'Pre Approval Verified',
            fullyVerified: 'Fully Verified'
          }
        },
        customerProgressPopover: {
          verificationContent: {
            index: {
              title: 'Verification Progress',
            },
            utils: {
              emailSmsVerification: 'Email/SMS Verification',
              idVerification: 'ID Verification',
              financialVerification: 'Income Verification',
              homeValuation: 'Home Valuation',
              notVerified: 'Not Verified yet'
            }
          },
          reservationContent: {
            index: {
              title: 'Reservation Progress',
            },
            utils: {
              emailSmsVerification: 'Email/SMS Verification',
              notVerified: 'Not Confirmed yet'
            }
          }
        },
        customerFinancialTrendBadge: {
          index: {
            noneDetected: 'Not Available',
            increasing: 'Increasing',
            decreasing: 'Decreasing',
            consistent: 'Consistent'
          }
        },
        customerTimeline: {
          tour: {
            index: {
              date: 'Date',
              registrationDate: 'Registration Date',
              scheduledBy: 'Scheduled By',
              division: 'Division',
              community: 'Community',
              plan: 'Plan',
              homesite: 'Home Site',
              address: 'Address',
              city: 'City',
              stateProvince: 'State Province',
              postalCode: 'Postal Code',
              tourReason: 'Tour Reason',
              status: 'Status',
              types: {
                scheduled: 'Scheduled a Tour',
                rescheduled: 'Rescheduled a Tour',
                missed: 'Missed a Tour',
                completed: 'Completed a Tour',
                cancelled: 'Cancelled a Tour',
                deleted: 'Deleted a Tour'
              }
            }
          },
          visit: {
            interests: {
              index: {
                title: 'User browsed the following interests'
              }
            },
            utils: {
              ownlyVisit: 'Ownly visit',
              websiteVisit: 'Website visit',
              of: 'of',
              minutes: {
                one: '%{count} minute',
                other: '%{count} minutes',
              },
              seconds: {
                one: '%{count} second',
                other: '%{count} seconds'
              }
            },
            trafficSource: {
              index: {
                title: 'Traffic Source: ',
                utmSource: 'Source: ',
                utmMedium: 'Medium: ',
                utmCampaign: 'Campaign: ',
                utmContent: 'Content: ',
                utmTerm: 'Term: ',
              },
            },
            referer: {
              index: {
                title: 'Referred by:'
              }
            }
          },
          homewisePostback: {
            index: {
              status: 'Status',
              updatedAt: 'Updated At',
              price: 'Price',
              statuses: {
                open_lead: 'Mortgage Application Submitted',
                pre_approved: 'Mortgage Pre-Approved',
                closed_lost: 'Mortgage Application Closed',
                closed_won: 'Mortgage Application Completed'
              }
            }
          },
          idVerified: {
            index: {
              title: 'ID Verification',
              description: 'ID has been verified'
            }
          },
          message: {
            index: {
              title: 'Message',
              description: 'Message has been sent'
            }
          },
          formSubmission: {
            index: {
              title: 'Form Submission',
              description: 'A new form has been submitted',
              form: 'Form:',
              seeMore: 'See more',
              seeLess: 'See less'
            }
          },
          affordability: {
            index: {
              homeSales: {
                title: 'Buying Power',
                description: 'Buying Power has been completed'
              },
              homeRental: {
                title: 'Renting Power',
                description: 'Renting Power has been completed'
              }
            }
          },
          builtHome: {
            index: {
              title: 'Home Saved',
              description: 'User saved a home to their profile.'
            }
          },
          onfidoCheck: {
            index: {
              title: 'ID Scan'
            }
          },
          offerAccepted: {
            index: {
              title: 'Deal',
              description: 'Deal created',
            }
          },
          depositSubmitted: {
            index: {
              title: 'Deposit',
              description: 'Deposit has been completed'
            }
          },
          signSubmitted: {
            index: {
              title: 'Reservation',
              description: 'Reservation form has been signed'
            }
          },
          customerInvited: {
            verified: {
              index: {
                title: 'Invited',
                description: 'Invited by %{inviter} to Verified'
              }
            },
            reserveNow: {
              index: {
                title: 'Invited',
                description: 'Invited by %{inviter} to Reserve'
              }
            }
          },
          refundIssued: {
            index: {
              title: 'Refund',
              description: 'Refund of %{amount} issued to %{customerName} by %{userName}',
              support: 'Ownly Support'
            }
          }
        },
        customerFormSubmissionIndicator: {
          index: {
            description: 'Lead from Form Submission on Website'
          }
        }
      },
      customersBreadcrumbs: {
        index: {
          homeSales: {
            customers: 'Customers'
          },
          homeRental: {
            customers: 'Applicants'
          }
        }
      },
      deleteButton: {
        functions: {
          title: 'Are you sure?',
          message: 'Do you want to delete this item?'
        },
      },
      viewModesDropdown: {
        index: {
          viewAs: 'View as',
          grid: 'Grid',
          list: 'List'
        }
      },
      floorsManager: {
        index: {
          buttons: {
            add: 'Add Floor'
          }
        },
        floorFormModal: {
          index: {
            newTitle: 'New Floor',
            editTitle: 'Edit Floor',
            name: 'Name',
            image: 'Image',
            size: 'Size',
            bedrooms: 'Bedrooms',
            bathrooms: 'Bathrooms',
            isBelowGrade: 'Below Grade'
          }
        },
        floorGroupFormModal: {
          index: {
            newTitle: 'New Group',
            editTitle: 'Edit Group',
            name: 'Name',
            selection: 'Selection',
            selectionOptions: {
              single: 'Radio Button: Only One option can be selected',
              multiple: 'Option Toggles: Multiple Options can be selected'
            }
          }
        },
        floorOptionFormModal: {
          index: {
            newTitle: 'New Option',
            editTitle: 'Edit Option',
            name: 'Name',
            image: 'Image',
            priceChange: 'Price Change',
            sizeChange: 'Size Change',
            bedsChange: 'Beds Change',
            bathsChange: 'Baths Change',
            description: 'Description',
            alternateImage: 'Alternate Image',
            alternateOption: 'Alternate Option',
            isDefault: 'Default',
            frontage: 'Frontage',
            max: 'Max',
            min: 'Min',
            externalId: 'External ID'
          },
          availabilityRuleSettings: {
            index: {
              title: 'Availability Rule',
              description: 'By default, this option will be <code>available</code> for all model communities.',
              onlyFor: 'only for the following',
              availabilityCommunitiesPlaceholder: 'Select communities',
              availabilityCommunityPhasesPlaceholder: 'Select phases',
              empty: 'No Communities',
              buttons: {
                setRule: 'Set Rule',
                removeRule: 'Remove Rule'
              }
            }
          }
        },
        floorOptionImagesModal: {
          index: {
            title: 'Manage Images for %{name}'
          }
        },
        floorOptionPriceRulesModal: {
          index: {
            title: 'Price Rules for %{name}'
          }
        },
        floor: {
          index: {
            sizeSqft: '%{size} sq.ft.',
            belowGrade: 'Below Grade',
            buttons: {
              addGroup: 'Add Group'
            }
          },
          actionsMenu: {
            index: {
              moveUp: 'Move Up',
              moveDown: 'Move Down'
            }
          },
          floorGroup: {
            index: {
              buttons: {
                addOption: 'Add Option'
              }
            }
          },
          floorOption: {
            index: {
              changes: 'Changes:',
              alternatives: 'Alternatives:',
              dependencies: 'Dependencies:',
              images: 'Images',
              priceRules: 'Price Rules',
              availabilityRule: 'Availability Rule',
              isDefault: 'Default',
              yes: 'Yes'
            },
            actionsMenu: {
              index: {
                dependency: 'Dependency',
                images: 'Images',
                priceRules: 'Price Rules'
              }
            }
          }
        },
        floorOptionDependencyModal: {
          index: {
            title: 'Enable This Option When',
            floor: 'Floor',
            allConditions: 'All the conditions must be met',
            oneCondition: 'One of the conditions must be met',

            operators: {
              is: 'is',
              isNot: 'is not'
            }
          }
        }
      },
      directLinkModal: {
        index: {
          title: 'Share Link',
          previewTitle: 'Preview Link',
          reserveNowCheckbox: 'Reserve Now',
          messages: {
            copied: 'Link has been copied to clipboard.'
          },
          buttons: {
            copy: 'Copy Link'
          }
        }
      },
      home: {
        communityProject: {
          index: {
            city: ' in %{city}'
          }
        },
        validUntil: {
          index: {
            validUntil: 'valid until %{date}'
          }
        },
        offerSteps: {
          offerStep: {
            index: {
              pending: 'Waiting',
              submitted: 'Sent',
              countered: 'Countered',
              accepted: 'Accepted',
              rejected: 'Rejected',
              declined: 'Declined',
              invited: 'Invited'
            }
          },
          signStep: {
            index: {
              pending: 'Waiting',
              accepted: 'Confirmed',
              rejected: 'Problem',
              declined: 'Declined',
              completed: 'Completed'
            }
          },
          depositStep: {
            index: {
              pending: 'Waiting',
              completed: 'Completed',
              accepted: 'Confirmed',
              rejected: 'Problem',
            }
          },
        },
        dealModal: {
          navTabs: {
            index: {
              overview: 'Overview',
              timeline: 'Timeline'
            }
          },
          overview: {
            dealInfo: {
              index: {
                status: 'Status',
                assignedTo: 'Assigned to',
                model: 'Model/Unit',
                communityProject: 'Community/Project',
                sellingPrice: 'Selling Price',
                deposit: 'Deposit'
              },
              utils: {
                customerOffer: 'Customer Deal',
                offerAccepted: 'Deal Accepted',
                offerCountered: 'Deal Countered',
                recounterPending: 'Recounter Pending',
                offerRejected: 'Deal Rejected',
                offerDeclined: 'Deal Declined',
              }
            },
            offerSection: {
              acceptOfferCard: {
                index: {
                  title: 'Accept Offer',
                  customerOffered: 'Customer offered',
                  confirmCustomerOffered: 'Confirm customer offer',
                  requiredDepositAmount: 'Required deposit amount',
                  buttons: {
                    accept: 'Accept'
                  },
                  confirmAlert: {
                    message: 'Please confirm you want to accept this offer.'
                  }
                }
              },
              counterOfferCard: {
                index: {
                  title: 'Counter Offer',
                  counterOfferPrice: 'Counter offer',
                  confirmCounterOfferPrice: 'Confirm counter offer',
                  buttons: {
                    counter: 'Counter'
                  }
                }
              },
              rejectOfferCard: {
                index: {
                  title: 'Reject Offer',
                  description: 'Reject the customers offer, the customer can submit another offer.',
                  buttons: {
                    reject: 'Reject'
                  },
                  confirmAlert: {
                    message: 'Please confirm you want to reject this offer.'
                  }
                }
              },
              declineOfferCard: {
                index: {
                  title: 'Decline',
                  description: 'Decline the customer offer.',
                  buttons: {
                    decline: 'Decline'
                  },
                  confirmAlert: {
                    message: 'Please confirm you want to decline this offer.'
                  }
                }
              },
              acceptedOfferCard: {
                index: {
                  requiredDepositAmount: 'Required deposit amount'
                },
                offerDocumentToSign: {
                  index: {
                    documentToSign: 'Document to Sign',
                    editDocumentTemplate: 'Edit document template in DocuSign',
                    uploadingDocumentTemplate: 'Uploading document template to DocuSign...',
                    uploadDocument: 'Upload Document',
                    selectTemplate: 'Select Template from DocuSign',
                    templateId: 'Template',
                    messages: {
                      notUploaded: 'There was an error uploading your document to DocuSign. Please try again.'
                    },
                    buttons: {
                      uploadFile: 'Upload File'
                    }
                  }
                }
              },
            },
            signSection: {
              confirmSignCard: {
                index: {
                  title: 'Confirm Signature',
                  description: 'The customer has signed the deal document. Confirm that you have received the deal document signed from the customer.',
                  downloadSignedDocument: 'Download signed document',
                  noSignedDocument: 'There was a problem getting the signed document.',
                  signConfirmed: 'Signature Confirmed',
                  buttons: {
                    confirm: 'Confirm'
                  },
                  confirmAlert: {
                    message: 'Please confirm you want to accept this signature.'
                  }
                }
              },
              rejectSignCard: {
                index: {
                  title: 'Signature Problem',
                  description: 'There was a problem with the signature. Add a note that will be sent to the user.',
                  signNote: 'Note',
                  buttons: {
                    reject: 'Signature Problem'
                  }
                }
              },
              declinedSignCard: {
                index: {
                  title: 'Signature Declined',
                  description: 'The customer has declined to sign the document.'
                }
              },
            },
            depositSection: {
              confirmDepositCard: {
                index: {
                  title: 'Confirm Deposit',
                  description: 'The customer has supplied to you a deposit. Confirm that you have received the credit card deposit from the customer.',
                  amount: 'Deposit',
                  confirmAmount: 'Confirm Deposit',
                  buttons: {
                    confirm: 'Confirm Deposit'
                  },
                  confirmAlert: {
                    message: 'Please confirm you want to accept this deposit.'
                  }
                }
              },
              depositProblemCard: {
                index: {
                  title: 'Deposit Problem',
                  description: 'There was a problem with the deposit. Add a note that will be sent to the user.',
                  note: 'Note',
                  buttons: {
                    reject: 'Deposit Problem'
                  }
                }
              },
              stripeStatus: {
                index: {
                  paymentMethod: 'Payment Method',
                },
                stripePaymentStatus: {
                  index: {
                    completed: 'Deposit Completed.',
                    failed: 'Deposit Failed'
                  }
                },
                stripePayoutStatus: {
                  index: {
                    completed: 'Payout Completed.',
                    failed: 'Payout Failed'
                  }
                }
              },
              RefundDepositSection: {
                index: {
                  refundedAmount: 'Refunded Amount',
                  buttons: {
                    refundDeposit: 'Refund Deposit'
                  }
                }
              }
            }
          }
        },
        size: {
          index: {
            sqft: 'sq ft'
          }
        },
        customerSignUpDate: {
          index: {
            new: 'New'
          }
        }
      },
      quickPossessionForm: {
        index: {
          community: 'Community',
          elevation: 'Elevation',
          model: 'Model',
          package: 'Package',
          palette: 'Palette',
          floorplan: 'Floor Plans',
          specifications: 'Optional Upgrades',
          availability: 'Availability',
          status: 'Status',
          description: 'Description',
          address: 'Address',
          url: 'URL',
          lotId: 'Lot ID',
          squareFootage: 'Square Footage',
          image: 'Main Image',
          enablePossessionDate: 'Enable Possession Date',
          possessionDate: 'Possession Date',
          price: 'Price',
          beds: 'Beds',
          baths: 'Baths',
          images: 'Additional Images',
          placeholders: {
            url: 'Url',
            description: 'e.g. Welcome to this spacious and stylish 3-bedroom, 2-bathroom home boasting impressive features and modern amenities. Nestled in a tranquil neighbourhood, this property offers an ideal blend...',
            beds: '# of Beds',
            baths: '# of Baths',
            requiredDepositAmount: 'Deposit Amount'
          },
          floorImages: 'Floor Plan Images',
          overrideFloors: 'Override Floor Plans',
          requiredDepositAmount: 'Required Deposit Amount',
          stage: 'Stage'
        },
        modelDropdown: {
          index: {
            label: 'Model',
            newModel: '(New Model)'
          }
        },
        communityDropdown: {
          index: {
            label: 'Community',
            newCommunity: '(New Community)'
          }
        },
        elevationDropdown: {
          index: {
            label: 'Elevation',
          }
        },
        packageDropdown: {
          index: {
            label: 'Package',
          }
        },
        paletteDropdown: {
          index: {
            label: 'Palette',
          }
        },
        specificationsMultiSelect: {
          index: {
            noMoreSpecifications: 'No Optional Upgrades available',
          }
        },
        floorplan: {
          components: {
            editableSwitch: {
              index: {
                title: 'Editable'
              }
            }
          }
        }
      },
      homeCard: {
        index: {
          builtOn: 'Built on: ',
          quickPossession: 'Quick Possession',
          deletedByUserAt: 'Deleted by user on: ',
          totalOffers: 'Total deals: %{total}'
        }
      },
      sellersManagement: {
        addSellerDropdown: {
          index: {
            teams: 'Sales Teams',
            salesReps: 'Sales Representatives',
            teamUsersCount: {
              zero: 'No sales representatives',
              one: '1 sales representative',
              other: '%{count} representatives'
            },
            buttons: {
              add: 'Add Sales Representative / Team'
            }
          }
        },
        sellersList: {
          index: {
            salesReps: 'Sales Representatives',
            salesTeams: 'Sales Teams'
          },
          sellerItem: {
            index: {
              teamUsersCount: {
                zero: 'No sales representatives',
                one: '1 sales representative',
                other: '%{count} sales representatives'
              },
            }
          }
        }
      },
      QRCodeModal: {
        index: {
          title: 'QR Code',
          message: 'Scan QR Code to open the link.',
          text: '%{itemName} Share Link',
          buttons: {
            downloadQr: 'Download QR code'
          }
        }
      },
      socialShare: {
        index: {
          text: '%{itemName} Share Link',
        }
      },
      modifications: {
        index: {
          prevValues: 'Previous Values',
          newValues: 'New Values',
          updatedBy: 'Updated By',
          modificationDate: 'Modification Date'
        }
      },
      priceRules: {
        priceRuleList: {
          index: {
            communityPhase: 'Community / Phase',
            price: 'Base Price',
            defaultSelection: 'Default Selection',
            externalId: 'External ID',
            loading: 'Loading price rules...'
          },
          listItem: {
            index: {
              defaultSelections: {
                overrideParent: 'Override Parent',
                sameAsParent: 'Same as Parent'
              }
            }
          }
        },
        addPriceRuleButton: {
          index: {
            title: 'Add Price Rule'
          }
        },
        priceRuleFormModal: {
          index: {
            newTitle: 'New Price Rule',
            editTitle: 'Edit Price Rule',
            communityAndPhaseId: 'Community / Phase',
            price: 'Price',
            externalId: 'External ID',
            defaultSelection: 'Default Selection',
            priceRuleDefaultSelections: {
              sameAsParent: 'Same as Parent',
              overrideParent: 'Override Parent'
            }
          }
        }
      },
      imagesManager: {
        listItem: {
          index: {
            isDefault: 'Default',
            buttons: {
              markAsDefault: 'Mark as default'
            }
          }
        }
      },
      filters: {
        companyFilter: {
          index: {
            all: 'All Companies',
            company: 'Company'
          }
        },
        communityFilter: {
          index: {
            title: 'Community'
          }
        },
        modelFilter: {
          index: {
            title: 'Model'
          }
        }
      },
      salesInvitationModal: {
        invitationWizard: {
          invitationType: {
            index: {
              title: 'Invite your customers',
              description: 'Select a product you would like to invite customers to.',
              buttons: {
                verified: 'Verified',
                reserveNow: 'ReserveNow'
              },
              descriptions: {
                verified: 'Invite a Customer to verify their ID and finances',
                reserveNow: 'Invite a Customer to place a deposit on a home'
              }
            }
          },
          customerSelectionType: {
            index: {
              homeSales: {
                title: 'Invite customers to Ownly',
                description: 'Select whether you would like to select an existing customer or invite a new one',
                buttons: {
                  newCustomer: 'New Customer',
                  existingCustomer: 'Existing Customer'
                }
              },
              homeRental: {
                title: 'Invite applicants to Ownly',
                description: 'Select whether you would like to select an existing applicant or invite a new one',
                buttons: {
                  newCustomer: 'New Applicant',
                  existingCustomer: 'Existing Applicant'
                }
              }
            }
          },
          newCustomer: {
            index: {
              homeSales: {
                title: 'Invite a New Customer',
                description: 'Create a new customer to invite to Ownly',
              },
              homeRental: {
                title: 'Invite a New Applicant',
                description: 'Create a new applicant to invite to Ownly',
              },
              firstName: 'First Name',
              lastName: 'Last Name *',
              email: 'Email Address *',
              phone: 'Phone Number'
            },
            existingCustomerModal: {
              index: {
                homeSales: {
                  title: 'This customer already exists',
                  description: 'Looks like you already have this person in you customer list. Would you like to invite them?',
                },
                homeRental: {
                  title: 'This applicant already exists',
                  description: 'Looks like you already have this person in you applicant list. Would you like to invite them?',
                }
              }
            }
          },
          existingCustomer: {
            index: {
              homeSales: {
                title: 'Invite Existing Customers',
                searchBarPlaceholder: 'Search for all customers',
                selectedCustomers: {
                  one: '<b>1</b> customer selected',
                  other: '<b>%{count}</b> customers selected'
                }
              },
              homeRental: {
                title: 'Invite Existing Applicants',
                searchBarPlaceholder: 'Search for all applicants',
                selectedCustomers: {
                  one: '<b>1</b> applicant selected',
                  other: '<b>%{count}</b> applicants selected'
                }
              }
            },
            listView: {
              index: {
                homeSales: {
                  customer: 'Customer',
                },
                homeRental: {
                  customer: 'Applicant',
                },
                signUpDate: 'Sign-up date'
              },
              listItem: {
                selectLink: {
                  index: {
                    select: 'Select',
                    selected: 'Selected'
                  }
                }
              }
            }
          },
          invitationList: {
            index: {
              homeSales: {
                title: 'Invite New Customers'
              },
              homeRental: {
                title: 'Invite New Applicants'
              }
            },
            listView: {
              index: {
                homeSales: {
                  customer: 'Customer'
                },
                homeRental: {
                  customer: 'Applicant'
                }
              },
            },
          },
          invitationSent: {
            index: {
              title: 'Invite Sent',
              homeSales: {
                description: 'The customers you’ve added will receive an invite to %{invitationTypeName}.',
                buttons: {
                  viewCustomers: 'View Customers'
                }
              },
              homeRental: {
                description: 'The applicants you’ve added will receive an invite to %{invitationTypeName}.',
                buttons: {
                  viewCustomers: 'View Applicants'
                }
              }
            }
          },
          components: {
            copyInviteLinkButton: {
              index: {
                title: 'Copy Invite Link',
                messages: {
                  copied: 'Link has been copied.'
                },
              }
            },
            inviteByDropdown: {
              index: {
                title: 'Invite by',
              }
            },
            invitationListItem: {
              inviteByButton: {
                index: {
                  title: 'Invite by'
                }
              }
            }
          },
          homeSelectionType: {
            index: {
              description: 'Select from your current inventory or add a new home',
              buttons: {
                newHome: 'Create a New Home',
                existingHome: 'Select From Inventory'
              },
              homeSales: {
                title: 'Invite customers to ReserveNow',
              },
              homeRental: {
                title: 'Invite applicants to ReserveNow',
              }
            }
          },
          newHome: {
            index: {
              title: 'Create a New Home',
              description: 'By filling in the details below you will create a published home'
            }
          },
          existingHome: {
            index: {
              title: 'Select a Home From Your Current Inventory',
              searchBarPlaceholder: 'Search',
              listView: {
                listItem: {
                  index: {
                    community: 'Community',
                    project: 'Project',
                    address: 'Address'
                  }
                }
              },
              gridView: {
                listItem: {
                  index: {
                    community: 'Community',
                    project: 'Project'
                  }
                }
              },
              sortDropdown: {
                index: {
                  newest: 'Newest',
                  oldest: 'Oldest',
                  priceAscending: 'Price Ascending',
                  priceDescending: 'Price Descending',
                  alphabetical: 'Alphabetical'
                }
              }
            }
          },
          confirmInvitation: {
            index: {
              verified: {
                title: 'Please Confirm'
              },
              reserveNow: {
                title: 'Confirm The Invite of Customer to Model'
              },
              buttons: {
                sendInvite: 'Send Invite',
                inviteToSite: 'Invite to Site',
                inviteToLandingPage: 'Invite to Landing Page'
              }
            }
          },
          utils: {
            invitationType: {
              verified: 'Verified',
              reserveNow: 'ReserveNow'
            }
          }
        },
      },
      verificationDirectLinkModal: {
        index: {
          title: 'Get Verified Link',
          name: 'Get Verified',
          messages: {
            copied: 'Link has been copied to clipboard!',
            success: 'Verification Invitations sent successfully!'
          },
          buttons: {
            copy: 'Copy Link',
            sendInBackground: 'Send Invitation for verification'
          }
        }
      },
      viewModesButton: {
        index: {
          grid: 'Grid',
          list: 'List'
        }
      },
      addressAutocomplete: {
        index: {
          address: 'Address'
        }
      },
      columnsPickerModal: {
        index: {
          title: 'Edit Columns',
          description: 'Choose which columns you see',
          availableColumnsTitle: 'Select the columns you would like to be active',
          availableColumnsDescription: 'Check them off to add them to the order above',
          editColumnOrder: 'Edit Column Order',
          selectColumns: 'Select Columns'
        }
      },
      mortgageProvidersManagement: {
        addMortgageProviderDropdown: {
          index: {
            buttons: {
              add: 'Add Mortgage Provider'
            }
          }
        }
      },
      deposit: {
        depositRefundFormModal: {
          index: {
            title: 'Are you sure?',
            description: 'By issuing a refund you will not be able to reverse this action.',
            amount: 'Refund Amount',
            reason: 'Reason for Refund',
            confirmAlert: {
              message: 'Do you want to mark the home as sold?',
              buttons: {
                markAsSold: 'Mark as Sold'
              }
            }
          }
        }
      },
      reservationDocumentManagement: {
        index: {
          overrideReservationDocument: 'Override Reservation Document',
          uploadDocument: 'Upload Document',
          selectTemplate: 'Select Template from DocuSign',
          templateName: 'Template Name',
          emailSubject: 'Email Subject',
          documentToSign: 'Document to Sign',
          templateId: 'Template',
          defaults: {
            templateName: 'Ownly template',
            emailSubject: 'signature completed'
          },
          placeholders: {
            name: 'Team Name',
            description: 'Description',
          },
          buttons: {
            uploadFile: 'Upload File',
            editTemplate: 'Edit document template in DocuSign',
          },
          messages: {
            saved: 'Reservation Document settings has been successfully saved.',
          }
        }
      }
    },
    ui: {
      strongPasswordInput: {
        criteriaPopover: {
          index: {
            title: 'Password criteria:',
            startWithALetter: 'Start with a letter',
            oneUpperCaseLetter: 'One upper-case letter',
            oneLowerCaseLetter: 'One lower-case letter',
            oneSpecialCharacter: 'One special character (!,@,#,%)',
            oneNumber: 'One number',
            atLeastEight: 'At least 8 characters'
          }
        }
      },
      locationSelector: {
        index: {
          country: 'Country',
          state: 'Province / State',
          city: 'City'
        }
      }
    },
    auth: {
      login: {
        index: {
          title: 'Log in to your account',
          description: 'Welcome back! Please enter your details.',
          email: 'Email',
          password: 'Password',
          forgotPassword: 'Forgot Password?',
          placeholders: {
            email: 'Enter your email',
            password: 'Enter your password'
          },
          showPassword: 'Show Password',
          hidePassword: 'Hide Password',
          buttons: {
            login: 'Sign In'
          }
        }
      },
      sendCode: {
        index: {
          title: 'Two-factor authentication',
          description: 'We need to verify your identity.',
          instructions: 'Choose how to receive your authentication code.',
          mediums: {
            sms: 'Text Message',
            email: 'Email'
          },
          buttons: {
            send: 'Send Code'
          }
        }
      },
      verifyCode: {
        index: {
          title: 'Enter Verification Code',
          smsDescription: 'A text message with a 6-digit verification code was just sent to your phone.',
          emailDescription: 'A message with a 6-digit verification code was just sent to your email.',
          form: {
            code: 'Enter Code'
          },
          buttons: {
            resend: 'Resend'
          },
          noReceived: 'Didn\'t receive the code?',
          messages: {
            invalidCode: 'Your code entered is invalid or expired.',
            resent: 'Verification Code resent.'
          },
          resending: 'Resending'
        }
      },
      forgotPassword: {
        index: {
          title: 'Forgot Password',
          email: 'Email',

          messages: {
            blank: 'Email can\'t be blank',
            submitted: 'Thanks! If there\'s an account associated with this email, we\'ll send the password reset instructions immediately.'
          },

          buttons: {
            confirm: 'Confirm'
          }
        }
      },
      resetPasswordLinkSent: {
        index: {
          title: 'Email has been sent!',
          emailDescription: 'A message with a link for reset your password was just sent to your email.',
          pleaseCheckSpamFolder: 'Please check your spam folder if you do not receive an email within 5 minutes',
          notReceivedLink: 'Didn\'t receive the link?',
          resendLink: 'Resend',
          messages: {
            submitted: 'Thanks! If there\'s an account associated with this email, we\'ll send the password reset instructions immediately.'
          },
        }
      },
      resetPassword: {
        index: {
          title: 'Reset your password',
          password: 'New Password',
          confirmPassword: 'Confirm New Password',
          blankError: 'The passwords cannot be blank',
          dontMatchError: 'The passwords don\'t match',
          messages: {
            invalidResetToken: 'Your reset password link is invalid or expired.',
            invalidFirstLoginToken: 'Your link to complete your registration is invalid or has expired.',
            error: 'There was an error processing your request',
            success: 'Your password has been reset',
          },
          buttons: {
            reset: 'Reset'
          }
        },
      },
      signUp: {
        index: {
          title: 'Sign Up',
          messages: {
            invalidToken: 'Your invitation link is invalid or expired.',
            error: {
              default: 'There was an error processing your request',
              password: 'The passwords don\'t match'
            },
            success: 'Your password has been reset'
          }
        },
        registrationForm: {
          index: {
            firstName: 'First Name',
            lastName: 'Last Name',
            phone: 'Phone',
            password: 'Password',
            confirmPassword: 'Password confirmation',
            buttons: {
              register: 'Register'
            }
          }
        },
        successRegistration: {
          index: {
            title: 'Successful registration!',
            description: 'Please wait while you are redirect.'
          }
        },
        invalidInvitation: {
          index: {
            title: 'Invalid or Expired Invitation',
            description: 'Please wait while you are redirect.'
          }
        }
      },
      loginCallback: {
        index: {
          title: 'Signing in using %{provider}.',
          description: 'Please wait while you are redirected.',
          messages: {
            error: 'There was an error processing your request. Please try again.'
          }
        }
      },
      consent: {
        index: {
          loggedInAs: 'You are logged in as:',
          allowAccess: 'Allow Zapier to access your Ownly Account?',
          willAllow: 'This will allow Zapier to:',
          readProfile: 'Read your profile',
          readHomes: 'Read Homes',
          readLeads: 'Read Leads'
        }
      }
    },
    admin: {
      overview: {
        index: {
          title: 'Overview',
        },
        reportItemTitle: {
          index: {
            modelReport: 'Model Report: %{name}',
            communityReport: 'Community Report: %{name}',
            projectReport: 'Project Report: %{name}',
            clearSelection: 'Clear selection'
          }
        },
        trafficByCommunity: {
          index: {
            title: 'Traffic By Community/Project',
            last3Months: 'Last 3 months',
            visitors: 'Visitors',
            communityOrProject: 'Community/Project'
          }
        },
        trafficByModel: {
          index: {
            title: 'Traffic By Models',
            last3Months: 'Most viewed models in last 3 months',
            model: 'Model',
            visitors: 'Visitors'
          }
        },
        leadGeneration: {
          index: {
            title: 'Lead Generation',
            last6Months: 'Last 6 months',
            ownlyVisitors: 'Visitors (Ownly)',
            ownlyReturningVisitors: 'Returning Visitors (Ownly)',
            ownlyLeadsCaptured: 'Leads Captured (Ownly)',
            webtrackerVisitors: 'Visitors (Website)',
            webtrackerReturningVisitors: 'Returning Visitors (Website)',
            webtrackerLeadsCaptured: 'Leads Captured (Website)',
            totalVisits: 'Total Visits: %{totalVisits}',
            totalPerCategory: 'Total %{category}: %{count}',
            date: 'Date'
          }
        },
        trafficByDevice: {
          index: {
            title: 'Our Visitors',
            description: 'Different devices used to visit',
            mobile: 'Mobile',
            desktop: 'Desktop',
            tablet: 'Tablet',
            deviceType: 'Device Type',
            visitors: 'Visitors'
          }
        },
        mostPopularSelections: {
          index: {
            title: 'Most Popular Selections',
            timesSelected: 'Times Selected',
            selection: 'Selection'
          }
        },
        leadLocation: {
          index: {
            title: 'Lead Location'
          }
        },
        topElevations: {
          index: {
            title: 'Top Elevations',
            selections: 'Selections'
          }
        },
        trafficSource: {
          index: {
            title: 'Traffic Source',
            totalLegend: 'Total %{trafficSourceName}: %{count}'
          }
        }
      },
      communities: {
        form: {
          index: {
            newTitle: 'New Community',
            editTitle: 'Edit Community',
            name: 'Name',
            cityId: 'City',
            subRegion: 'Sub-Region',
            address: 'Address',
            description: 'Description',
            province: 'Province / State',
            websiteUrl: 'Website URL',
            image: 'Image',
            status: 'Status',
            enableNavigation: 'Enable Navigation',
            enableMakeOffer: 'Enable Make a Deal',
            hubSpotValue: 'HubSpot Value'
          },
          lotMapSettings: {
            index: {
              enableLotMapIntegration: 'Enable Lot Map Integration',
              provider: 'Lot Map Provider',
              mapUrl: 'Lot Map URL',
              communityId: 'Partner Community ID',
              displaySpecHomes: 'Display Spec Homes',
              propertyId: 'Property ID',
              builderId: 'Builder ID',
              mapKeys: 'Map Keys',
              mapKeysPlaceholder: 'Type a map key and press enter',
              hideLotPrice: 'Hide Lot Price',
              lotWorksCommunityId: 'Lot Map Community ID'
            }
          },
          mailingLists: {
            index: {
              mailingLists: 'Mailing Lists',
              empty: 'No Mailing Lists Available'
            }
          },
          salesRepresentative: {
            index: {
              title: 'Sales Contact',
              salesContactName: 'Sales Contact Name',
              salesAddress: 'Sales Address'
            }
          }
        },
        list: {
          index: {
            title: 'Communities',
            queryPlaceholder: 'Search by name',
            buttons: {
              add: 'Community'
            }
          },
          listView: {
            index: {
              name: 'Name',
              city: 'City',
              state: 'Province/State',
              modelsCount: 'Number of models',
              status: 'Status',
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By'
            }
          },
          sorting: {
            index: {
              sort: 'Sort:',
              asc: 'A - Z',
              desc: 'Z - A'
            }
          }
        },
        show: {
          index: {
            buttons: {
              preview: 'Get Preview Link',
              clone: 'Clone'
            },
            tabs: {
              overview: 'Overview',
              productTypes: 'Product Types',
              models: 'Models',
              phases: 'Phases',
              images: 'Images',
              salesRepresentatives: 'Sales Representatives / Teams',
              modifications: 'Modifications',
              mortgageProviders: 'Mortgage Providers',
              reservationDocument: 'Reservation Document'
            },
            clone: {
              title: 'Are you sure to clone %{communityName} Community?',
              message: 'Only images and Community assets are copied.'
            }
          },
          overview: {
            index: {
              city: 'City',
              province: 'Province / State',
              subRegion: 'Sub-Region',
              address: 'Address',
              description: 'Description',
              websiteUrl: 'Website URL',
              displaySampleMapOptions: {
                yes: 'Yes',
                no: 'No'
              },
              status: 'Status',
              communityId: 'Community ID',
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By',
              salesContactName: 'Sales Contact Name',
              salesAddress: 'Sales Address',
              externalId: 'External ID',
            },
            lotMapSettings: {
              index: {
                title: 'Lot Map Settings',
                provider: 'Provider',
                lotWorks: {
                  mapUrl: 'Lot Map URL',
                  communityId: 'Lot Map Community ID',
                  displaySpecHomes: 'Display Spec Homes',
                  displaySpecHomesOptions: {
                    yes: 'Yes',
                    no: 'No'
                  }
                },
                focus360: {
                  communityId: 'Partner Community ID'
                },
                streetscape: {
                  propertyId: 'Property ID',
                  builderId: 'Builder ID',
                  mapKeys: 'Map Keys',
                  hideLotPrice: 'Hide Lot Price',
                  displaySpecHomes: 'Display Spec Homes'
                }
              }
            }
          },
          productTypes: {
            index: {
              buttons: {
                add: 'Add Product Type',
                salesRep: 'Sales Rep',
                productTypeSettings: 'Product Type Settings'
              }
            },
            salesRepFormModal: {
              index: {
                title: 'Sales Representative',
                salesImage: 'Sales Image',
                salesPhone: 'Sales Phone',
                salesEmail: 'Sales Email',
                salesContactName: 'Sales Contact Name',
                salesAddress: 'Address',
                salesCity: 'City',
                salesProvince: 'Province / state',
                errorMessages: {
                  salesPhone: 'Please enter a valid sales phone number',
                }
              }
            },
            settingsModal: {
              index: {
                title: 'Product Type Settings: %{productTypeName}',
                minLotPrice: 'Minimum Lot Price',
                lotMapParams: 'Lot Map Parameters',
                lotMapParamsPlaceholder: 'Type a param name and press enter',
                standardizedLotWorksProductType: 'Lotworks Standardized Product Type',
                enableLotMap: 'Enable Lot Map'
              }
            },
            salesRepresentativesModal: {
              index: {
                title: 'Sales Representatives / Teams',
              }
            }
          },
          models: {
            index: {
              buttons: {
                add: 'Add Model'
              }
            }
          },
          phases: {
            form: {
              index: {
                newTitle: 'New Phase',
                editTitle: 'Edit Phase',
                phase: 'Phase',
                architecturalPrice: 'Architectural Price',
              }
            },
            list: {
              index: {
                phase: 'Phase',
                architecturalPrice: 'Architectural Price',
                buttons: {
                  add: 'Phase',
                  delete: 'Delete',
                  edit: 'Edit'
                }
              }
            }
          }
        }
      },
      models: {
        form: {
          index: {
            newTitle: 'New Model',
            editTitle: 'Edit Model',
            tourUrl: '3D Tour Url',
            quickPossessionUrl: 'Quick Possession Url',
            productTypeId: 'Product Type',
            name: 'Name',
            intro: 'Intro',
            beds: 'Beds',
            baths: 'Baths',
            sizeSqft: 'Size (sq.ft.)',
            basePrice: 'Base Price',
            image: 'Image',
            status: 'Status',
            openTourUrlInOverlayWindow: 'Open this URL in overlay window (please check X-Frame-Options of this URL)',
            openQuickPossessionUrlInOverlayWindow: 'Open this URL in overlay window (please check X-Frame-Options of this URL)',
            uTourUrl: 'UTour Url',
            useOwnlyQuickPossessions: 'Use Ownly Quick Possession URLs',
            addQuickPossessionUrl: 'Add Quick Possession URL',
            quickPossessionUrls: 'Quick Possession URLs',
            externalId: 'External ID'
          }
        },
        list: {
          index: {
            title: 'Models',
            buttons: {
              add: 'Model'
            }
          },
          gridView: {
            listItem: {
              index: {
                from: 'From %{price}',
                sizeSqft: '%{size} sq.ft.',
                includes: 'Includes lot, house & GST',
                notIncludesTaxes: 'NOT INCLUDING TAXES',
                tooltip: 'Model Report'
              }
            },
            communitiesTooltip: {
              communitiesList: {
                index: {
                  title: 'Communities'
                }
              }
            }
          },
          listView: {
            index: {
              name: 'Name',
              size: 'Size',
              bedrooms: 'Bedrooms',
              bathrooms: 'Bathrooms',
              price: 'Base Price',
              status: 'Status',
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By'
            }
          },
          communityFilter: {
            index: {
              community: 'Community:',
              all: 'All'
            }
          },
          productTypeFilter: {
            index: {
              productType: 'Product Type:',
              all: 'All'
            }
          }
        },
        show: {
          index: {
            buttons: {
              preview: 'Get Preview Link',
              clone: 'Clone'
            },
            tabs: {
              details: 'Details',
              floors: 'Floors',
              elevations: 'Elevations',
              packages: 'Packages',
              specifications: 'Optional Upgrades',
              palettes: 'Palettes',
              images: 'Images',
              lotWorks: 'LotWorks',
              phases: 'Phases',
              priceRules: 'Price Rules',
              modifications: 'Modifications'
            },
            clone: {
              title: 'Are you sure to clone %{modelName} Model?',
              message: 'Only images and Model assets are copied. Price Rules will not be copied to avoid dependencies.'
            }
          },
          details: {
            index: {
              productType: 'Product Type',
              intro: 'Intro',
              sizeSqft: 'Size (sq.ft.)',
              basePrice: 'Base Price',
              totalPrice: 'Total Price',
              tourUrl: '3D Tour Url',
              quickPossessionUrl: 'Quick Possession Url',
              status: 'Status',
              uTourUrl: 'UTour Url',
              salesSimplicityId: 'Sales Simplicity ID',
              modelId: 'Model ID',
              priceMessage: 'NOT INCLUDING TAXES',
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By',
              externalId: 'External ID',
            }
          },
          floors: {
            index: {
              floorPlanProvider: 'Floor Plan Provider'
            },
            focus360Settings: {
              index: {
                floorPlanCode: 'Floor Plan Code',
                messages: {
                  saved: 'Focus 360 settings saved.'
                }
              }
            }
          },
          elevations: {
            index: {
              buttons: {
                add: 'Elevation'
              }
            },
            formModal: {
              index: {
                newTitle: 'New Elevation',
                editTitle: 'Edit Elevation',
                name: 'Name',
                description: 'Description',
                image: 'Image',
                isDefault: 'Default',
              }
            },
            listItem: {
              index: {
                isDefault: 'Default',
                description: 'Description',
              }
            }
          },
          specifications: {
            index: {
              buttons: {
                add: 'Optional Upgrade'
              }
            },
            formModal: {
              index: {
                newTitle: 'New Optional Upgrade',
                editTitle: 'Edit Optional Upgrade',
                name: 'Name'
              }
            }
          },
          packages: {
            listItem: {
              index: {
                default: 'Default',
                buttons: {
                  addCommunity: 'Add Community'
                }
              }
            },
            addDropDown: {
              index: {
                title: 'Add Package'
              }
            },
          },
          palettes: {
            index: {
              selectPalettes: 'Select Palettes',
              empty: 'No Palettes Available'
            },
            addDropDown: {
              index: {
                title: 'Add Palette'
              }
            },
          },
          directLinkModal: {
            index: {
              title: 'Share Link',
              previewTitle: 'Preview Link',
              selectCommunity: 'Select Community',
              noCommunities: 'No Communities Available',
              reserveNowCheckbox: 'Reserve Now',
              messages: {
                copied: 'Link has been copied to clipboard.'
              },
              buttons: {
                copy: 'Copy Link'
              }
            }
          },
          lotWorks: {
            index: {
              frontage: 'Frontage',
              buildpocket: 'Buildpocket',
              min: 'Min',
              max: 'Max',
              minLotPrice: 'Min Lot Price',
              messages: {
                saved: 'LotWorks settings saved.'
              }
            }
          },
          images: {
            index: {
              isDefault: 'Default'
            }
          }
        }
      },
      mortgageRate: {
        index: {
          title: 'Mortgage'
        },
        navTabs: {
          index: {
            mortgageRate: 'Mortgage Rate',
            mortgageProviders: 'Mortgage Providers'
          }
        }
      },
      palettes: {
        list: {
          index: {
            title: 'Palettes',
            buttons: {
              add: 'Palette'
            }
          },
          listItem: {
            index: {
              from: 'From %{price}'
            }
          }
        },
        form: {
          index: {
            newTitle: 'New Palette',
            editTitle: 'Edit Palette',
            name: 'Name',
            basePrice: 'Base Price',
            description: 'Description'
          }
        },
        show: {
          index: {
            tabs: {
              overview: 'Overview',
              images: 'Images',
              modifications: 'Modifications'
            }
          },
          overview: {
            index: {
              basePrice: 'Base Price',
              description: 'Description',
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By'
            }
          },
        }
      },
      cities: {
        list: {
          index: {
            title: 'Cities',
            buttons: {
              add: 'City'
            }
          }
        },
        form: {
          index: {
            newTitle: 'New City',
            editTitle: 'Edit City',
            name: 'Name',
            state: 'Province / State',
            description: 'Description',
            longDescription: 'Long Description'
          }
        },
        show: {
          index: {
            tabs: {
              overview: 'Overview',
              images: 'Images',
              modifications: 'Modifications',
              reservationDocument: 'Reservation Document'
            }
          },
          overview: {
            index: {
              state: 'Province / State',
              description: 'Description',
              longDescription: 'Long Description',
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By'
            }
          },
        }
      },
      teams: {
        components: {
          formModal: {
            index: {
              newTitle: 'Create a Team',
              editTitle: 'Edit Team',
              form: {
                name: 'Team Name',
                description: 'Description',
              },
              placeholders: {
                name: 'Team Name',
                description: 'Description',
              }
            }
          },
        },
        list: {
          index: {
            title: 'Teams',
            name: 'Name',
            company: 'Company',
            usersCount: 'Team Members',
            createdAt: 'Created At',
            buttons: {
              add: 'Create Team'
            }
          },
          listItem: {
            index: {
              usersCount: {
                zero: 'No Members',
                one: '1 Team Member',
                other: '%{count} Team Members'
              }
            },
            actionsMenu: {
              index: {
                getVerifiedLink: 'Get Verified Link'
              }
            }
          }
        },
        show: {
          index: {
            buttons: {
              getVerifiedLink: 'Get Verified Link',
            }
          },
          navTabs: {
            index: {
              overview: 'Overview',
              users: 'Members'
            }
          },
          overview: {
            index: {
              description: 'Description',
              company: 'Company'
            }
          },
          users: {
            index: {
              buttons: {
                add: 'Add Member'
              }
            },
            list: {
              index: {
                user: 'Member',
                permission: 'Permission Level'
              }
            },
            formModal: {
              index: {
                newTitle: 'Add Member',
                editTitle: 'Edit Member',
                userId: 'User',
                permission: 'Permission Level'
              }
            }
          }
        }
      },
      packages: {
        list: {
          index: {
            title: 'Packages',
            from: 'From %{price}',
            buttons: {
              add: 'Package'
            }
          }
        },
        form: {
          index: {
            newTitle: 'New Package',
            editTitle: 'Edit Package',
            name: 'Name',
            description: 'Details',
            image: 'Image',
            basePrice: 'Base Price'
          }
        },
        show: {
          index: {
            tabs: {
              overview: 'Overview',
              modifications: 'Modifications'
            }
          },
          overview: {
            index: {
              basePrice: 'Base Price',
              description: 'Description',
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By'
            }
          }
        }
      },
      companies: {
        list: {
          index: {
            title: 'Companies',
            archiveMessage: 'Do you want to archive this company?',
            archivedCompanies: 'Archived Companies',
            buttons: {
              add: 'Company'
            },
            errors: {
              deleteCurrentCompany: 'The current company cannot be deleted.'
            }
          }
        },
        archived: {
          index: {
            title: 'Archived Companies',
            companies: 'Companies',
            restoreMessage: 'Do you want to restore this company?',
            buttons: {
              add: 'Company'
            },
            errors: {
              restoreCurrentCompany: 'The current company cannot be restored.'
            }
          }
        },
        table: {
          index: {
            name: 'Name',
            createdAt: 'Created At',
            archivedAt: 'Archived At'
          }
        },
        settings: {
          index: {
            title: 'Company Settings',
            messages: {
              saved: 'Company settings has been saved!',
              notSaved: 'Company settings hasn\'t been saved. Please fix the errors.'
            }
          },
          navTabs: {
            index: {
              companyAndBranding: 'Company & Branding',
              siteAndUX: 'Site & UX',
              salesConfig: 'Sales Config',
              workflow: 'Workflow',
              communications: 'Communications',
              advancedSettings: 'Advanced Settings'
            }
          },
          tabsContent: {
            companyAndBranding: {
              index: {
                companyName: 'Company Name',
                country: 'Country',
                state: 'State / Province',
                city: 'City',
                companyLogo: 'Company logo',
                favicon: 'Favicon (optional)',
                textColor: 'UI Company Text Color',
                accentColor: 'Accent Color (Buttons)',
                floorPlanHighlightColor: 'Floor Plan Highlight Color',
                defaultHomePage: 'Default Home Page',
                enableCORSForJavaScriptSDK: 'Enable CORS for Javascript SDK',
                domains: 'Domains',
                excludeIpAddresses: 'Exclude IP addresses ',
                timezone: 'Timezone',
                helpTexts: {
                  companyName: 'This will be displayed on your Ownly URL’s.',
                  country: 'Select your country',
                  state: 'Select your state or province',
                  city: 'Select your city',
                  companyLogo: 'Update your company logo.<br/>This will be used in the header.',
                  favicon: 'Update your favicon.',
                  domains: 'The domain entered will be used to load the company content, as well as white list the domain.',
                  excludeIpAddresses: 'Enter in IP addresses you don’t want to include in your reports. Separate IP addresses by a coma.<br/>Your IP address is: %{ipAddress}'
                },
                buttons: {
                  addDomain: 'Add Domain'
                }
              },
              domainField: {
                index: {
                  placeholders: {
                    domain: 'https://'
                  }
                }
              }
            },
            siteAndUX: {
              index: {
                defaultHomePage: 'Default home Page',
                termsAndConditionsUrl: 'Terms & Conditions URL',
                enableCommunicationToggle: 'Enable Communication Toggle',
                startingFromForModel: 'Use “Starting From $” for Model',
                startingFromForCommunity: 'Use “Starting From $” for Communities',
                overrideQPSizeWithModelSize: 'Override Quick Possession Size with Model Size',
                enableExitPageReminder: 'Enable Exit Page Reminder',
                enableInitialLeadCapture: 'Enable Initial Lead Capture',
                enableNewHomeBuilds: 'Enable New Home Builds',
                enableLegacyUI: 'Enable Legacy UI ',
                introPageMessage: 'Intro Page Message',
                completionTitle: 'Completion Title',
                completionMessage: 'Completion Message',
                saveButtonText: 'Save Button Text',
                modelText: 'Model Text',
                verificationText: 'Verification Text',
                reserveNowButtonText: 'ReserveNow Button Text',
                completionTitleForQP: 'Completion Title for Quick Possession',
                completionMessageForQP: 'Completion Message for Quick Possession',
                reserveNowQPButtonText: 'ReserveNow QP Button Text',
                textForSaveQPButton: 'Text for Save Quick Possession Button',
                quickPossessionText: 'Quick Possession Text',
                singleFamilyPriceNote: 'Price Note - Single Family',
                multiFamilyPriceNote: 'Price Note - Multi Family',
                lotPremiumLabel: 'Lot Premium Label',
                helpTexts: {
                  defaultHomePage: 'The url entered will be used for users to redirect to your site via your logo.',
                  enableCommunicationToggle: 'This is required by CASTL only in Canada.',
                  startingFromForModel: 'This enables minimum price for a model.',
                  startingFromForCommunity: 'This enables minimum price for a community.',
                  overrideQPSizeWithModelSize: 'Models will override quick possession size (sqft).',
                  enableExitPageReminder: 'This enables pop up as a reminder to save before leaving Build & Price.',
                  enableInitialLeadCapture: 'Capture leads before users continue with Build & Price.',
                  enableNewHomeBuilds: 'This will be active for Build & Price only.',
                  enableLegacyUI: 'This feature is Build & Price specific.',
                  introPageMessage: 'This feature is Build & Price specific.',
                  completionTitle: 'This feature is Build & Price specific.',
                  completionMessage: 'This feature is Build & Price specific.',
                  saveButtonText: 'This feature is Build & Price specific.',
                  modelText: 'This feature is Build & Price specific.',
                  verificationText: 'This feature is Build & Price specific.',
                  reserveNowButtonText: 'This is enabled for ReserveNow.',
                  completionTitleForQP: 'This is enabled for Quick Possession.',
                  completionMessageForQP: 'This is enabled for Quick Possession.',
                  reserveNowQPButtonText: 'This is enabled for Quick Possession.',
                  textForSaveQPButton: 'This is enabled for Quick Possession.',
                  quickPossessionText: 'This is enabled for Quick Possession.',
                  singleFamilyPriceNote: 'By default the Price Note value is \'Incl lot, house, GST\'',
                  multiFamilyPriceNote: 'By default the Price Note value is \'Including GST\'',
                  lotPremiumLabel: 'By default the Lot Premium label is \'Lot Premium\''
                }
              }
            },
            salesConfig: {
              index: {
                companyType: 'Company Type',
                companyTypes: {
                  homeSales: 'Home Sales',
                  homeRentals: 'Home Rentals'
                },
                enableSingleFamilyHome: 'Enable Single Family Home',
                enableMultiFamilyHome: 'Enable Multi-Family Home',
                enableACHPayments: 'Enable ACH Payments',
                mortgageRate: 'Mortgage Rate',
                fixedDepositAmount: 'Fixed Deposit Amount',
                downPayment: 'Down Payment',
                loanTerm: 'Loan Term',
                years: '%{years} Years',
                interestRate: 'Interest Rate',
                enableValidUntil: 'Enable Valid Until Feature',
                validUntilDate: 'Valid Until Date',
                helpTexts: {
                  companyType: 'Select what how you want to use Ownly.',
                  enableACHPayments: 'ACH deposits will be enabled for Deposits',
                  mortgageRate: 'Enable mortgage rates & down deposits.',
                  fixedDepositAmount: 'Set the amount buyers will pay online for reserving the home.',
                  interestRate: 'Set the interest rate for the loan.',
                  enableValidUntil: 'This enables an expiry date for prices in Ownly.',
                  validUntilDate: 'Choose the time frame that your price will be honoured.',
                  buyingPowerInfo: '* Changes in this setting have an impact on the calculation of buying power as well.'
                }
              }
            },
            workflow: {
              index: {
                enableReserveNow: 'Enable ReserveNow',
                enableVerified: 'Enable Verified',
                enablePriceNegotiation: 'Enable Price Negotiation',
                enableReports: 'Enable Reports',
                enableEmbeddedReserveNow: 'Enable Embedded Experience',
                embeddedReserveNowBaseUrl: 'Base URL',
                enableIdVerification: 'Enable ID Verification',
                idVerificationProvider: 'ID Verification Provider',
                enablePreApprovalVerification: 'Enable Pre-Approval Verification',
                enableHomewise: 'Enable Homewise',
                enableDemoModeForHomewise: 'Enable Demo Mode for Homewise',
                bankVerificationProvider: 'Financial Verification Provider',
                phoneNumberMandatory: 'Make Phone Number Mandatory',
                enableLotSelectionFirst: 'Enable Lot Selection First',
                enableDemoSampleData: 'Enable Demo Sample Data',
                enableAirMilesForReserveNow: 'Enable Air Miles ReserveNow',
                enableAirMilesForVerified: 'Enable Air Miles Verified',
                helpTexts: {
                  enableLotSelectionFirst: 'Select to make lot selection prior to model selection in Build & Price.',
                  enableDemoSampleData: 'This Data is filled for demo purposes.'
                },
                placeholders: {
                  embeddedReserveNowBaseUrl: 'https://'
                }
              },
              workflows: {
                navTabs: {
                  index: {
                    reserveNowWorkflow: 'ReserveNow Workflow',
                    verifiedWorkflow: 'Verified Workflow'
                  }
                },
                components: {
                  configFile: {
                    index: {
                      title: 'Config File',
                      errors: {
                        notSaved: 'Invalid config file.'
                      }
                    }
                  }
                }
              }
            },
            communications: {
              index: {
                emailServiceProvider: 'Email Service Provider',
                sendersEmailAddress: 'Senders Email Address',
                buildAndPriceDisclaimers: 'Build & Price Disclaimers',
                helpTexts: {
                  sendersEmailAddress: 'This address will be used for sending out emails through Ownly.'
                },
                disclaimerSections: {
                  city: 'Intro Page',
                  communityProject: 'Intro Community / Project Page ',
                  lotMap: 'LotMap Page',
                  productType: 'Model Product Type',
                  modelDetails: 'Model Details Pages',
                  monthlyPaymentDisclaimer: 'Monthly Payment Disclaimer',
                  floorplans: 'Floorplans Page',
                  elevations: 'Elevations Page',
                  palettes: 'Palettes Page',
                  packages: 'Packages Page',
                  depositAmount: 'Deposit Amount Disclaimer',
                  summary: 'Summary Tab',
                  exportPagePdf: 'Export Page/ PDF',
                  sfPriceDisclaimer: 'Price Disclaimer - Single Family',
                  mfPriceDisclaimer: 'Price Disclaimer - Multi-Family',
                  qpDepositInPlace: 'Quick Possession with Deposit',
                  interestRate: 'Interest Rate',
                }
              }
            },
            advancedSettings: {
              index: {
                demoModeReserveNow: 'Demo Mode ReserveNow',
                demoModeVerified: 'Demo Mode Verified',
                contactStages: 'Contact Stages',
                contactStagesDescription: 'Click and drag to reorder the stages.',
                selectedStages: 'Selected Stages',
                unselectedStages: 'Unselected Stages',
                defaultStage: 'Default Stage',
                homePriceDisplayMode: 'Display Price Variant'
              },
              leadNotifications: {
                index: {
                  leadNotification: 'Lead Notification',
                  emptySalesReps: 'There\'s no sales representatives',
                  helpTexts: {
                    leadNotification: 'Select sales representatives'
                  }
                }
              },
              exportSettings: {
                index: {
                  exportCustomers: 'Allow to Export Customers',
                  confirmAlert: {
                    message: 'Are you sure to allow this company to export customers?'
                  }
                }
              },
              reportSettings: {
                index: {
                  enableReports: 'Enable Reports',
                  financialReports: 'Financial Reports',
                  financialHighlights: 'Financial Highlights',
                  marketingReport: 'Marketing Report'
                }
              }
            }
          },
          components: {
            saveChangesModal: {
              index: {
                title: 'Are you sure?',
                description: 'You\'ve made some changes to the company settings. Would you like to save them before leaving?'
              }
            },
            settingsSearch: {
              index: {
                searchBar: {
                  placeholder: 'Search Settings'
                }
              }
            }
          }
        }
      },
      users: {
        components: {
          formModal: {
            index: {
              newTitle: 'Create a User',
              editTitle: 'Edit User',
              companyId: 'Company',
              role: 'Role',
              firstName: 'First Name',
              lastName: 'Last Name',
              email: 'Email',
              phone: 'Phone',
              password: 'Password',
              cityId: 'City',
              confirmPassword: 'Confirm Password',
              passwordError: 'The passwords don\'t match',
              buttons: {
                addPhone: 'Phone'
              },
              errorMessages: {
                phone: 'Please enter a valid phone number'
              }
            }
          },
        },
        list: {
          index: {
            title: 'Users',
            user: 'User',
            company: 'Company',
            role: 'Role',
            emailVerified: 'Email Verified',
            lastActive: 'Last Active',
            buttons: {
              add: 'Create User'
            },
            errors: {
              deleteCurrentUser: 'The current logged user cannot be deleted.'
            },
            searchBarPlaceholder: 'Search for users',
          },
        },
        show: {
          index: {
            tabs: {
              profile: 'Profile',
              teams: 'Teams'
            },
            users: 'Users'
          },
          profile: {
            index: {
              id: 'User ID',
              email: 'Email',
              phone: 'Phone',
              company: 'Company',
              role: 'Role',
              city: 'City',
              createdAt: 'Created At'
            }
          },
          teams: {
            index: {
              buttons: {
                assignTeam: 'Assign Team'
              }
            },
            list: {
              index: {
                team: 'Team',
                permissionLevel: 'Permission Level'
              }
            },
            formModal: {
              index: {
                title: 'Assign Team',
                form: {
                  teamId: 'Team',
                  permissionLevel: 'Permission Level'
                },
                buttons: {
                  assign: 'Assign'
                }
              }
            }
          }
        }
      },
      integrations: {
        index: {
          title: 'Integrations',
          buttons: {
            customCode: 'Custom Code',
            hubSpot: 'HubSpot',
            exportData: 'Export Data',
            importData: 'Import Data',
            zapier: 'Zapier',
            googleSitemap: 'Google Sitemap',
            embedWidget: 'Embedded Shopping Cart',
            socialMedia: 'Social Media Login',
            externalDataSources: 'External Data Sources',
            webhooks: 'Webhooks',
            paymentMethods: 'Payment Methods',
            docuSign: 'DocuSign',
            sendGrid: 'SendGrid',
            tracking: 'Web Tracking',
            apiDocumentation: 'API Documentation'
          }
        },
        customCode: {
          index: {
            title: 'Custom Code',
            headerScripts: 'Scripts in Header',
            headerScriptsDescription: 'These scripts will be printed in the <head> section',
            footerScripts: 'Scripts in Footer',
            footerScriptsDescription: 'These scripts will be printed above </body> tag'
          }
        },
        hubSpot: {
          index: {
            title: 'HubSpot Settings',
            tabs: {
              generalSettings: 'General Settings',
              fieldsMapping: 'Fields Mapping'
            },
            messages: {
              successfulLogin: 'Success! You can now continue with the configuration process.',
              success: 'Your HubSpot settings has been successfully updated!'
            }
          },
          generalSettings: {
            index: {
              apiKey: 'HubSpot API Key',
              contactListId: 'HubSpot Contact List ID',
              contactListIdHelp: 'You must add List Id to add contacts in your HubSpot lists. Works only for Static Lists.',
              lifecycleStage: 'Lifecycle Stage',
              leadStatus: 'Lead Status',
              leadStatuses: {
                new: 'New',
                open: 'Open',
                inProgress: 'In Progress',
                openDeal: 'Open Deal',
                unqualified: 'Unqualified',
                attemptedToContact: 'Attempted to Contact',
                connected: 'Connected',
                badTiming: 'Bad Timing'
              },
              lifecycleStages: {
                subscriber: 'Subscriber',
                lead: 'Lead',
                marketingQualifiedLead: 'Marketing Qualified Lead',
                salesQualifiedLead: 'Sales Qualified Lead',
                opportunity: 'Opportunity',
                customer: 'Customer',
                evangelist: 'Evangelist',
                other: 'Other'
              }
            }
          },
          fieldsMapping: {
            index: {
              ownlyField: 'Ownly Field',
              hubSpotField: 'HubSpot Custom Field',
              customValue: 'Custom Value',
              casl: 'CASL',
              leadSource: 'Lead Source',
              buildLink: 'Ownly Build Link',
              community: 'Community',
              model: 'Model',
              verifiedStatusField: 'Verified Status',
              reservationStepField: 'Reservation Step',
              project: 'Project',
              unit: 'Unit',
              placeholders: {
                caslCustomValue: 'true'
              },
              messages: {
                caslCustomValue: 'Ensure the value matches the HubSpot configuration. For example, use "True" if required by HubSpot.'
              }
            }
          },
          loginToApp: {
            index: {
              title: 'Update',
              description: 'Please, before continuing, install the Ownly App in your HubSpot account',
              buttons: {
                install: 'Click here to Install'
              }
            }
          }
        },
        exportData: {
          index: {
            title: 'Export Data'
          },
          exportButton: {
            index: {
              fileReady: 'Your Excel file is ready to download.',
              buttons: {
                exportData: 'Export Now!',
                exporting: 'Exporting...',
                download: 'Download Excel'
              }
            }
          }
        },
        importData: {
          index: {
            title: 'Import Data',
          },
          form: {
            index: {
              file: 'File to Import',
              acceptedFiles: 'You must choose an Excel file',
              buttons: {
                startImport: 'Start Import',
                importing: 'Importing...'
              }
            }
          },
          downloadTemplate: {
            index: {
              download: 'Download template',
              here: 'here!'
            }
          },
          details: {
            index: {
              title: 'Import Details',
              cities: 'Cities',
              sfCommunities: 'Communities',
              sfModels: 'Models (Single Family)',
              sfProductTypes: 'Product Types',
              sfCommunityProductTypes: 'Community Product Types',
              sfElevations: 'Elevations',
              sfPackages: 'Packages (Single Family)',
              sfFloors: 'Floors (Single Family)',
              sfFloorplans: 'Floorplans (Single Family)',
              sfOptionalUpgrades: 'Optional Upgrades',
              sfCommunityModels: 'Community Models',
              sfModelPackages: 'Model Packages (Single Family)',
              sfPriceRules: 'Price Rules',
              sfQuickPossessions: 'Quick Possessions',
              mfProjects: 'Projects',
              mfBuildings: 'Buildings',
              mfModels: 'Models (Multi Family)',
              mfBuildingModels: 'Building Models (Multi Family)',
              mfUnits: 'Units',
              mfPackages: 'Packages (Multi Family)',
              mfPalettes: 'Palettes (Multi Family)',
              mfFloors: 'Floors (Multi Family)',
              mfFloorplans: 'Floorplans (Multi Family)',
              mfModelPackages: 'Model Packages (Multi Family)',
              mfModelPalettes: 'Model Palettes (Multi Family)',
              mfOptionTypes: 'Option Types',
              mfUnitOptions: 'Unit Options',
              tabs: {
                importedObjects: 'Imported Objects',
                notImportedObjects: 'Not Imported Objects'
              }
            }
          }
        },
        zapier: {
          index: {
            title: 'Zapier',
            connected: 'Connected on %{connectedDate}',
            disconnected: 'Go to <a href="https://zapier.com" target="_blank">Zapier</a> to connect our Ownly app.',
            buttons: {
              disconnect: 'Disconnect'
            }
          }
        },
        googleSitemap: {
          index: {
            title: 'Google Sitemap',
            sitemapUrl: 'Sitemap URL',
            noDomain: 'Please configure a domain for your company to get a Sitemap URL',
            messages: {
              copied: 'Sitemap URL copied!'
            }
          }
        },
        syncData: {
          index: {
            title: 'Sync Data'
          }
        },
        embedWidget: {
          index: {
            title: 'Embedded Shopping Cart',
            noDomain: 'Please configure a domain for your company to embed widget in your site.',
          },
          navTabs: {
            index: {
              buildHomeSetup: 'Build Home set up',
              settings: 'Settings'
            }
          },
          settings: {
            index: {
              windowWidth: 'Window Width',
              windowWidthDesc: 'Size of slide out window in pixels (Px) or percentage (%). Default size 70%. Min size 480px.',
              messages: {
                saved: 'Settings saved!'
              }
            }
          },
          buildHomeSetup: {
            navTabs: {
              index: {
                installation: 'Installation',
                buildHome: 'Build Home',
                saveHome: 'Save Home',
                reserveHome: 'Reserve Home',
                myHomes: 'My Homes',
                quickPossessions: 'Quick Possessions',
                javaScriptSdk: 'JavaScript SDK',
                getVerified: 'Get Verified',
                getRentalVerified: 'Get Rental Verified'
              }
            },
            installation: {
              index: {
                installation: 'Installation',
                installationDesc: 'Insert the following HTML code before the closing <code>&lt;body&gt;</code> tag preferably.',
              }
            },
            buildHome: {
              index: {
                buildHome: 'Build Home',
                buildHomeDesc: 'Add the <code>ownly-build-home</code> class to your element.',
                buildSingleFamilyHome: 'Build Single Family Home',
                buildSingleFamilyHomeDesc: 'For a specific model add <code>data-community-id</code> and <code>data-model-id</code> attributes to your element.<br/>To drill down to the Community, add the <code>data-community-id</code> attribute only.',
                addNewCommunityAndModel: 'Also create or update a new community and model by adding more attributes.',
                addNewModelOption: 'Whenever you add a new model option, increment the index specified in the attribute:<br /><code>data-model-option1-name, data-model-option2-name, data-model-option3-name, [...]</code>',
                buildMultiFamilyHome: 'Build Multi Family Home',
                buildMultiFamilyHomeDesc: 'For a specific unit add <code>data-building-id</code> and <code>data-unit-id</code> attributes to your element.<br/>For a specific model add <code>data-building-id</code> and <code>data-model-id</code> attributes to your element.<br/>To drill down to the Project, add the <code>data-project-id</code> attribute only.',
                addNewProjectAndUnit: 'Also create or update a new project, building, unit, package and palette by adding more attributes.',
                addNewUnitOption: 'Whenever you add a new unit option, increment the index specified in the attribute:<br /><code>data-unit-option1-name, data-unit-option2-name, data-unit-option3-name, [...]</code>',
                example: 'Example:',
                list: {
                  name: 'Name',
                  attribute: 'Attribute',
                  type: 'Type',
                  required: 'Required'
                }
              }
            },
            saveHome: {
              index: {
                saveHome: 'Save Home',
                saveHomeDesc: 'Add the <code>ownly-save-home</code> class to your element.',
                example: 'Example:',
                similarityDesc: 'You can use the same attributes as Build Home. This function allows you to save the home info'
              }
            },
            reserveHome: {
              index: {
                reserveHome: 'Reserve Home',
                reserveHomeDesc: 'Add the <code>ownly-reserve-home</code> class to your element.',
                example: 'Example:',
                similarityDesc: 'You can use the same attributes as Build Home. Once user confirms the listing/home, the user will directly go in to the reserve now flow.'
              }
            },
            myHomes: {
              index: {
                myHomes: 'My Homes',
                myHomesDesc: 'Add the <code>ownly-my-homes</code> class to your element.',
                example: 'Example:'
              }
            },
            quickPossessions: {
              index: {
                quickPossessions: 'Quick Possessions',
                quickPossessionsDesc: 'Add the <code>data-listing-id</code> attribute to link and open shopping cart for a Quick Possession Home.<br/>To get those IDs, go to your quick possession and copy the "Listing Id".',
                buildQuickPossessionHome: 'Build Quick Possession Home',
                showQuickPossessions: 'To show quick possessions matching the community or model, add <code>data-listing-community-id</code> or <code>data-listing-model-id</code> attributes to your element.',
                addNewQuickPossession: 'Also create or update a new quick possession by adding more attributes.',
                addNewModelFloor: 'Whenever you add a new model floor, increment the index specified in the attribute:<br /><code>data-listing-model-floor1-name, data-listing-model-floor2-name, data-listing-model-floor3-name, [...]</code>',
                example: 'Example:',
                list: {
                  name: 'Name',
                  attribute: 'Attribute',
                  type: 'Type',
                  required: 'Required'
                }
              }
            },
            javascriptSdk: {
              index: {
                title: 'JavaScript SDK',
                description: 'JavaScript SDK allows you manage the build and widget programmatically.<br/><br/>We trigger an event named <code>ownly.ready</code> on the global document object when the SDK is ready. We recommend you encapsulate all your actions in the callback of that event:',
                api: 'API',
                apiDescription: 'The <code>api</code> object allows you to add and remove builds.',
                apiAttributes: 'You can pass all available attributes to build the Single Family home, Multi Family Home and Quick Possession.',
                apiRemoveBuild: 'Also, you can remove the build added.',
                apiGetBuildUrl: 'You can retrieve the URL of the build',
                widget: 'Widget',
                widgetDescription: 'The <code>widget</code> object allows you to open and close the widget window.',
                example: 'Example',
                getVerifiedBuildHomeAttributes: 'Optionally you can pass all available attributes to build the Single Family home, Multi Family Home and Quick Possession to the <code>Ownly.widget.getVerified()</code> and <code>Ownly.widget.getRentalVerified()</code> calls.'
              }
            },
            getVerified: {
              index: {
                getVerified: 'Get Verified',
                getVerifiedDesc: 'Add the <code>ownly-get-verified</code> class to your element.',
                example: 'Example:',
                otherAttributes: 'Optionally you can add the following attributes:',
                buildHomeAttributes: 'Also, you can pass all available attributes to build the Single Family home, Multi Family Home and Quick Possession.',
                list: {
                  attribute: 'Attribute',
                  type: 'Type',
                  description: 'Description'
                }
              }
            },
            getRentalVerified: {
              index: {
                getVerified: 'Get Rental Verified',
                getVerifiedDesc: 'Add the <code>ownly-rental-verified</code> class to your element.',
                example: 'Example:',
                otherAttributes: 'Optionally you can add the following attributes:',
                buildHomeAttributes: 'Also, you can pass all available attributes to build the Single Family home, Multi Family Home and Quick Possession.',
                list: {
                  attribute: 'Attribute',
                  type: 'Type',
                  description: 'Description'
                }
              }
            }
          }
        },
        socialMedia: {
          index: {
            title: 'Social Media Login',
            tabs: {
              facebook: 'Facebook',
              google: 'Google',
              apple: 'Apple'
            },
            facebook: {
              index: {
                appId: 'App ID',
                appSecret: 'App secret'
              }
            },
            google: {
              index: {
                clientId: 'Client ID',
                clientSecret: 'Client secret'
              }
            },
            apple: {
              index: {
                clientId: 'Client ID',
                clientSecret: 'Client secret'
              }
            }
          }
        },
        webhooks: {
          list: {
            index: {
              title: 'Webhooks',
              hookUrl: 'Endpoint URL',
              events: 'Events',
              enabled: 'Enabled',
              buttons: {
                add: 'Add Webhook'
              }
            }
          },
          formModal: {
            index: {
              newTitle: 'New Webhook',
              editTitle: 'Edit Webhook',
              hookUrl: 'Endpoint URL',
              events: 'Events to listen',
              hookUrlPlaceholder: 'https://',
              enabled: 'Enabled',
              eventHelps: {
                contact: {
                  created: 'Occurs whenever a contact is created.'
                },
                build: {
                  created: 'Occurs whenever a build is created.'
                }
              }
            }
          },
          webhookDeliveries: {
            index: {
              title: 'Deliveries',
              breadcrumb: {
                webhooks: 'Webhooks',
              }
            },
            list: {
              listItem: {
                status: {
                  index: {
                    delivered: 'Delivered',
                    failed: 'Failed'
                  }
                }
              }
            },
            show: {
              index: {
                response: 'Response',
                request: 'Request',
                errorMessage: 'Error: %{error}'
              }
            }
          }
        },
        paymentMethods: {
          index: {
            title: 'Payment Methods'
          },
          navTabs: {
            index: {
              creditCard: 'Credit Card'
            }
          },
          creditCard: {
            index: {
              description: 'Configure Stripe to accept payments with Credit Card.',
              secretKey: 'Secret Key',
              publishableKey: 'Publishable Key',
              currency: 'Currency',
              enableImmediatePayout: 'Enable Immediate Payout',
              paymentMethods: 'Payment Methods',
              enableRefunds: 'Enable Refunds',
              messages: {
                saved: 'Credit Card settings saved.'
              },
            },
            connectionBadge: {
              index: {
                connected: 'Connected',
                notConnected: 'Not Connected'
              }
            }
          }
        },
        docuSign: {
          index: {
            title: 'DocuSign'
          },
          navTabs: {
            index: {
              connection: 'Connection',
              settings: 'Settings',
              userGuides: 'User Guides'
            }
          },
          connection: {
            index: {
              connected: 'Connected as',
              buttons: {
                connect: 'Connect',
                disconnect: 'Disconnect',
                reconnect: 'Connect again'
              },
              messages: {
                notConnected: 'An error occurred while connecting to DocuSign. Please try again.',
                disconnected: 'Your account was successfully disconnected.',
                reconnect: 'An error occurred while processing your last request. Please connect your account again.'
              },
              confirmAlert: {
                message: 'Please confirm you want to disconnect your DocuSign account.'
              },
              details: {
                name: 'Name',
                email: 'Email',
                date: 'Date',
                account: 'Account'
              }
            }
          },
          settings: {
            index: {
              enableGenericTemplate: 'Enable Generic Template',
              uploadDocument: 'Upload Document',
              selectTemplate: 'Select Template from DocuSign',
              templateId: 'Template',
              templateName: 'Template Name',
              templateNameDefault: 'Ownly template',
              emailSubject: 'Email Subject',
              emailSubjectDefault: 'signature completed',
              documentToSign: 'Document to Sign',
              editDocumentTemplate: 'Edit document template in DocuSign',
              buttons: {
                uploadFile: 'Upload File'
              },
              messages: {
                saved: 'DocuSign settings saved.',
                notSaved: 'There was an error saving the document template to DocuSign. Please try again.',
                locked: 'You are editing this template in Docusign, please close the template in Docusign and try again.'
              }
            }
          },
          userGuides: {
            navTabs: {
              index: {
                documentTemplate: 'Document Template'
              }
            },
            documentTemplate: {
              index: {
                title: 'Steps to edit the document template',
                step1: 'Click Edit document template in DocuSign.',
                step2: 'Click EDIT and then NEXT.',
                step3: 'Locate the Signature field.',
                step4: 'Drag the Signature field to the location on the document where the customer needs to sign.',
                step5: '(Optional) To add custom fields, select the Custom Fields palette.',
                step5Note: 'Note: Custom fields will be automatically populated when the customer signs the document.',
                step6: 'Select SAVE AND CLOSE.',
              }
            }
          }
        },
        sendGrid: {
          index: {
            title: 'SendGrid'
          },
          contactLists: {
            list: {
              index: {
                title: 'Contact Lists',
                sendGrid: 'SendGrid',
                name: 'Name',
                count: 'Count',
                buttons: {
                  add: 'Add List'
                }
              }
            },
            formModal: {
              index: {
                newTitle: 'New Contact List',
                editTitle: 'Edit Contact List',
                id: 'SendGrid Contact List ID',
                name: 'Name'
              }
            },
            contacts: {
              index: {
                title: 'Contacts',
                sendGrid: 'SendGrid',
                contactLists: 'Contact Lists',
                buttons: {
                  add: 'Add Contact'
                }
              },
              list: {
                index: {
                  name: 'Name',
                  email: 'Email',
                  addedDate: 'Added Date',
                  updatedDate: 'Updated Date'
                }
              },
              formModal: {
                index: {
                  newTitle: 'New Contact',
                  editTitle: 'Edit Contact',
                  firstName: 'First Name',
                  lastName: 'Last Name',
                  email: 'Email',
                  phoneNumber: 'Phone Number'
                }
              }
            }
          },
          navTabs: {
            index: {
              generalSettings: 'General Settings',
              mailingList: 'Automation Rules'
            }
          },
          generalSettings: {
            index: {
              apiKey: 'SendGrid API Key',
              messages: {
                saved: 'SendGrid settings saved.'
              }
            }
          },
          mailingList: {
            index: {
              addedHomeListId: 'SendGrid Contact List ID',
              addedHomeListIdHelp: 'When a user saves a home, we will automatically add the contact to this SendGrid Contact List.',
              messages: {
                saved: 'SendGrid automation rules saved.'
              },
              buttons: {
                manageContactLists: 'Update Contact List'
              }
            }
          }
        },
        tracking: {
          index: {
            title: 'Web Tracking',
          },
          navTabs: {
            index: {
              trackingCode: 'Tracking Code',
              forms: 'Forms',
              pages: 'Pages'
            }
          },
          trackingCode: {
            navTabs: {
              index: {
                installation: 'Installation',
                javaScriptSdk: 'JavaScript SDK'
              }
            },
            installation: {
              index: {
                installation: 'Installation',
                installationDesc: 'Copy and paste this tracking code into every page of your site, just before the <code>&lt;/body&gt;</code> tag.',
                buttons: {
                  emailInstallationCode: 'Email the installation code'
                }
              },
              sendTrackingCodeModal: {
                index: {
                  title: 'Send Tracking Code',
                  email: 'Email Address',
                  buttons: {
                    sendEmail: 'Send Email'
                  }
                }
              }
            },
            javaScriptSdk: {
              index: {
                title: 'JavaScript SDK',
                description: 'JavaScript SDK allows you create page view event and form submission event programmatically.',
                pageTracking: 'Page Tracking',
                pageTrackingDescription: 'To track a page, you need to pass the <code>page URL</code> visited and optionally a <code>page title</code>.',
                formTracking: 'Form Tracking',
                formTrackingDescription: 'To track a form submission, you need to pass the <code>form name</code>, <code>form data</code> and <code>page URL</code>.',
                formDataDescription: `The <code>form data</code> parameter must be an array with all form elements:
                <ul>
                    <li><code>name</code> is the field name.</li>
                    <li><code>type</code> is the field type (text, email, select, radio, checkbox,...).</li>
                    <li><code>value</code> is the field value.</li>
                </ul>`
              }
            }
          },
          forms: {
            list: {
              index: {
                title: 'Forms',
                name: 'Name/URL',
                createdAt: 'Created At',
                isMapped: 'Fields Mapped',
                formSubmissionsCount: 'Submissions Count',
                lastSubmittedAt: 'Last Submitted At'
              },
              listItem: {
                index: {
                  yes: 'Yes',
                  buttons: {
                    mapFields: 'Map Fields'
                  }
                }
              }
            },
            formModal: {
              index: {
                title: 'Fields Mapping',
                name: 'Field Name',
                type: 'Field Type',
                mapped: 'Field Mapped',
                exclude: 'Exclude'
              }
            }
          },
          pages: {
            list: {
              index: {
                title: 'Pages',
                url: 'URL',
                mapTo: 'Map to',
                communityModel: 'Community/Model',
                createdAt: 'Created At',
                visitsCount: 'Visits Count',
                lastVisitedAt: 'Last Visited At',
                buttons: {
                  add: 'Page'
                }
              },
              listItem: {
                index: {
                  community: 'Community',
                  model: 'Model'
                }
              }
            },
            formModal: {
              index: {
                title: 'Tracking Page',
                url: 'URL',
                urlPlaceholder: 'https://',
                mapActivityTo: 'Map activity to',
                community: 'Community',
                model: 'Model'
              }
            }
          }
        },
      },
      productTypes: {
        list: {
          index: {
            title: 'Product Types',
            heading: 'Product Types',
            buttons: {
              add: 'Product Type'
            }
          },
          listView: {
            index: {
              name: 'Name',
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By'
            }
          },
        },
        form: {
          index: {
            newTitle: 'New Product Type',
            editTitle: 'Edit Product Type',
            name: 'Name',
            image: 'Image',
            lotMapParams: 'Lot Map Params'
          }
        },
        show: {
          index: {
            tabs: {
              overview: 'Overview',
              modifications: 'Modifications'
            }
          },
          overview: {
            index: {
              name: 'Name',
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By'
            }
          }
        }
      },
      settings: {
        index: {
          title: 'Settings'
        }
      },
      reports: {
        index: {
          title: 'Reports'
        }
      },
      customers: {
        list: {
          index: {
            homeSales: {
              title: 'Customers',
              deletedCustomers: 'Deleted Customers',
            },
            homeRental: {
              title: 'Applicants',
              deletedCustomers: 'Deleted Applicants',
            },
            buttons: {
              buyNow: 'Buy Now',
              getVerifiedLink: 'Get Verified Link'
            },
            searchBar: {
              placeholder: 'Search for customers'
            }
          },
          socialMediaLoginType: {
            index: {
              signUp: 'Sign Up with %{name}'
            }
          },
          listView: {
            listHeader: {
              index: {
                homeSales: {
                  customer: 'Customer',
                },
                homeRental: {
                  customer: 'Applicant',
                },
                reservationProgress: 'Reservation Progress',
                verificationProgress: 'Verification Progress',
                buyingPower: 'Buying Power',
                rentingPower: 'Renting Power',
                browsingPrice: 'Browsing Price',
                signUpDate: 'Sign-up Date',
                lastActive: 'Last Active'
              }
            },
            listItem: {
              customColumnValue: {
                verifiedStatusBadge: {
                  index: {
                    fullyVerified: 'Fully Verified',
                    verified: 'Verified'
                  }
                }
              }
            }
          },
          assignedFilter: {
            index: {
              all: 'All Customers',
              assigned_to_me: 'Assigned to Me'
            }
          },
          columnsPickerModal: {
            index: {
              title: 'Edit Columns',
              description: 'Choose which columns you see',
              availableColumnsTitle: 'Select the columns you would like to be active',
              availableColumnsDescription: 'Check them off to add them to the order above',
              editColumnOrder: 'Edit Column Order',
              selectColumns: 'Select Columns'
            }
          },
          editColumnsButton: {
            index: {
              title: 'Edit Columns'
            }
          },
          filtersModal: {
            index: {
              title: 'Filters',
              description: 'Apply filters'
            },
            interestsFilter: {
              index: {
                title: 'Interests'
              }
            },
            lastActiveFilter: {
              index: {
                title: 'Last Active'
              }
            },
            regionFilter: {
              index: {
                title: 'Region'
              }
            },
            statusFilter: {
              index: {
                title: 'Status'
              }
            },
            reservationProgressFilter: {
              index: {
                title: 'Reservation Progress'
              }
            },
            verificationProgressFilter: {
              index: {
                title: 'Verification Progress'
              }
            },
            signUpDateFilter: {
              index: {
                title: 'Sign-up Date'
              }
            },
            buyingPowerFilter: {
              index: {
                title: 'Buying Power'
              }
            },
            rentingPowerFilter: {
              index: {
                title: 'Renting Power'
              }
            },
            annualIncomeFilter: {
              index: {
                title: 'Annual Income'
              }
            },
            assignedToFilter: {
              index: {
                title: 'Assigned To',
                salesTeams: 'Sales Teams',
                salesRepresentatives: 'Sales Representatives'
              }
            }
          },
          assignedToFilter: {
            index: {
              select: 'Company Wide',
            }
          },
          interestsFilter: {
            index: {
              select: 'All Communities/Projects'
            }
          },
          components: {
            exportModal: {
              index: {
                title: 'Export Customers',
                messages: {
                  exporting: 'Exporting the customer list, please wait...',
                  exported: 'The customer list has been exported successfully!'
                }
              }
            }
          }
        },
        show: {
          index: {
            edit: 'Edit'
          },
          components: {
            detailsButton: {
              index: {
                title: 'Details'
              }
            },
            actionsMenu: {
              index: {
                resetMessage: 'Are you sure to reset verification?'
              }
            },
            backLink: {
              index: {
                deals: 'Deals',
                contracts: 'Contracts'
              }
            }
          },
          overview: {
            personalSection: {
              index: {
                name: 'Name',
                email: 'Email',
                phone: 'Phone Number',
                coBuyer: 'Co-Buyer',
                dateCreated: 'Date Created',
                lastActive: 'Last Active',
                airMilesRewardNumber: 'Air Miles Reward Number'
              },
            },
            verificationSection: {
              index: {
                buyingPower: 'Verified Buying Power',
                rentingPower: 'Verified Renting Power',
                homeValuation: 'Home Valuation',
                buttons: {
                  view: 'View Property'
                },
              },
              preApproval: {
                index: {
                  title: 'Pre-approval'
                }
              },
              viewReportButton: {
                index: {
                  title: 'View Report'
                }
              },
            },
            identityCard: {
              index: {
                title: 'Identification',
                expiryDate: 'Expiry Date',
                issuingCountry: 'Issuing Country',
                verifiedOn: 'Verified On',
                address: 'Address',
                documentType: 'Document Type'
              }
            },
            propertyCard: {
              index: {
                errors: {
                  notFound: 'There is no HonestDoor property that matches the customer\'s address.',
                }
              },
              honestDoorCard: {
                index: {
                  title: 'Estimated Market Value',
                  honestDoorPrice: 'HonestDoor Price',
                  buttons: {
                    view: 'View Property'
                  },
                  homeAddress: 'Home Address',
                }
              },
              attomCard: {
                index: {
                  title: 'Estimated Market Value',
                  attomPrice: 'Attom Price',
                  homeEquity: 'Home Equity',
                  homeAddress: 'Home Address',
                }
              },
            },
            demographicsCard: {
              index: {
                title: 'Demographics',
                dateOfBirth: 'Date of Birth',
                occupation: 'Occupation',
              }
            },
            preApprovalCard: {
              index: {
                title: 'Pre-approval',
                amount: 'Amount',
                verification: 'Verification',
                creditScore: 'Credit Score',
                buttons: {
                  request: 'Request re-submission'
                },
                messages: {
                  requested: 'Pre-approval request resent'
                }
              },
              changeStatusDropdown: {
                index: {

                },
                utils: {
                  verified: 'Verified',
                  notVerified: 'Not Verified'
                }
              }
            },
            buildersSection: {
              index: {
                title: '%{name} is also in'
              }
            },
            purchasePowerCard: {
              index: {
                title: 'Purchase Power',
                upTo: 'Up to',
                between: 'between',
                dateAssessed: 'Date Assessed',
                buttons: {
                  viewReport: 'View Report'
                }
              }
            },
            rentalPowerCard: {
              index: {
                title: 'Renting Power',
                dateAssessed: 'Date Assessed',
                buttons: {
                  viewReport: 'View Report'
                }
              }
            },
            salesSection: {
              index: {
                stage: 'Deal Stage',
                salesRep: 'Assigned to',
              },
            },
            financialReportModal: {
              index: {
                buttons: {
                  downloadPDF: 'Download PDF'
                }
              }
            }
          },
          details: {
            navTabs: {
              index: {
                journey: 'Journey',
                verification: 'Verification',
                deals: 'Deals',
                activity: 'Activity',
                messages: 'Messages',
                documents: 'Documents',
                interests: 'Interests'
              }
            },
            activity: {
              analyticsCard: {
                index: {
                  title: 'Analytics',
                  totalVisits: 'Total Visits',
                  averageVisitTime: 'Average Visit',
                  device: 'Device',
                  os: 'Operating System',
                  browser: 'Browser',
                }
              },
              locationCard: {
                index: {
                  title: 'Location',
                  ip: 'IP Address: %{ip}'
                }
              },
              timeline: {
                index: {
                  title: 'User Activity',
                  description: 'View your users Timeline through Ownly.<br/>Time stamp is based on your local time.'
                },
              }
            },
            verification: {
              utils: {
                idVerified: 'ID Verified',
                financesVerified: 'Finances Verified',
                coBuyerAdded: 'Co-Buyer Added',
                additionalInfo: 'Additional Info',
                homeValuation: 'Home Valuation',
                preApproval: 'Pre-approval',
              },
              idVerified: {
                placeholder: {
                  index: {
                    title: 'ID Verified'
                  }
                },
                infoCard: {
                  index: {
                    title: 'ID Verified',
                    verified: 'Verified: %{date}',
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    documentType: 'Document Type',
                    documentNumber: 'Document #',
                    dateOfBirth: 'Date of Birth',
                    expiryDate: 'Expiry Date',
                    address: 'Address',
                    country: 'Country',
                    buttons: {
                      viewDocuments: 'View Documents'
                    }
                  }
                }
              },
              financesVerified: {
                placeholder: {
                  index: {
                    title: 'Finances Verified'
                  }
                },
                infoCard: {
                  index: {
                    title: 'Finances Verified',
                    verified: 'Verified: %{date}',
                    employerTrend: 'Employer Trend',
                    employerName: 'Employer Name',
                    debitActivityTrend: 'Debit Activity Trend',
                    creditActivityTrend: 'Credit Activity Trend',
                    overallActivityTrend: 'Overall Activity Trend',
                    overallBalanceTrend: 'Balance Trend',
                    homeRental: {
                      customerFinancialAmount: 'Verified Renting Power',
                      partyFinancialAmount: 'Combined Verified Renting Power'
                    },
                    homeSales: {
                      customerFinancialAmount: 'Verified Buying Power',
                      partyFinancialAmount: 'Combined Verified Buying Power',
                    },
                    buttons: {
                      viewReport: 'View Report'
                    }
                  }
                }
              },
              coBuyerAdded: {
                placeholder: {
                  index: {
                    homeRental: {
                      title: 'Co-applicant Added',
                    },
                    homeSales: {
                      title: 'Co-buyer Added'
                    },
                  }
                },
                infoCard: {
                  index: {
                    homeRental: {
                      title: 'Co-applicant Added',
                      financialAmount: 'Verified Renting Power',
                      buttons: {
                        view: 'View Co-applicant'
                      }
                    },
                    homeSales: {
                      title: 'Co-buyer Added',
                      financialAmount: 'Verified Buying Power',
                      buttons: {
                        view: 'View Co-buyer'
                      }
                    },
                    added: 'Added: %{date}',
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    documentType: 'Document Type',
                    documentNumber: 'Document Number',
                    annualIncome: 'Annual Employer Income',
                    employerName: 'Employer Name',
                    preApproval: 'Pre-approval'
                  }
                }
              },
              additionalInfo: {
                homeValuation: {
                  placeholder: {
                    index: {
                      title: 'Home Valuation'
                    }
                  },
                  infoCard: {
                    index: {
                      title: 'Home Valuation',
                      homeValue: 'Home Value',
                      valuationDate: 'Valuation Date',
                      address: 'Address',
                      ownership: 'Ownership',
                      ownershipOptions: {
                        yes: 'Yes',
                        no: 'No'
                      },
                      approxMortgageBalance: 'Approx. Mortgage Balance',
                      approxHomeEquity: 'Approx. Home Equity',
                      buttons: {
                        viewValuation: 'View Valuation'
                      }
                    }
                  }
                },
                preApproval: {
                  placeholder: {
                    index: {
                      title: 'Pre-approval'
                    }
                  },
                  infoCard: {
                    index: {
                      title: 'Pre-approval Added',
                    },
                    downloadButton: {
                      index: {
                        title: 'Download Pre-approval'
                      }
                    }
                  }
                }
              }
            },
            deals: {
              index: {
                title: 'Deals'
              },
              listHeader: {
                index: {
                  model: 'Model',
                  dealPrice: 'Deal Price',
                  dealState: 'Deal State',
                  communityProject: 'Community/Project',
                  realtor: 'Realtor',
                  lastActive: 'Last Active'
                }
              }
            },
            messages: {
              index: {
                title: 'Messages',
                description: 'The following are messages you’ve received from this customer.',
                list: {
                  model: 'Model',
                  message: 'Message',
                  date: 'Date',
                }
              }
            },
            documents: {
              listHeader: {
                index: {
                  filename: 'File Name',
                  documentType: 'Document Type',
                  fileSize: 'File Size',
                  dateUploaded: 'Date Uploaded'
                }
              },
              utils: {
                preApproval: 'Pre-approval',
                documentBack: 'Back',
                documentFront: 'Front',
                documentPhoto: '%{documentSide} of %{documentName} (%{customerName}).%{fileType}',
                documentSelfie: 'Selfie Image (%{customerName}).%{fileType}',
                customerPhoto: 'Customer Photo',
                documentReport: 'ID Verification Report (%{customerName}).%{fileType}',
                idVerification: 'Id Verification'
              }
            },
            interests: {
              index: {
                title: 'Interests'
              },
              interestList: {
                listHeader: {
                  index: {
                    type: 'Type',
                    model: 'Model/Unit',
                    listedPrice: 'Listed Price',
                    community: 'Community/Project',
                    lastActive: 'Last Active'
                  }
                },
                listItem: {
                  index: {
                    types: {
                      model: 'Model',
                      unit: 'Unit',
                      quickPossession: 'Quick Possession',
                      lot: 'Lot'
                    }
                  }
                }
              }
            }
          },
          form: {
            index: {
              title: 'Personal Info',
              description: 'Update this customer personal information.',
              name: 'Name',
              email: 'Email Address',
              phoneNumber: 'Phone Number',
              errorMessages: {
                phone: 'Please enter a valid phone number',
              }
            }
          },
        },
        financialReport: {
          components: {
            reportHeader: {
              index: {
                reportDate: 'Report Date:',
                email: 'Email:',
                phone: 'Phone:'
              }
            },
            idCard: {
              index: {
                title: 'Identity Document Verification',
                verified: 'Verified',
                unverified: 'Unverified',
                verificationDate: 'Verification Date:',
                firstName: 'First Name:',
                lastName: 'Last Name:',
                dateOfBirth: 'DOB:',
                address: 'Address:',
                idType: 'ID Type:',
                idNumber: 'ID #:',
                idExpiryDate: 'ID Expiry Date:'
              }
            },
            buyingPowerCard: {
              index: {
                title: 'Buying Power',
                between: 'Between',
                withCoBuyer: 'With co-buyer',
                withoutCoBuyer: 'Without co-coyer: '
              }
            },
            rentingPowerCard: {
              index: {
                title: 'Renting Power',
                month: 'A month',
                withCoApplicant: 'With co-applicant',
                withoutCoApplicant: 'Without co-applicant: '
              }
            }
          },
          index: {
            homeSales: {
              title: 'Customer',
            },
            homeRental: {
              title: 'Applicant',
            },
          },
          financialReportFlinks: {
            index: {
              report: 'Report',
              sections: {
                identity: 'Identity',
                employerIncomeBreakdown: 'Employer Income Breakdown',
                nonEmployerIncomeBreakdown: 'Non-Employer Income Breakdown',
                accountAnalysis: 'Account Analysis',
                overallBalance: 'Overall Balance Analysis',
                closingBalance: 'Specific Balance Analysis (Average Closing Balance)',
                specifRiskAnalysis: 'Specific Risk Analysis',
                liabilitiesBreakdown: 'Liabilities Breakdown'
              }
            },
            components: {
              monthlyAmountsChart: {
                index: {
                  month: 'Month'
                }
              },
              monthlyAmountsTable: {
                index: {
                  amount: 'Amount',
                  count: 'Count'
                }
              }
            },
            averageMonthlyRecurring: {
              index: {
                title: 'Average Monthly Recurring',
                deposits: 'Deposits',
                payments: 'Payments',
              }
            },
            accountOutflowCard: {
              index: {
                title: 'Account Outflow (last 6 months)',
                microLoanPayments: 'Micro Loan',
                mortgagePayments: 'Mortgage',
                autoLoanPayments: 'Auto & Transport',
                studentLoanPayments: 'Student Loan',
                otherLoanPayments: 'Other Loans',
                telecomPayments: 'Telecom',
                utilityPayments: 'Utilities',
              }
            },
            employersSection: {
              index: {
                tabs: {
                  overallIncome: 'Overall Income'
                },
                primary: 'Primary',
                secondary: 'Secondary',
                titleY: 'Income Amount (Monthly)'
              },
              employers: {
                employerCard: {
                  index: {
                    primaryName: 'Primary Employer Name',
                    secondaryName: 'Secondary Employer Name',
                    income: 'Employer Income',
                    trend: 'Employer Trend',
                  }
                }
              },
              attributesTable: {
                index: {
                  attribute: 'Attribute',
                  value: 'Value',
                  averageMonthlyDeposit: 'Average Monthly Deposit',
                  frequency: 'Frequency',
                  last2Paydates: 'Last 2 Paydates',
                  estimatedAnnualNet: 'Estimated Annual Net',
                  primary: 'primary',
                  secondary: 'secondary'
                }
              }
            },
            nonEmployerIncomeCard: {
              index: {
                nonEmployerIncome: 'Non-Employer Income',
                governmentIncome: 'Government Income'
              }
            },
            overallGovernmentTable: {
              index: {
                tabs: {
                  overall: 'Overall Gov’t',
                },
                accountAttribute: 'Account Attribute',
                value: 'Value',
                averageDeposit: 'Average Deposit',
                frequency: 'Frequency',
                last2DepositDates: 'Last Two Deposit Dates',
                totalDetected: 'Total Detected'
              }
            },
            accountAnalysisCard: {
              index: {
                activeDaysInAccount: 'Active Days in Account',
                firstDetectedTransaction: 'First Detected Transaction',
                averageMonthlyFreeCashFlow: 'Avg. Mthly Free Cash-Flow',
                averageMonthlyRecurringPayments: 'Avg. Mthly Recurring Payments',
                averageMonthlyRecurringDeposits: 'Avg. Mthly Deposits',
                activeDaysTrend: 'Trend of Active Days',
                debitActivityTrend: 'Debit Activity Trend',
                creditActivityTrend: 'Credit Activity Trend',
                overallActivityTrend: 'Overall Activity Trend'
              }
            },
            overallBalanceCard: {
              index: {
                trend: 'Balance Trend',
                max: 'Maximum Balance',
                min: 'Minimum Balance Detected',
                current: 'Current Balance',
                balance90DaysAgo: 'Balance as of 90 Days Ago',
                daysWithNegativeBalance: 'Days with a Negative Balance'
              }
            },
            closingBalanceCard: {
              index: {
                dayOfIncome: 'Day of Any Income',
                dayOfEmployerIncome: 'Day of Employer Income',
                dayOfGovernmentIncome: 'Day of Gov. Income',
                dayAfterIncome: 'Day After Any Income',
                dayAfterEmployerIncome: 'Day After Employer Income',
                dayAfterGovernmentIncome: 'Day After Gov. Income',
              }
            },
            nsfFeeTable: {
              index: {
                tabs: {
                  nsfFees: 'NSF Fees',
                },
                attributes: 'Attributes',
                value: 'Value',
                nfsTotal: 'Total’s NSF’s',
                nfsOf60Days: 'NSF’s (60 Days)',
                nfsOf6Months: 'NSF’s (6 months)',
                nfsMonthlyAverage: 'Average Monthly NSF’s',
                nfsDaysAfterEmployerIncome: 'NSF’s Day After Employer Income',
                nfsDaysAfterGovernmentIncome: 'NSF’s Day After Government Income',
              }
            },
            liabilitiesBreakdown: {
              index: {
                tabs: {
                  loanPayments: 'Loan Payments'
                },
                micro_loan: 'Micro Loan',
                mortgage: 'Mortgage',
                auto_loan: 'Automotive',
                student_loan: 'Student',
                other_loan: 'Other Loans',
                titleY: 'Total Loan Amount (Monthly)'
              },
              monthlyLoanPaymentsTable: {
                index: {
                  loanType: 'Loan Type',
                  averageMonthlyAmount: 'Avg. Monthly Amount',
                  averageMonthlyLoanPaymentsAmount: 'All Loans',
                  averageMonthlyMicroLoanPaymentsAmount: 'MicroLoans',
                  averageMonthlyMortgagePaymentsAmount: 'Mortgages',
                  averageMonthlyAutomotiveLoanPaymentsAmount: 'Automotive',
                  averageMonthlyStudentLoanPaymentsAmount: 'Student',
                  averageMonthlyOtherLoanPaymentsAmount: 'Other Loans',
                }
              }
            }
          },
          financialReportPlaid: {
            index: {
              report: 'Report',
              sections: {
                identity: 'Identity',
                incomes: 'Incomes',
                loanPayments: 'Loan Payments'
              }
            },
            incomesSection: {
              index: {
                wages: 'Wages',
                dividends: 'Dividends',
                interestEarned: 'Interest Earned',
                retirementPension: 'Retirement Pension',
                otherIncome: 'Other Income',
              }
            },
            loanPaymentsSection: {
              index: {
                carPayment: 'Car Payment',
                personalLoanPayment: 'Personal Loan Payment',
                studentLoanPayment: 'Student Loan Payment',
                creditCardPayment: 'Credit Card Payment',
                otherPayment: 'Other Payment'
              }
            }
          }
        }
      },
      insights: {
        index: {
          title: 'Insights'
        },
        navTabs: {
          index: {
            verifications: 'Verifications',
            marketing: 'Marketing'
          }
        },
        verifications: {
          verifiedAgeDemographicCard: {
            index: {
              title: 'Verified Age Demographic'
            }
          },
          verifiedFunnelCard: {
            index: {
              title: 'Verified Funnel',
              labels: {
                invited: 'Invited',
                idVerified: 'ID Verified',
                homeVerified: 'Home Valuation',
                buyingPower: 'Buying Power'
              }
            }
          },
          verifiedBuyingPowerCard: {
            index: {
              title: 'Verified Buying Power',
              description: 'View your customers affordability',
              percentageOfCustomers: 'Percentage of Customers',
              amount: 'Amount'
            }
          },
          verifiedLeadsLocationCard: {
            index: {
              title: 'Verified Leads Location'
            }
          }
        },
        marketing: {
          utmCampaignSection: {
            index: {
              trafficByCampaign: 'Traffic by Campaign',
              leadsByCampaign: 'Leads by Campaign'
            }
          },
          utmSourceSection: {
            index: {
              trafficBySource: 'Traffic by Source',
              leadsBySource: 'Leads by Source'
            }
          },
          utmMediumSection: {
            index: {
              trafficByMedium: 'Traffic by Medium',
              leadsByMedium: 'Leads by Medium'
            }
          },
          utmContentSection: {
            index: {
              trafficByContent: 'Traffic by Content',
              leadsByContent: 'Leads by Content'
            }
          }
        }
      },
      documents: {
        index: {
          title: 'Documents',
          buttons: {
            add: 'Add Files'
          }
        },
        list: {
          index: {
            name: 'Name',
            size: 'Size',
            modifiedDate: 'Modified Date'
          }
        },
        uploader: {
          index: {
            title: 'Uploading files'
          }
        }
      },
      taxRates: {
        index: {
          title: 'Tax Rates',
          buttons: {
            add: 'Tax Rate'
          },
        },
        list: {
          index: {
            name: 'Name',
            rate: 'Rate',
            citiesCommunitiesProjects: 'Cities/Communities/Projects',
            type: 'Type',
            createdBy: 'Created By',
            lastModifiedBy: 'Last Modified By'
          }
        },
        show: {
          index: {
            taxRates: 'Tax Rates',
            tabs: {
              overview: 'Overview',
            },
            edit: 'Edit'
          },
          overview: {
            index: {
              name: 'Name',
              rate: 'Rate',
              type: 'Type',
              cities: 'Cities',
              communitiesProjects: 'Communities/Projects',
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By'
            }
          }
        },
        form: {
          index: {
            newTitle: 'New Tax Rate',
            editTitle: 'Edit Tax Rate',
            name: 'Name',
            rate: 'Rate',
            communitiesProjects: 'Communities/Projects',
            cities: 'Cities',
            emptyCommunities: 'There\'s no communities',
            emptyCities: 'There\'s no cities',
            rateMessage: 'Tax rates are a decimal amount to aid in calculations, (i.e. if the tax rate is 5% then enter 0.05)',
            communityProjectBased: 'Community/Project based',
            cityBased: 'City based',
            buttons: {
              save: 'Save',
              cancel: 'Cancel'
            }
          }
        }
      },
      projects: {
        list: {
          index: {
            title: 'Projects',
            buttons: {
              add: 'Project'
            }
          },
          listView: {
            index: {
              name: 'Name',
              city: 'City',
              state: 'Province/State',
              status: 'Status',
              buildingType: 'Building Type',
              type: 'Type',
              availability: 'Availability',
              types: {
                rental: 'Rental',
                for_sale: 'For Sale'
              },
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By'
            }
          }
        },
        form: {
          index: {
            newTitle: 'New Project',
            editTitle: 'Edit Project',
            name: 'Name',
            cityId: 'City',
            subRegion: 'Sub-Region',
            address: 'Address',
            province: 'Province / State',
            buildingType: 'Building Type',
            image: 'Image',
            availability: 'Availability',
            status: 'Status',
            type: 'Type',
            enableMakeOffer: 'Enable Make a Deal',
            types: {
              rental: 'Rental',
              forSale: 'For Sale'
            },
            description: 'Description',
            longDescription: 'Long Description',
            flowForBuildAndPrice: 'Flow for Build and Price',
            startFromModelSelection: 'Start from Model Selection',
            startFromUnitSelection: 'Start from Unit Selection',
            hubSpotValue: 'HubSpot Value'
          }
        },
        show: {
          index: {
            tabs: {
              overview: 'Overview',
              floors: 'Floors',
              palettes: 'Palettes',
              packages: 'Packages',
              salesRepresentatives: 'Sales Representatives / Teams',
              modifications: 'Modifications',
              mortgageProviders: 'Mortgage Providers',
              reservationDocument: 'Reservation Document'
            }
          },
          overview: {
            index: {
              projectId: 'Project ID',
              city: 'City',
              address: 'Address',
              subRegion: 'Sub-Region',
              province: 'Province / State',
              buildingType: 'Building Type',
              image: 'Image',
              availability: 'Availability',
              status: 'Status',
              type: 'Type',
              types: {
                rental: 'Rental',
                for_sale: 'For Sale'
              },
              description: 'Description',
              longDescription: 'Long Description',
              createdBy: 'Created by',
              lastModifiedBy: 'Last Modified By',
              externalId: 'External ID',
              flowForBuildAndPrice: 'Flow for Build and Price'
            },
          },
          buildings: {
            index: {
              buttons: {
                add: 'Add Building'
              }
            },
            formModal: {
              index: {
                newTitle: 'New Building',
                editTitle: 'Edit Building',
                name: 'Name',
                description: 'Description'
              }
            },
            listItem: {
              index: {
                description: 'Description',
                images: 'Images',
                menu: {
                  images: 'Images'
                }
              }
            }
          },
          palettes: {
            addDropdown: {
              index: {
                add: 'Add Palette'
              }
            }
          },
          packages: {
            addDropdown: {
              index: {
                add: 'Add Package'
              }
            }
          }
        }
      },
      projectBuildings: {
        list: {
          index: {
            title: 'Buildings',
            buttons: {
              add: 'Building'
            }
          },
          listView: {
            index: {
              name: 'Name',
              project: 'Project',
              availability: 'Availability',
              status: 'Status',
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By'
            }
          }
        },
        form: {
          index: {
            newTitle: 'New Building',
            editTitle: 'Edit Building',
            projectId: 'Project',
            name: 'Name',
            description: 'Description',
            availability: 'Availability',
            status: 'Status'
          }
        },
        show: {
          index: {
            tabs: {
              overview: 'Overview',
              models: 'Models',
              floors: 'Floors',
              images: 'Images',
              modifications: 'Modifications'
            }
          },
          overview: {
            index: {
              projectBuildingId: 'Building ID',
              project: 'Project',
              description: 'Description',
              availability: 'Availability',
              status: 'Status',
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By'
            }
          },
          models: {
            index: {
              buttons: {
                add: 'Add Model'
              }
            }
          },
          buildingFloors: {
            index: {
              buttons: {
                add: 'Add Floor'
              }
            },
            buildingFloorFormModal: {
              index: {
                newTitle: 'New Floor',
                editTitle: 'Edit Floor',
                name: 'Name',
                description: 'Description',
                image: 'Image'
              }
            },
            unitsMultiSelect: {
              index: {
                selectUnits: 'Select Units',
                empty: 'No Units Available'
              }
            },
            buildingFloor: {
              actionsMenu: {
                index: {
                  moveUp: 'Move Up',
                  moveDown: 'Move Down'
                }
              },
            }
          },
        }
      },
      units: {
        list: {
          index: {
            title: 'Inventory',
            searchBarPlaceholder: 'Search by name or ID',
            buttons: {
              add: 'Unit'
            }
          },
          components: {
            filtersModal: {
              index: {
                title: 'Filters',
                description: 'Apply filters'
              },
              projectFilter: {
                index: {
                  title: 'Project'
                }
              },
              buildingModelFilter: {
                index: {
                  title: 'Model'
                }
              },
              bedroomFilter: {
                index: {
                  title: 'Bedrooms',
                  bedrooms: '%{count} Bedrooms'
                }
              },
              bathroomFilter: {
                index: {
                  title: 'Bathrooms',
                  bathrooms: '%{count} Bathrooms'
                }
              },
              availabilityFilter: {
                index: {
                  title: 'Availability'
                }
              }
            }
          }
        },
        form: {
          index: {
            newTitle: 'New Unit',
            editTitle: 'Edit Unit',
            buildingModelId: 'Model',
            price: 'Price',
            name: 'Name',
            floor: 'Floor',
            availability: 'Availability',
            floorLocation: 'Floor Location',
            view: 'View',
            image: 'Image',
            tourUrl: '3D Tour Url',
            openTourUrlInOverlayWindow: 'Open this URL in overlay window (please check X-Frame-Options of this URL)',
          }
        },
        show: {
          index: {
            tabs: {
              details: 'Details',
              options: 'Options',
              images: 'Images',
              observers: 'Observers',
              modifications: 'Modifications'
            }
          },
          details: {
            index: {
              unitId: 'Unit ID',
              floor: 'Floor',
              price: 'Price',
              availability: 'Availability',
              floorLocation: 'Floor Location',
              view: 'View',
              buildingModel: 'Model',
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By'
            }
          },
          options: {
            index: {
              buttons: {
                add: 'Add Option'
              }
            },
            optionFormModal: {
              index: {
                title: 'Option Form',
                price: 'Price'
              }
            }
          },
          observers: {
            components: {
              observersList: {
                index: {
                  list: {
                    name: 'Name',
                    phone: 'Phone',
                    buyingPower: 'Buying Power',
                    rentingPower: 'Renting Power',
                    browsingPrice: 'Browsing Price',
                    savedDate: 'Saved Date',
                    startedDate: 'Started Date',
                    lastActive: 'Last Active'
                  }
                }
              }
            },
            savedHomes: {
              index: {
                title: 'Saved Homes'
              }
            },
            startedReservationFlow: {
              index: {
                title: 'Started Reservation Flow'
              }
            }
          }
        }
      },
      optionTypes: {
        list: {
          index: {
            heading: 'Option Types',
            title: 'Option Types',
            buttons: {
              add: 'Add Option Type'
            }
          }
        },
        form: {
          index: {
            name: 'Name',
            newTitle: 'New Option Type',
            editTitle: 'Edit Option Type',
            image: 'Image',
            description: 'Description'
          }
        },
        show: {
          index: {
            name: 'Name',
            description: 'Description',
            tabs: {
              overview: 'Overview',
              modifications: 'Modifications'
            }
          },
          overview: {
            index: {
              name: 'Name',
              description: 'Description',
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By'
            }
          }
        }
      },
      deposits: {
        list: {
          index: {
            title: 'Deposits',
            searchBar: {
              placeholder: 'Search'
            }
          },
          listView: {
            listHeader: {
              index: {
                date: 'Date',
                customer: 'Customer',
                communityProject: 'Community/Project',
                property: 'Property',
                amount: 'Amount',
                paymentMethod: 'Payment Method',
                status: 'Status'
              }
            },
            listItem: {
              status: {
                index: {
                  partialRefund: 'Partial Refund',
                  refunded: 'Refunded',
                  pending: 'Pending',
                  success: 'Success',
                  failed: 'Failed',
                  rejected: 'Rejected'
                }
              }
            }
          }
        }
      },
      externalDataSources: {
        index: {
          title: 'External Data Sources',
          buttons: {
            salesSimplicity: 'Sales Simplicity',
            marondaHomes: 'Maronda Homes'
          }
        },
        show: {
          index: {
            integrations: 'Integrations',
            externalDataSources: 'External Data Sources',
            config: 'Config',
            dataProviders: {
              salesSimplicity: 'Sales Simplicity',
              marondaHomes: 'Maronda Homes',
            },
            buttons: {
              add: 'Add Config',
              edit: 'Edit Config',
              startSync: 'Start Sync',
              syncing: 'Syncing',
              getBuiltOptionsReport: 'Get Built Options Report',
              startPriceRulesSync: 'Start Price Rules Sync',
              syncingPriceRules: 'Syncing Price Rules'
            }
          }
        },
        form: {
          index: {
            integrations: 'Integrations',
            externalDataSources: 'External Data Sources',
            dataProviders: {
              salesSimplicity: 'Sales Simplicity Config',
              marondaHomes: 'Maronda Homes Config'
            }
          }
        },
        salesSimplicity: {
          details: {
            index: {
              username: 'Username',
              password: 'Password',
              token: 'Token',
            }
          },
        },
        marondaHomes: {
          details: {
            index: {
              apiKey: 'API Key',
              proxy: 'Proxy',
              proxyHost: 'Server',
              proxyPort: 'Port',
              proxyUsername: 'Username',
              proxyPassword: 'Password'
            }
          }
        },
        syncLogs: {
          index: {
            createdProductTypes: 'Created Product Types',
            updatedProductTypes: 'Updated Product Types',
            createdModels: 'Created Models:',
            updatedModels: 'Updated Models:',
            createdCommunities: 'Created Communities:',
            updatedCommunities: 'Updated Communities:',
            createdCommunityModels: 'Created Community Models:',
            updatedCommunityModels: 'Updated Community Models:',
            createdCommunityProductTypes: 'Created Community Product Types:',
            updatedCommunityProductTypes: 'Updated Community Product Types:',
            createdElevations: 'Created Elevations',
            updatedElevations: 'Updated Elevations',
            createdCommunityElevations: 'Created Community Elevations',
            updatedCommunityElevations: 'Updated Community Elevations',
            createdFloorOptions: 'Created Floor Options',
            updatedFloorOptions: 'Updated Floor Options',
            updatedPriceRuleIds: 'Updated Price Rule Ids',
            lastReport: 'Last Report (%{lastSyncDate})',
            errors: 'Errors:',
          }
        },
        builtOptionsReport: {
          index: {
            title: 'Built Options Report',
            titles: {
              firstRange: '5 - 7 Times',
              secondRange: '8 - 10 Times',
              thirdRange: '11 - 20 Times',
              fourthRange: '20 - 50 Times',
              fifthRange: '> 50 Times',
            },
          },
          reportChart: {
            index: {
              labels: {
                timesRepeated: 'Times Repeated'
              }
            }
          }
        }
      },
      buildingModels: {
        list: {
          index: {
            title: 'Models',
            buttons: {
              add: 'Add Model'
            }
          },
          buildingFilter: {
            index: {
              all: 'All',
              building: 'Building'
            }
          },
          gridView: {
            listItem: {
              index: {
                bath: '%{baths} Bath',
                bed: '%{beds} Bed',
                sizeSqft: '%{size} SqFt',
              }
            }
          },
          listView: {
            index: {
              name: 'Name',
              size: 'Size',
              bedrooms: 'Bedrooms',
              bathrooms: 'Bathrooms',
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By'
            }
          }
        },
        show: {
          navTabs: {
            index: {
              details: 'Details',
              floors: 'Floors',
              images: 'Images',
              packages: 'Packages',
              palettes: 'Palettes',
              modifications: 'Modifications'
            }
          },
          details: {
            index: {
              modelId: 'Model ID',
              project: 'Project',
              size: 'Size (sq.ft.)',
              longDescription: 'Long Description',
              createdBy: 'Created By',
              lastModifiedBy: 'Last Modified By'
            }
          },
          images: {
            index: {
              useImageGallery: 'Use image gallery instead of model image'
            },
            listItem: {
              index: {
                isDefault: 'Default',
                buttons: {
                  markAsDefault: 'Mark as default'
                }
              }
            }
          },
          packages: {
            listItem: {
              index: {
                default: 'Default',
                delete: 'Delete',
                setDefault: 'Set as Default'
              }
            },
            buildingModelPackageFormModal: {
              index: {
                title: 'Package Settings: %{packageName}',
                price: 'Price',
                isDefault: 'Default'
              }
            }
          },
          palettes: {
            listItem: {
              index: {
                default: 'Default',
              }
            },
            buildingModelPaletteFormModal: {
              index: {
                title: 'Palette Settings: %{paletteName}',
                price: 'Price',
                isDefault: 'Default'
              }
            }
          },
          directLinkModal: {
            index: {
              title: 'Share Link',
              previewTitle: 'Preview Link',
              selectBuilding: 'Select Building',
              noBuildings: 'No Buildings Available',
              messages: {
                copied: 'Link has been copied to clipboard.'
              },
              buttons: {
                copy: 'Copy Link'
              }
            }
          },
        },
        form: {
          index: {
            newTitle: 'New Model',
            editTitle: 'Edit Model',
            projectId: 'Project',
            name: 'Name',
            longDescription: 'Long Description',
            image: 'Model Image',
            size: 'Size',
            bedrooms: 'Bedrooms',
            bathrooms: 'Bathrooms'
          }
        },
      },
      tours: {
        list: {
          index: {
            title: 'Tours',
            user: 'User',
            date: 'Date',
            type: 'Type',
            company: 'Company',
            types: {
              scheduled: 'Scheduled',
              rescheduled: 'Rescheduled',
              missed: 'Missed',
              completed: 'Completed',
              deleted: 'Deleted',
              cancelled: 'Cancelled'
            }
          }
        }
      },
      quickPossessions: {
        components: {
          quickPossessionFormModal: {
            form: {
              index: {
                titleNew: 'Create a New Home',
                titleEdit: 'Update Home',
                descriptionNew: 'By filling in the details below you will create a published home.',
              }
            },
            confirm: {
              index: {
                title: 'Confirm the creation of this model',
                description: 'You\'re about to add a new home model to your online inventory. This action will make the model visible to potential buyers.',
                buttons: {
                  save: 'Yes, Add Model'
                }
              },
              homeInfo: {
                index: {
                  description: 'Description:'
                }
              }
            }
          },
        },
        list: {
          index: {
            title: 'Quick Possessions',
            searchBarPlaceholder: 'Search for Quick Possessions',
            buttons: {
              add: 'Quick Possession'
            },
            tabs: {
              activeListings: 'Active Listings',
              inactiveListings: 'Inactive Listings'
            }
          },
          listItem: {
            index: {
              community: 'Community',
              model: 'Model',
              totalPrice: 'Total Price',
              status: 'Status'
            }
          },
          components: {
            quickPossessionFiltersModal: {
              index: {
                title: 'Filters',
                description: 'Apply filters'
              },
              communityFilter: {
                index: {
                  title: 'Community'
                }
              },
              modelFilter: {
                index: {
                  title: 'Model'
                }
              },
              productTypeFilter: {
                index: {
                  title: 'Product Type'
                }
              },
              bedsFilter: {
                index: {
                  title: 'Beds'
                },
                lisItem: {
                  index: {
                    beds: '%{count} Beds'
                  }
                }
              },
              bathsFilter: {
                index: {
                  title: 'Baths'
                },
                lisItem: {
                  index: {
                    baths: '%{count} Baths'
                  }
                }
              }
            }
          }
        },
        show: {
          index: {
            title: 'Quick Possessions',
            name: '%{modelName} in %{communityName}',
            tabs: {
              overview: 'Overview',
              images: 'Images',
              requests: 'Requests',
              modifications: 'Modifications'
            }
          },
          overview: {
            index: {
              listingId: 'Listing ID',
              community: 'Community',
              model: 'Model',
              productType: 'Product Type',
              elevation: 'Elevation',
              package: 'Package',
              palette: 'Palette',
              floorPlan: 'Floorplan',
              status: 'Status',
              description: 'Description',
              address: 'Address',
              squareFootage: 'Square Footage',
              beds: 'Beds',
              baths: 'Baths',
              price: 'Price',
              url: 'Url',
              lotId: 'Lot ID',
              externalId: 'External ID',
            }
          },
          requests: {
            index: {
              name: 'Name',
              email: 'Email',
              phone: 'Phone',
              requestDate: 'Request Date'
            }
          },
        }
      },
      myProfile: {
        show: {
          index: {
            title: 'My Profile',
            tabs: {
              basicInfo: 'Basic Info',
              security: 'Security'
            }
          },
          basicInfo: {
            index: {
              name: 'Name',
              email: 'Email',
              phone: 'Phone',
              role: 'Role',
              company: 'Company'
            }
          },
          security: {
            index: {
              currentPassword: 'Current Password',
              newPassword: 'New Password',
              confirmPassword: 'Confirm Password',
              messages: {
                success: 'Your password has been changed'
              }
            }
          }
        },
        form: {
          index: {
            title: 'Edit My Profile',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            phone: 'Phone',
            role: 'Role',
            company: 'Company',
            password: 'Password',
            changePassword: 'Change Password',
            messages: {
              successfullyUpdated: 'Your info has been successfully updated!',
            },
          }
        }
      },
      messages: {
        list: {
          index: {
            title: 'Messages',
            name: 'Name',
            phoneNumber: 'Phone number',
            email: 'Email',
            building: 'Building',
            message: 'Message',
            date: 'Date'
          }
        }
      },
      masterAdminDashboard: {
        index: {
          title: 'Ownly Dashboard'
        },
        visitorLeadsTable: {
          index: {
            title: 'Visitors & Leads (By Builder)',
            builder: 'Builder',
            visitors: 'Visitors',
            capturedLeads: 'Total Leads',
            idVerified: 'ID Verified',
            preApproval: 'Pre-approval',
            fullyVerified: 'Fully Verified'
          }
        },
        visitorLeadsChart: {
          index: {
            title: 'Visitors & Leads (All Builders)',
            visitors: 'Visitors',
            visitorsCount: '%{count} Visitors',
            capturedLeads: 'Leads',
            capturedLeadsCount: '%{count} Leads',
          }
        }
      },
      sales: {
        components: {
          dealList: {
            searchBar: {
              index: {
                title: 'Search'
              }
            },
            assignedToFilter: {
              index: {
                select: 'All sales people',
              }
            },
            filtersModal: {
              index: {
                title: 'Filters',
                description: 'Apply filters'
              },
              completedDealStatusFilter: {
                index: {
                  title: 'Completed Deal Status'
                }
              },
              communityProjectFilter: {
                index: {
                  title: 'Community/Project'
                }
              },
              dealPriceFilter: {
                index: {
                  title: 'Deal Price'
                }
              },
              realtorFilter: {
                index: {
                  title: 'Realtor'
                }
              },
            },
            list: {
              listHeader: {
                index: {
                  customer: 'Customer',
                  dealPrice: 'Deal Price',
                  dealState: 'Deal State',
                  modelUnit: 'Model/Unit',
                  communityProject: 'Community/Project',
                  homeSales: {
                    financialAmount: 'Buying Power'
                  },
                  homeRental: {
                    financialAmount: 'Renting Power'
                  },
                  realtor: 'Realtor',
                  lastActive: 'Last Active',
                }
              },
              listItem: {
                index: {
                  preApproval: 'Pre Approval'
                }
              }
            }
          }
        },
        contracts: {
          list: {
            index: {
              title: 'Contracts'
            }
          }
        },
        deals: {
          index: {
            title: 'Deals'
          },
          filters: {
            index: {
              searchBar: {
                title: 'Search'
              },
            }
          },
          list: {
            index: {
              title: 'Deals'
            },
            listView: {
              index: {
                customer: 'Customer',
                dealPrice: 'Deal Price',
                dealState: 'Deal State',
                modelUnit: 'Model/Unit',
                communityProject: 'Community/Project',
                homeSales: {
                  financialAmount: 'Buying Power'
                },
                homeRental: {
                  financialAmount: 'Renting Power'
                },
                realtor: 'Realtor',
                lastActive: 'Last Active',
              },
              listItem: {
                index: {
                  preApproval: 'Pre Approval'
                }
              }
            }
          }
        },
      },
      propertyTaxRates: {
        list: {
          index: {
            title: 'Property Tax Rates',
            search: 'Country, State or City',
            country: 'Country',
            state: 'State',
            city: 'City',
            taxRate: 'Tax Rate',
            isDefault: 'Default',
            buttons: {
              add: 'Property Tax Rate'
            }
          }
        },
        form: {
          index: {
            newTitle: 'New Property Tax Rate',
            editTitle: 'Edit Property Tax Rate',
            country: 'Country',
            state: 'State',
            city: 'City',
            isDefault: 'Default City',
            taxRate: 'Tax Rate'
          }
        }
      },
      mortgageProviders: {
        components: {
          formModal: {
            index: {
              newTitle: 'Create a Mortgage Provider',
              editTitle: 'Edit Mortgage Provider',
              form: {
                name: 'Mortgage Provider Name',
                url: 'Mortgage Provider Link',
                image: 'Image'
              },
              placeholders: {
                name: 'Mortgage Provider Name',
                url: 'https://'
              }
            }
          },
        },
        list: {
          index: {
            title: 'Mortgage Providers',
            buttons: {
              add: 'Add Mortgage Provider'
            }
          }
        }
      }
    }
  },
  layout: {
    admin: {
      topBar: {
        viewSite: 'View Site',
        previewNewUi: 'Preview New UI',
        inviteButton: {
          index: {
            title: 'Invite'
          }
        }
      },
      userMenuDropdown: {
        index: {
          logout: 'Log Out',
          myProfile: 'My Profile',
        }
      },
      familyScenarioSelect: {
        singleFamily: 'Single Family Homes',
        multiFamily: 'Multi Family Homes'
      },
      sidebar: {
        index: {
          menus: {
            overview: 'Overview',
            settings: {
              title: 'Settings',
              subMenus: {
                companies: 'Companies',
                myCompany: 'Company',
                users: 'Users',
                teams: 'Teams',
                mortgageRate: 'Mortgage',
                taxRates: 'Tax Rates',
                cities: 'Cities',
                propertyTaxRates: 'Property Tax Rates'
              }
            },
            dashboard: 'Ownly Dashboard',
            customers: 'Customers',
            applicants: 'Applicants',
            insights: 'Insights',
            integrations: 'Integrations',
            documents: 'Documents',
            messages: 'Messages',
            homes: 'Homes'
          }
        },
        singleFamilyMenu: {
          index: {
            title: 'Single Family'
          }
        },
        multiFamilyMenu: {
          index: {
            title: 'Multi-Family'
          }
        },
        singleFamilySideBar: {
          menus: {
            communities: 'Communities',
            productTypes: 'Product Types',
            models: 'Models',
            palettes: 'Palettes',
            packages: 'Packages',
            inventory: 'Inventory'
          }
        },
        multiFamilySideBar: {
          menus: {
            projects: 'Projects',
            buildings: 'Buildings',
            buildingModels: 'Models',
            options: 'Options',
            optionTypes: 'Option Types',
            palettes: 'Palettes',
            packages: 'Packages',
            units: 'Inventory'
          }
        },
        salesMenu: {
          index: {
            title: 'Sales',
            menus: {
              deals: 'Deals',
              contracts: 'Contracts',
              deposits: 'Deposits'
            }
          }
        }
      }
    }
  },
  utils: {
    userAgentUtils: {
      pc: 'PC'
    },
    customerUtils: {
      noBuilds: 'No Builds',
      builds: '%{buildsCount} builds',
      notVerified: 'Not Verified'
    },
    dateUtils: {
      hours: '%{hours}h',
      minutes: '%{minutes}m',
      seconds: '%{seconds}s',
    },
    auditUtils: {
      ownlySupport: 'Ownly Support'
    },
    companyUtils: {
      buyerReserveSteps: {
        offer: 'Deal',
        sign: 'Sign',
        deposit: 'Deposit',
        realtorQuestion: 'Realtor Question',
        multipleReservations: 'Multiple Reservations'
      },
      rentalReserveSteps: {
        offer: 'Deal',
        sign: 'Sign',
        deposit: 'Pay',
        multipleReservations: 'Multiple Reservations'
      }
    },
    homeUtils: {
      lotName: 'Lot %{name}'
    },
    reserveNowUtils: {
      offerJourneyLabels: {
        pending: 'Deal',
        submitted: 'Deal Submitted',
        accepted: 'Deal Accepted',
        countered: 'Deal Countered',
        recountered: 'Recounter Pending',
        rejected: 'Deal Rejected',
        declined: 'Deal Declined',
      },
      depositJourneyLabels: {
        pending: 'Deposit',
        submitted: 'Deposit Submitted',
        accepted: 'Deposit Confirmed',
        rejected: 'Deposit Problem',
        partialRefund: 'Deposit Partially Refunded',
        refunded: 'Deposit Refunded'
      },
      signJourneyLabels: {
        pending: 'Sign',
        submitted: 'Document Signed',
        accepted: 'Document Confirmed',
        rejected: 'Signature Problem',
      }
    }
  },
  labels: {
    required: 'Required'
  }
};
