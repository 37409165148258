import React from 'react';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import i18n from 'i18n-js';

import styles from '../FinancialReport.module.scss';
import useHomeRental from '../../../../../hooks/useHomeRental';
import IncomesSection from './IncomesSection';
import LoanPaymentsSection from './LoanPaymentsSection';
import IdCard from '../components/IdCard';
import RentingPowerCard from '../components/RentingPowerCard';
import BuyingPowerCard from '../components/BuyingPowerCard';

const i18nOpts = { scope: 'components.admin.customers.financialReport.financialReportPlaid.index' };

const FinancialReportPlaid = () => {
  const { isRental } = useHomeRental();

  return (
    <>
      <h2 className={styles.subtitle}>{i18n.t('sections.identity', i18nOpts)}</h2>
      <Row>
        <Col xs="12" sm="12" md="8" lg="6">
          <IdCard />
        </Col>
      </Row>

      <Row className="mb-5">
        <Col xs="12" sm="6" md="4">
          {isRental ? (
            <RentingPowerCard />
          ) : (
            <BuyingPowerCard />
          )}
        </Col>
      </Row>

      <h1 className={classNames(styles.title)}>{i18n.t('report', i18nOpts)}</h1>
      <hr className={styles.headerSeparator} />

      <h3 className={styles.subtitle}>{i18n.t('sections.incomes', i18nOpts)}</h3>
      <IncomesSection />

      <h3 className={classNames(styles.subtitle, 'mt-4')}>{i18n.t('sections.loanPayments', i18nOpts)}</h3>
      <LoanPaymentsSection />
    </>
  );
};

FinancialReportPlaid.propTypes = {};

FinancialReportPlaid.defaultProps = {};

export default FinancialReportPlaid;
