import React from 'react';
import i18n from 'i18n-js';
import { Card } from 'reactstrap';
import { connect } from 'react-redux';

import StatCardValue from '../../components/StatCardValue';
import { formatCurrency } from '../../../../../../utils/currencyUtils';

const i18nOpts = { scope: 'components.admin.customers.financialReport.financialReportFlinks.nonEmployerIncomeCard.index' };

const NonEmployerIncomeCard = ({ financialReport }) => {
  const { nonEmployerIncome, governmentIncome } = financialReport;
  const { currentIncome } = governmentIncome;

  return (
    <Card body>
      <div className="d-flex align-items-center justify-content-around">
        <StatCardValue title={i18n.t('nonEmployerIncome', i18nOpts)}>
          {formatCurrency(nonEmployerIncome)}
        </StatCardValue>
        <StatCardValue title={i18n.t('governmentIncome', i18nOpts)} showSeparator>
          {formatCurrency(currentIncome)}
        </StatCardValue>
      </div>
    </Card>
  );
};

NonEmployerIncomeCard.propTypes = {};

NonEmployerIncomeCard.defaultProps = {};

export default connect((store) => ({
  financialReport: store.customers.financialReport,
}))(NonEmployerIncomeCard);
