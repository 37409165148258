import React from 'react';
import PropTypes from 'prop-types';

import { getUnitColumnName } from '../../../../../../../utils/enumUtils';
import { UnitColumns } from '../../../../../../../constants';

const CustomColumnName = ({ column }) => {
  const name = getUnitColumnName(column);

  return (
    <th>
      {name}
    </th>
  );
};

CustomColumnName.propTypes = {
  column: PropTypes.oneOf(Object.values(UnitColumns)).isRequired
};

CustomColumnName.defaultProps = {};

export default CustomColumnName;
