const legend = {
  display: true,
  position: 'right',
  align: 'start',
  labels: {
    font: {
      color: '#000000',
      weight: '800',
      size: 10
    },
    usePointStyle: true,
    pointStyleWidth: 10,
    boxHeight: 7
  }
};

const tooltip = {
  callbacks: {
    label(context) {
      const total = context.dataset.data.reduce((sum, val) => sum + val, 0);
      const value = context.raw;
      const percentage = ((value / total) * 100).toFixed(0);

      return ` ${context.label}: ${value} (${percentage}%)`;
    }
  }
};

export const chartOptions = {
  aspectRatio: 2.3,
  plugins: {
    legend,
    tooltip
  }
};

export const innerRingChartColors = [
  '#006D2C', '#238B45', '#41AE76', '#78C679',
  '#ADDD8E', '#D9F0A3', '#F7FCB9'
];
