import React, { useMemo } from 'react';
import { Progress } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from './CustomerReservationProgress.module.scss';
import ModalService from '../../../../services/ModalService';
import { CustomerProgressTypes, ModalNames, ReserveStepTypes } from '../../../../constants';
import useReserveNowWorkflowSteps from '../../../../hooks/useReserveNowWorkflowSteps';

const CustomerReservationProgress = ({ customer, wrapInTd }) => {
  const validReserveNowWorkflowSteps = [...Object.values(ReserveStepTypes)];
  const reserveNowWorkflowSteps = useReserveNowWorkflowSteps();

  const calculateProgress = () => {
    const { isOfferConfirmed, isSignConfirmed, isDepositConfirmed } = customer;

    const steps = reserveNowWorkflowSteps
      .map((step) => step.type)
      .filter((step) => validReserveNowWorkflowSteps.includes(step));

    let value = 0;

    if (steps.includes(ReserveStepTypes.OFFER) && isOfferConfirmed) {
      if (steps.length === 1) value += 100;
      else if (steps.length === 2) value += 50;
      else if (steps.length === 3) value += 20;
    }

    if (steps.includes(ReserveStepTypes.SIGN) && isSignConfirmed) {
      value += steps.length === 3 ? 40 : 50;
    }

    if (steps.includes(ReserveStepTypes.DEPOSIT) && isDepositConfirmed) {
      value += steps.length === 3 ? 40 : 50;
    }

    return value;
  };

  const onOpenPopover = () => {
    ModalService.open(ModalNames.CUSTOMER_PROGRESS, {
      customerId: customer.id,
      type: CustomerProgressTypes.RESERVATION
    });
  };

  const progress = useMemo(calculateProgress, [reserveNowWorkflowSteps, customer]);

  const WrapperComponent = wrapInTd ? 'td' : 'div';

  return (
    <WrapperComponent onClick={onOpenPopover} role="button" className="clickable-column">
      <div
        id={`customer-reservation-progress-${customer.id}`}
        aria-hidden
      >
        <Progress color="success" value={progress} className={styles.progress} barClassName={styles.progressBar} />
        <div className="col-gray-700 font-weight-500 mt-1">{`${progress}%`}</div>
      </div>
    </WrapperComponent>
  );
};

CustomerReservationProgress.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
  wrapInTd: PropTypes.bool
};

CustomerReservationProgress.defaultProps = {
  wrapInTd: false
};

export default CustomerReservationProgress;
