import React from 'react';
import { connect } from 'react-redux';
import {
  Nav, NavItem, NavLink, Table
} from 'reactstrap';
import i18n from 'i18n-js';

const i18nOpts = { scope: 'components.admin.customers.financialReport.financialReportFlinks.nsfFeeTable.index' };

const NSFFeeTable = ({
  financialReport
}) => {
  const { nsfFee } = financialReport;
  const {
    nfsTotal, nfsOf60Days, nfsOf6Months, nfsMonthlyAverage,
    nfsDaysAfterEmployerIncome, nfsDaysAfterGovernmentIncome
  } = nsfFee;

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink active className="font-16">
            {i18n.t('tabs.nsfFees', i18nOpts)}
          </NavLink>
        </NavItem>
      </Nav>

      <Table bordered responsive className="mt-4">
        <thead>
          <tr>
            <th>{i18n.t('attributes', i18nOpts)}</th>
            <th>{i18n.t('value', i18nOpts)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{i18n.t('nfsTotal', i18nOpts)}</td>
            <td>{nfsTotal}</td>
          </tr>
          <tr>
            <td>{i18n.t('nfsOf60Days', i18nOpts)}</td>
            <td>{nfsOf60Days}</td>
          </tr>
          <tr>
            <td>{i18n.t('nfsOf6Months', i18nOpts)}</td>
            <td>{nfsOf6Months}</td>
          </tr>
          <tr>
            <td>{i18n.t('nfsMonthlyAverage', i18nOpts)}</td>
            <td>{nfsMonthlyAverage}</td>
          </tr>
          <tr>
            <td>{i18n.t('nfsDaysAfterEmployerIncome', i18nOpts)}</td>
            <td>{nfsDaysAfterEmployerIncome}</td>
          </tr>
          <tr>
            <td>{i18n.t('nfsDaysAfterGovernmentIncome', i18nOpts)}</td>
            <td>{nfsDaysAfterGovernmentIncome}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

NSFFeeTable.propTypes = {};

NSFFeeTable.defaultProps = {};

export default connect((store) => ({
  financialReport: store.customers.financialReport,
}))(NSFFeeTable);
