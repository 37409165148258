import React from 'react';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import i18n from 'i18n-js';

import styles from '../FinancialReport.module.scss';
import useHomeRental from '../../../../../hooks/useHomeRental';
import IdCard from '../components/IdCard';
import RentingPowerCard from '../components/RentingPowerCard';
import BuyingPowerCard from '../components/BuyingPowerCard';
import AverageMonthlyRecurring from './AverageMonthlyRecurring';
import AccountOutflowCard from './AccountOutflowCard';
import EmployersSection from './EmployersSection';
import NonEmployerIncomeCard from './NonEmployerIncomeCard';
import OverallGovernmentTable from './OverallGovernmentTable';
import AccountAnalysisCard from './AccountAnalysisCard';
import OverallBalanceCard from './OverallBalanceCard';
import ClosingBalanceCard from './ClosingBalanceCard';
import NSFFeeTable from './NSFFeeTable';
import LiabilitiesSection from './LiabilitiesSection';

const i18nOpts = { scope: 'components.admin.customers.financialReport.financialReportFlinks.index' };

const FinancialReportFlinks = () => {
  const { isRental } = useHomeRental();

  return (
    <>
      <h2 className={styles.subtitle}>{i18n.t('sections.identity', i18nOpts)}</h2>
      <Row>
        <Col xs="12" sm="12" md="8" lg="6">
          <IdCard />
        </Col>
      </Row>

      <Row className="mb-5">
        <Col xs="12" sm="6" md="4">
          {isRental ? (
            <RentingPowerCard />
          ) : (
            <BuyingPowerCard />
          )}
        </Col>
        <Col xs="12" sm="6" md="4">
          <AverageMonthlyRecurring />
        </Col>
        <Col xs="12" sm="6" md="4">
          <AccountOutflowCard />
        </Col>
      </Row>

      <h1 className={classNames(styles.title)}>{i18n.t('report', i18nOpts)}</h1>
      <hr className={styles.headerSeparator} />
      <h2 className={styles.subtitle}>{i18n.t('sections.employerIncomeBreakdown', i18nOpts)}</h2>
      <EmployersSection />

      <h2 className={classNames(styles.subtitle, 'mt-4')}>{i18n.t('sections.nonEmployerIncomeBreakdown', i18nOpts)}</h2>
      <Row>
        <Col xs="12" sm="12" md="6">
          <NonEmployerIncomeCard />
        </Col>
      </Row>
      <OverallGovernmentTable />

      <h3 className={classNames(styles.subtitle, 'mt-4')}>{i18n.t('sections.accountAnalysis', i18nOpts)}</h3>
      <AccountAnalysisCard />

      <div className="page-break-always" />

      <Row className="mt-4">
        <Col xs="12" sm="12" md="6">
          <h3 className={styles.subtitle}>{i18n.t('sections.overallBalance', i18nOpts)}</h3>
          <OverallBalanceCard />
        </Col>
        <Col xs="12" sm="12" md="6">
          <h3 className={styles.subtitle}>{i18n.t('sections.closingBalance', i18nOpts)}</h3>
          <ClosingBalanceCard />
        </Col>
      </Row>

      <h3 className={classNames(styles.subtitle, 'mt-3')}>{i18n.t('sections.specifRiskAnalysis', i18nOpts)}</h3>
      <NSFFeeTable />

      <h3 className={classNames(styles.subtitle, 'mt-3')}>{i18n.t('sections.liabilitiesBreakdown', i18nOpts)}</h3>
      <LiabilitiesSection />
    </>
  );
};

FinancialReportFlinks.propTypes = {};

FinancialReportFlinks.defaultProps = {};

export default FinancialReportFlinks;
