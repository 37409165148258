import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  Card, Col, Row, Spinner
} from 'reactstrap';
import { Pie } from 'react-chartjs-2';
import i18n from 'i18n-js';

import {
  fetchUtmContentReportAsync,
  generateSampleDataAsync,
  getLeadsChartData,
  getVisitorsChartData
} from './utils';
import ReportEmpty from '../../../../common/ReportEmpty';
import { chartOptions } from '../utils';

const momentTz = require('moment-timezone');

const i18nOpts = { scope: 'components.admin.insights.marketing.utmContentSection.index' };

const UtmContentSection = ({ company, dateRange }) => {
  const [loading, setLoading] = useState(false);
  const [utmContentData, setUtmContentData] = useState([]);

  const loadData = () => {
    setLoading(true);

    const loadDataAsync = company?.enableSampleData ? generateSampleDataAsync : fetchDataAsync;
    loadDataAsync()
      .then((data) => {
        setUtmContentData(data);
      })
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  };

  const fetchDataAsync = () => {
    const timezone = momentTz.tz.guess();
    const variables = { companyId: company?.id || null, customDateRange: dateRange, timezone };
    return fetchUtmContentReportAsync(variables);
  };

  useEffect(() => {
    loadData();
  }, [company, dateRange]);

  const visitorsData = useMemo(() => getVisitorsChartData(utmContentData), [utmContentData]);
  const leadsData = useMemo(() => getLeadsChartData(utmContentData), [utmContentData]);

  const hasVisitorsData = visitorsData.datasets[0].data.some((v) => v > 0);
  const hasLeadsData = leadsData.datasets[0].data.some((v) => v > 0);

  return (
    <Row>
      <Col xs={12} md={6}>
        <Card body>
          <h4 className="mb-4">{i18n.t('trafficByContent', i18nOpts)}</h4>

          <div className="h-100 d-flex justify-content-center align-items-center">
            {loading ? (
              <div className="text-center my-5"><Spinner size="lg" /></div>
            ) : (
              <>
                {hasVisitorsData ? (
                  <Pie data={visitorsData} options={chartOptions} />
                ) : (
                  <ReportEmpty />
                )}
              </>
            )}
          </div>
        </Card>
      </Col>

      <Col xs={12} md={6}>
        <Card body>
          <h4 className="mb-4">{i18n.t('leadsByContent', i18nOpts)}</h4>

          <div className="h-100 d-flex justify-content-center align-items-center">
            {loading ? (
              <div className="text-center my-5"><Spinner size="lg" /></div>
            ) : (
              <>
                {hasLeadsData ? (
                  <Pie data={leadsData} options={chartOptions} />
                ) : (
                  <ReportEmpty />
                )}
              </>
            )}
          </div>
        </Card>
      </Col>
    </Row>
  );
};

UtmContentSection.propTypes = {
  company: PropTypes.objectOf(PropTypes.any),
  dateRange: PropTypes.objectOf(PropTypes.any).isRequired
};

UtmContentSection.defaultProps = {
  company: null
};

export default UtmContentSection;
