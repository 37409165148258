import React from 'react';
import { Card } from 'reactstrap';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import { formatCurrency } from '../../../../../../utils/currencyUtils';
import StatCardValue from '../../components/StatCardValue';
import StatCardRowSeparator from '../../components/StatCardRowSeparator';
import CustomerFinancialTrendBadge from '../../../../../global/customer/CustomerFinancialTrendBadge';

const i18nOpts = { scope: 'components.admin.customers.financialReport.financialReportFlinks.accountAnalysisCard.index' };

const AccountAnalysisCard = ({ financialReport }) => {
  const { accountAnalysis } = financialReport;
  const {
    activeDaysInAccount, firstDetectedTransaction,
    averageMonthlyFreeCashFlow, averageMonthlyRecurringPayments,
    averageMonthlyRecurringDeposits, activeDaysTrend, debitActivityTrend,
    creditActivityTrend, overallActivityTrend
  } = accountAnalysis;

  return (
    <Card body>
      <div className="d-flex align-items-center justify-content-around">
        <StatCardValue title={i18n.t('activeDaysInAccount', i18nOpts)}>
          {activeDaysInAccount}
        </StatCardValue>
        <StatCardValue title={i18n.t('firstDetectedTransaction', i18nOpts)} showSeparator>
          {firstDetectedTransaction}
        </StatCardValue>
        <StatCardValue title={i18n.t('averageMonthlyFreeCashFlow', i18nOpts)} showSeparator>
          {formatCurrency(averageMonthlyFreeCashFlow)}
        </StatCardValue>
        <StatCardValue title={i18n.t('averageMonthlyRecurringPayments', i18nOpts)} showSeparator>
          {formatCurrency(averageMonthlyRecurringPayments)}
        </StatCardValue>
        <StatCardValue title={i18n.t('averageMonthlyRecurringDeposits', i18nOpts)} showSeparator>
          {formatCurrency(averageMonthlyRecurringDeposits)}
        </StatCardValue>
      </div>

      <StatCardRowSeparator />

      <div className="d-flex align-items-center justify-content-around">
        <StatCardValue title={i18n.t('activeDaysInAccount', i18nOpts)}>
          <CustomerFinancialTrendBadge trend={activeDaysTrend} />
        </StatCardValue>
        <StatCardValue title={i18n.t('debitActivityTrend', i18nOpts)} showSeparator>
          <CustomerFinancialTrendBadge trend={debitActivityTrend} />
        </StatCardValue>
        <StatCardValue title={i18n.t('creditActivityTrend', i18nOpts)} showSeparator>
          <CustomerFinancialTrendBadge trend={creditActivityTrend} />
        </StatCardValue>
        <StatCardValue title={i18n.t('overallActivityTrend', i18nOpts)} showSeparator>
          <CustomerFinancialTrendBadge trend={overallActivityTrend} />
        </StatCardValue>
      </div>
    </Card>
  );
};

AccountAnalysisCard.propTypes = {};

AccountAnalysisCard.defaultProps = {};

export default connect((store) => ({
  financialReport: store.customers.financialReport,
}))(AccountAnalysisCard);
