import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { Link } from 'gatsby';
import { connect } from 'react-redux';

import { isAdmin } from '../../../../utils/authUtils';
import ListEmpty from '../../../common/ListEmpty';
import {
  getUnits,
  cleanUnits,
  setUnitsViewMode,
  setUnitColumns,
  setUnitFilters
} from '../../../../store/actions/unitActions';
import { ViewModes } from '../../../../constants';
import GridView from './GridView';
import ListView from './ListView';
import ViewModesDropdown from '../../../global/ViewModesDropdown';
import ListPagination from '../../../common/ListPagination';
import Loading from '../../../ui/Loading';
import SearchBar from '../../../common/SearchBar';
import { getUnitFilters } from './utils';
import FiltersButton from './components/FiltersButton';
import FiltersModal from './components/FiltersModal';
import EditColumnsButton from './components/EditColumnsButton';
import ColumnsPickerModal from './components/ColumnsPickerModal';

const i18nOpts = { scope: 'components.admin.units.list.index' };

const List = ({
  units, loading, pagination, viewMode, columns, filters, currentCompany, currentUser, ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const [query, setQuery] = useState(null);
  const [initialColumnsSet, setInitialColumnsSet] = useState(false);

  const loadUnits = (page = 1) => {
    const filter = getUnitFilters(currentCompany, filters, query);

    const variables = { filter, page };
    props.getUnits(variables)
      .finally(() => setMounted(true));
  };

  const onSearch = (e, q) => {
    e.preventDefault();

    setQuery(q);
  };

  const onChangeViewMode = (newMode) => {
    props.setUnitsViewMode(newMode);
  };

  const onChangePage = (page) => {
    loadUnits(page);
  };

  const setInitialColumns = () => {
    const { units: initialColumns = [] } = currentUser.columnSettings || {};
    if (initialColumns && initialColumns.length) props.setUnitColumns(initialColumns);
    setInitialColumnsSet(true);
  };

  useEffect(() => {
    if (initialColumnsSet) loadUnits();
  }, [filters, query, columns, initialColumnsSet]);

  useEffect(() => {
    props.cleanUnits()
      .then(() => setInitialColumns());
  }, []);

  if (!mounted) return <Loading loading fullScreen />;

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="mb-4">
        <div className="font-24">{i18n.t('title', i18nOpts)}</div>
      </div>

      <div className="d-flex mb-4 align-items-center flex-wrap gap-3">
        <div className="flex-grow-1">
          <SearchBar
            onSubmit={onSearch}
            inputProps={{ bsSize: 'md', placeholder: i18n.t('searchBarPlaceholder', i18nOpts) }}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center flex-wrap gap-3">
          <ViewModesDropdown viewMode={viewMode} onChange={onChangeViewMode} />

          {viewMode === ViewModes.LIST && <EditColumnsButton />}

          <FiltersButton />

          {isAdmin() && (
            <Link to="/admin/units/form" className="btn btn-primary">
              <i className="fas fa-plus mr-2" />
              {i18n.t('buttons.add', i18nOpts)}
            </Link>
          )}
        </div>
      </div>

      {loading ? (
        <Loading loading />
      ) : (
        <>
          <ListEmpty loading={loading} items={units} />

          {units.length > 0 && (
            <div className="mb-4">
              {viewMode === ViewModes.GRID ? (
                <GridView />
              ) : (
                <ListView />
              )}
            </div>
          )}

          <div className="text-right">
            <ListPagination pagination={pagination} onPress={onChangePage} />
          </div>
        </>
      )}

      <ColumnsPickerModal />
      <FiltersModal />
    </div>
  );
};

export default connect((store) => ({
  units: store.units.units,
  loading: store.units.getUnits.loading,
  pagination: store.units.pagination,
  viewMode: store.units.viewMode,
  columns: store.units.columns,
  filters: store.units.filters,
  currentCompany: store.companies.currentCompany,
  currentUser: store.users.currentUser
}), {
  getUnits,
  cleanUnits,
  setUnitsViewMode,
  setUnitColumns,
  setUnitFilters
})(List);
