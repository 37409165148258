import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import moment from 'moment';
import i18n from 'i18n-js';

import { Colors, DateFormats } from '../../../../../../../constants';
import { formatCurrency } from '../../../../../../../utils/currencyUtils';

const i18nOpts = { scope: 'components.admin.customers.financialReport.financialReportFlinks.components.monthlyAmountsChart.index' };

const MonthlyAmountsChart = ({ datasets, reportDate, titleY }) => {
  const labels = useMemo(() => {
    const totalLabels = datasets[0].data.length;
    const date = moment(reportDate);

    const dates = [];
    for (let i = 0; i < totalLabels; i += 1) {
      dates.push(date.format(DateFormats.MMM));

      date.subtract(1, 'month');
    }
    return dates.reverse();
  }, [reportDate, datasets]);

  const getData = () => {
    const mergedDatasets = datasets.map((dataset) => (
      {
        label: dataset.label,
        backgroundColor: dataset.color,
        hoverBackgroundColor: dataset.color,
        data: dataset.data,
        barPercentage: 0.6,
        borderRadius: 8,
        stack: 'amounts',
      }
    ));

    return {
      labels,
      datasets: mergedDatasets,
    };
  };

  const getOptions = () => ({
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: Colors.GRAY_600,
          font: {
            weight: '400',
            size: 12,
          },
        },
        grid: {
          display: false,
          borderColor: Colors.GRAY_100
        },
        title: {
          display: true,
          text: i18n.t('month', i18nOpts),
          color: Colors.GRAY_600,
          font: {
            size: 12,
            weight: '500',
          }
        }
      },
      y: {
        stacked: true,
        ticks: {
          color: Colors.GRAY_700,
          font: {
            weight: '400',
            size: 12,
          },
          callback(value, index, ticks) {
            return formatCurrency(value);
          }
        },
        grid: {
          color: Colors.GRAY_100,
          borderColor: 'white'
        },
        title: {
          display: true,
          text: titleY,
          color: Colors.GRAY_600,
          font: {
            size: 12,
            weight: '500',
          }
        }
      },
    },
    plugins: {
      legend: getLegend(),
      tooltip: {
        callbacks: {
          label(context) {
            return formatCurrency(context.raw, false, true);
          }
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
  });

  const getLegend = () => ({
    align: 'start',
    position: 'right',
    labels: {
      color: Colors.GRAY_600,
      font: {
        weight: '400',
        size: 14,
      },
      usePointStyle: true,
      pointStyleWidth: 10,
      boxHeight: 7
    },
  });

  const data = getData();

  return (
    <Bar data={data} options={{ ...getOptions() }} />
  );
};

MonthlyAmountsChart.propTypes = {
  datasets: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.number),
    color: PropTypes.string,
  })),
  reportDate: PropTypes.string.isRequired,
  titleY: PropTypes.string
};

MonthlyAmountsChart.defaultProps = {
  datasets: [],
  titleY: null
};

export default MonthlyAmountsChart;
