import React from 'react';
import { Card } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import { isValidAmount } from '../../../../../../utils/currencyUtils';
import PowerChart from '../PowerChart';
import { formatCustomerRentalPowerRange } from '../../../../../../utils/customerUtils';

const i18nOpts = { scope: 'components.admin.customers.financialReport.components.rentingPowerCard.index' };

const RentingPowerCard = ({ customer }) => {
  const { companyContact, party } = customer;

  const { affordability } = companyContact;
  const maxContactRentingAmount = affordability?.maxRentingAmount || 0;
  const maxPartyRentingAmount = party?.affordability?.maxRentingAmount;

  const hasMaxPartyRentingAmount = isValidAmount(maxPartyRentingAmount);
  const maxRentingAmount = hasMaxPartyRentingAmount ? maxPartyRentingAmount
    : maxContactRentingAmount;

  const formattedRentalPowerRange = formatCustomerRentalPowerRange(maxRentingAmount);
  const formattedContactRentalPowerRange = formatCustomerRentalPowerRange(maxContactRentingAmount);

  return (
    <Card body className="text-dark h-100 d-flex flex-column">
      <h4>{i18n.t('title', i18nOpts)}</h4>

      <div className="d-flex justify-content-center h-100">
        <PowerChart formattedAmount={formattedRentalPowerRange}>
          <div className="d-flex justify-content-center">
            <div className="d-inline-block">
              <div className="font-18 font-weight-500">{i18n.t('month', i18nOpts)}</div>
              {hasMaxPartyRentingAmount && (
                <div className="font-14">{i18n.t('withCoApplicant', i18nOpts)}</div>
              )}
            </div>
          </div>
        </PowerChart>
      </div>

      {hasMaxPartyRentingAmount && (
        <div className="font-weight-600 text-center font-16">
          <span>{i18n.t('withoutCoApplicant', i18nOpts)}</span>
          <span className="ml-1">{formattedContactRentalPowerRange}</span>
        </div>
      )}
    </Card>
  );
};

RentingPowerCard.propTypes = {};

RentingPowerCard.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer
}))(RentingPowerCard);
