import React from 'react';
import { Card } from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';

import {
  formatCustomerBuyingPowerRange,
} from '../../../../../../utils/customerUtils';
import PowerChart from '../PowerChart';
import { isValidAmount } from '../../../../../../utils/currencyUtils';

const i18nOpts = { scope: 'components.admin.customers.financialReport.components.buyingPowerCard.index' };

const BuyingPowerCard = ({ customer }) => {
  const { companyContact, party } = customer;
  const { affordability } = companyContact;

  const maxContactBuyingAmount = affordability?.maxHomeAffordability || 0;
  const maxPartyBuyingAmount = party?.affordability?.maxHomeAffordability;

  const hasMaxPartyBuyingAmount = isValidAmount(maxPartyBuyingAmount);
  const maxBuyingAmount = hasMaxPartyBuyingAmount ? maxPartyBuyingAmount
    : maxContactBuyingAmount;

  const formattedBuyingPower = formatCustomerBuyingPowerRange(maxBuyingAmount);
  const formattedContactBuyingPower = formatCustomerBuyingPowerRange(maxContactBuyingAmount);

  return (
    <Card body className="text-dark h-100 d-flex flex-column">
      <h4>{i18n.t('title', i18nOpts)}</h4>

      <PowerChart
        formattedAmount={formattedBuyingPower}
        header={
          <div className="text-center mb-2">{i18n.t('between', i18nOpts)}</div>
        }
      >
        {hasMaxPartyBuyingAmount && (
          <div className="font-14 text-center">{i18n.t('withCoBuyer', i18nOpts)}</div>
        )}
      </PowerChart>

      {hasMaxPartyBuyingAmount && (
        <div className="font-weight-600 text-center font-16">
          <span>{i18n.t('withoutCoBuyer', i18nOpts)}</span>
          <span className="ml-1">{formattedContactBuyingPower}</span>
        </div>
      )}
    </Card>
  );
};

BuyingPowerCard.propTypes = {};

BuyingPowerCard.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer
}))(BuyingPowerCard);
