import { useMemo } from 'react';
import { toast } from 'react-toastify';
import v from 'voca';

import useForm from '../../../../../hooks/useForm';
import { parseBoolean, parseToFloat, parseToInt } from '../../../../../utils/parserUtils';
import { buildDisclaimerTexts, buildValidUntil, validateValidUntil } from '../../../../../utils/settingsUtils';
import { getIpsArray } from '../../../../../utils/ipUtils';
import { stripToEmpty, stripToNull } from '../../../../../utils/stringUtils';
import { getGraphQLErrors } from '../../../../../utils/requestUtils';
import { DefaultDesign, DefaultDisclaimers, DefaultMessages } from '../utils';
import {
  BankVerificationProviders,
  ContactStages,
  DEFAULT_TIMEZONE,
  DefaultHomePage,
  DownPaymentTypes,
  EmailServices, HomePriceDisplayModes,
  IdVerificationProviders,
  ReserveStepTypes,
  WorkflowTypes
} from '../../../../../constants';
import { hasFormChanges } from '../../../../../utils/formUtils';
import { isMasterAdmin, isTecSupport } from '../../../../../utils/authUtils';

const useSaveCompanySettings = (props) => {
  const {
    form, setForm, error, setError, onTextChange, submitting, setSubmitting,
    initialForm, setInitialForm
  } = useForm();

  const getDesign = () => {
    const settings = form.settings || {};
    const design = settings.design || {};
    const enableValidUntil = parseBoolean(form.enableValidUntil);
    return {
      ...design,
      fixedDepositAmount: parseToFloat(design.fixedDepositAmount) || null,
      ...(enableValidUntil && validateValidUntil(design.validUntil) ? {
        validUntil: buildValidUntil(design.validUntil)
      } : {})
    };
  };

  const getDisclaimers = () => {
    const settings = form.settings || {};
    const disclaimers = settings.disclaimerTexts || [];
    return buildDisclaimerTexts(disclaimers);
  };

  const getMortgageRateSettings = () => {
    const mortgageRateSettings = form.mortgageRateSettings || {};
    return {
      ...mortgageRateSettings,
      downPayment: parseToFloat(mortgageRateSettings.downPayment) || null,
      loanTerm: parseToInt(mortgageRateSettings.loanTerm),
      interestRate: parseToFloat(mortgageRateSettings.interestRate) || null
    };
  };

  const getReserveNowSettings = () => {
    const reserveNowSettings = form.reserveNowSettings || {};
    const enabled = parseBoolean(reserveNowSettings.enabled);

    return {
      ...reserveNowSettings,
      enabled: parseBoolean(reserveNowSettings.enabled),
      ...(enabled ? {
        enabledPriceNegotiation: parseBoolean(reserveNowSettings.enabledPriceNegotiation),
        enabledReports: parseBoolean(reserveNowSettings.enabledReports)
      } : {
        enabledPriceNegotiation: false,
        enabledReports: false
      })
    };
  };

  const getExportSettings = () => {
    const exportSettings = form.exportSettings || {};
    return {
      ...exportSettings,
      customers: parseBoolean(exportSettings.customers)
    };
  };

  const getReportSettings = () => {
    const reportSettings = form.reportSettings || {};
    const enabled = parseBoolean(reportSettings.enabled);
    const enabledFinancialReport = parseBoolean(reportSettings.enabledFinancialReport);
    return {
      ...reportSettings,
      enabled: parseBoolean(reportSettings.enabled),
      ...(enabled ? {
        enabledFinancialReport: parseBoolean(reportSettings.enabledFinancialReport),
        ...(enabledFinancialReport ? {
          enabledFinancialHighlights: parseBoolean(reportSettings.enabledFinancialHighlights)
        } : {
          enabledFinancialHighlights: false
        }),
        enabledMarketingReport: parseBoolean(reportSettings.enabledMarketingReport)
      } : {
        enabledFinancialReport: false,
        enabledFinancialHighlights: false,
        enabledMarketingReport: false
      })
    };
  };

  const getInput = () => {
    const settings = form.settings || {};
    const design = getDesign();
    const messages = settings.messages || [];
    const domains = form.domains || [];
    const excludeTrafficSettings = { excludedIPs: getIpsArray(form.ipToExclude) };
    const enableHomeRental = parseBoolean(form.enableHomeRental);
    const enableHomeSales = parseBoolean(form.enableHomeSales) && !enableHomeRental;
    const mortgageRateSettings = getMortgageRateSettings();
    const contactStages = form.contactStages || [];
    const leadNotificationSalesRepresentativeIds = (form.leadNotificationSalesRepresentatives || [])
      .map((i) => i.id);

    let input = {
      name: stripToEmpty(form.name),
      countryCode: stripToEmpty(form.countryCode),
      country: stripToEmpty(form.country),
      stateCode: stripToEmpty(form.stateCode),
      state: stripToEmpty(form.state),
      city: stripToEmpty(form.city),
      settings: {
        design,
        messages
      },
      excludeTrafficSettings,
      logoRedirectUrl: stripToNull(form.logoRedirectUrl),
      termsUrl: stripToNull(form.termsUrl),
      useStartingFromPrice: parseBoolean(form.useStartingFromPrice),
      enableHomesFromPriceForCommunities: parseBoolean(form.enableHomesFromPriceForCommunities),
      overrideQuickPossessionSize: parseBoolean(form.overrideQuickPossessionSize),
      enableHomeRental,
      ...(enableHomeRental ? {
        enableSingleFamilyHomesRental: parseBoolean(form.enableSingleFamilyHomesRental),
        enableMultiFamilyHomesRental: parseBoolean(form.enableMultiFamilyHomesRental)
      } : {}),
      enableHomeSales,
      ...(enableHomeSales ? {
        enableSingleFamilyHomes: parseBoolean(form.enableSingleFamilyHomes),
        enableMultiFamilyHomes: parseBoolean(form.enableMultiFamilyHomes)
      } : {}),
      mortgageRateSettings,
      enableValidUntil: parseBoolean(form.enableValidUntil),
      contactStages,
      defaultContactStage: stripToNull(form.defaultContactStage),
      leadNotificationSalesRepresentativeIds,
      homePriceDisplayMode: stripToNull(form.homePriceDisplayMode),
      enableCommunication: parseBoolean(form.enableCommunication)
    };

    if (isMasterAdminRole || isTecSupportRole) {
      const filteredDomains = domains.filter((domain) => !v.isBlank(domain));
      const reserveNowSettings = getReserveNowSettings();
      const enableEmbeddedReserve = reserveNowSettings.enabled
        && parseBoolean(form.enableEmbeddedReserve);
      const disclaimers = getDisclaimers();

      input = {
        ...input,
        defaultHomePage: form.defaultHomePage,
        domains: filteredDomains,
        enableExitPageReminder: parseBoolean(form.enableExitPageReminder),
        enableCaptureLeadsFromBeginning: parseBoolean(form.enableCaptureLeadsFromBeginning),
        enableNewHomeNavigation: parseBoolean(form.enableNewHomeNavigation),
        enableNewClientUI: parseBoolean(form.enableNewClientUI),
        enableACHPayments: parseBoolean(form.enableACHPayments),
        enableVerifiedBuyingPower: parseBoolean(form.enableVerifiedBuyingPower),
        reserveNowSettings,
        enableEmbeddedReserve,
        embeddedReserveBaseUrl: stripToEmpty(form.embeddedReserveBaseUrl),
        enableIdVerification: parseBoolean(form.enableIdVerification),
        idVerificationProvider: stripToNull(form.idVerificationProvider),
        enablePreApprovalVerification: parseBoolean(form.enablePreApprovalVerification),
        preApprovalVerificationProvider: stripToNull(form.preApprovalVerificationProvider),
        enableDemoModeForHomewise: parseBoolean(form.enableDemoModeForHomewise),
        enableContactPhoneRequired: parseBoolean(form.enableContactPhoneRequired),
        enablePickingALot: parseBoolean(form.enablePickingALot),
        enableSampleData: parseBoolean(form.enableSampleData),
        enableAirMilesForReserveNow: parseBoolean(form.enableAirMilesForReserveNow),
        enableAirMilesForVerified: parseBoolean(form.enableAirMilesForVerified),
        emailService: stripToNull(form.emailService),
        senderEmail: stripToNull(form.senderEmail),
        settings: {
          ...input.settings,
          disclaimerTexts: disclaimers
        },
        enableDemoModeForReserveNow: parseBoolean(form.enableDemoModeForReserveNow),
        enableDemoModeForVerified: parseBoolean(form.enableDemoModeForVerified),
        bankVerificationProvider: stripToNull(form.bankVerificationProvider)
      };
    }

    if (isMasterAdminRole) {
      const exportSettings = getExportSettings();
      const reportSettings = getReportSettings();
      input = {
        ...input,
        enableCORSForJavaScriptSDK: parseBoolean(form.enableCORSForJavaScriptSDK),
        exportSettings,
        reportSettings,
        timezone: stripToNull(form.timezone)
      };
    }

    if (form.id) input.id = form.id;

    return input;
  };

  const saveCompanySettingsAsync = async () => {
    setSubmitting(true);

    const input = getInput();
    const { logo: image, icon } = form;

    const variables = { input, image, icon };

    return props.saveCompany(variables)
      .then(({ value: { data: { item } } }) => {
        setForm((prevForm) => ({ ...prevForm, id: item.id }));
        return saveCompanyWorkflowsAsync(item.id, item.enableHomeRental);
      })
      .catch((e) => {
        const formattedError = getGraphQLErrors(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
        return Promise.reject(e);
      })
      .finally(() => setSubmitting(false));
  };

  const getValidVerifiedWorkflow = () => {
    const { verifiedWorkflow } = form;
    if (!verifiedWorkflow) return null;

    return verifiedWorkflow
      .filter((item) => item.enabled)
      .map((item) => ({ type: item.type, optional: item.optional }));
  };

  const getValidReserveNowWorkflow = () => {
    const { reserveNowWorkflow } = form;
    if (!reserveNowWorkflow) return null;

    return reserveNowWorkflow
      .filter((item) => item.enabled)
      .map((item) => {
        const result = { type: item.type };

        // eslint-disable-next-line max-len
        if ([ReserveStepTypes.OFFER, ReserveStepTypes.SIGN, ReserveStepTypes.DEPOSIT].includes(item.type)) {
          result.label = item.label;
        }

        if ([ReserveStepTypes.SIGN, ReserveStepTypes.DEPOSIT].includes(item.type)) {
          result.autocomplete = item.autocomplete;
        }

        if (item.type === ReserveStepTypes.OFFER && Array.isArray(item.verifications)) {
          result.verifications = item.verifications
            .filter((verification) => verification.enabled)
            .map((verification) => ({ type: verification.type, optional: verification.optional }));
        }

        return result;
      });
  };

  const saveCompanyWorkflowsAsync = async (companyId, isHomeRental) => {
    const promises = [];

    const verifiedWorkflow = getValidVerifiedWorkflow();
    const reserveNowWorkflow = getValidReserveNowWorkflow();
    if (verifiedWorkflow) {
      const variables = {
        companyId,
        type: isHomeRental ? WorkflowTypes.RENTAL_VERIFIED : WorkflowTypes.BUYER_VERIFIED,
        workflow: verifiedWorkflow
      };
      promises.push(props.saveCompanyWorkflow(variables));
    }
    if (reserveNowWorkflow) {
      const variables = {
        companyId,
        type: isHomeRental ? WorkflowTypes.RENTAL_RESERVE : WorkflowTypes.BUYER_RESERVE,
        workflow: reserveNowWorkflow
      };
      promises.push(props.saveCompanyWorkflow(variables));
    }

    return Promise.all(promises)
      .catch((e) => Promise.reject(e));
  };

  const populateFormAsync = async (company) => {
    let formData = { logo: undefined, icon: undefined };

    if (company) {
      const settings = company.settings || {};
      const design = settings.design || DefaultDesign;
      const messages = settings.messages || DefaultMessages;
      const disclaimers = settings.disclaimerTexts || DefaultDisclaimers;

      const {
        excludeTrafficSettings,
        enableHomeSales,
        enableHomeRental,
        leadNotificationSalesRepresentatives
      } = company;

      formData = {
        ...formData,
        ...company,
        enableHomeSales: enableHomeSales && !enableHomeRental,
        settings: {
          ...settings,
          design,
          messages,
          disclaimers
        },
        ipToExclude: excludeTrafficSettings?.excludedIPs.join(', ') || '',
        leadNotificationSalesRepresentatives: (leadNotificationSalesRepresentatives || []).map((item) => ({ ...item, displayValue: `${item.name} - ${item.email}` }))
      };
    } else {
      formData = {
        ...formData,
        enableNewClientUI: true,
        enableHomeSales: true,
        enableSingleFamilyHomes: true,
        mortgageRateSettings: {
          downPaymentType: DownPaymentTypes.AMOUNT
        },
        enableValidUntil: true,
        idVerificationProvider: IdVerificationProviders.ONFIDO,
        defaultHomePage: DefaultHomePage.MY_HOMES,
        settings: {
          design: DefaultDesign,
          messages: DefaultMessages,
          disclaimerTexts: DefaultDisclaimers
        },
        emailService: EmailServices.AWS_SES,
        contactStages: Object.keys(ContactStages),
        defaultContactStage: null,
        homePriceDisplayMode: HomePriceDisplayModes.TOTAL_PRICE_ONLY,
        enableCommunication: true,
        timezone: DEFAULT_TIMEZONE,
        bankVerificationProvider: BankVerificationProviders.FLINKS
      };
    }

    setInitialForm(formData);
    setForm(formData);

    return Promise.resolve();
  };

  const loadCompanyAsync = async (id) => {
    const variables = { id, withWorkflows: true };
    return props.getCompany(variables)
      .then(({ value: { data: { item } } }) => item);
  };

  const hasChanges = useMemo(() => hasFormChanges(initialForm, form), [initialForm, form]);
  const isMasterAdminRole = isMasterAdmin();
  const isTecSupportRole = isTecSupport();

  return {
    initialForm,
    form,
    setForm,
    submitting,
    setSubmitting,
    error,
    setError,
    onTextChange,
    hasChanges,
    loadCompanyAsync,
    populateFormAsync,
    saveCompanySettingsAsync
  };
};

export default useSaveCompanySettings;
