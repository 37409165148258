import api from '../../../../../api';
import { getUtmSourceReportQuery } from '../../../../../graphql';
import { fakeValue } from '../../../../../utils/sampleDataUtils';
import { innerRingChartColors } from '../utils';

export function fetchUtmSourceReportAsync(filter) {
  const variables = { reportFilter: filter };
  return api.graphql(getUtmSourceReportQuery, variables)
    .then(({ data: { results } }) => results);
}

export function generateSampleDataAsync() {
  let data = [
    { utmSource: 'SMS Invitation', visitorsCount: fakeValue(100) },
    { utmSource: 'Email Invitation', visitorsCount: fakeValue(100) },
    { utmSource: 'Website', visitorsCount: fakeValue(100) },
    { utmSource: 'Campaign', visitorsCount: fakeValue(100) }
  ];

  data = data.map((item) => ({
    ...item,
    leadsCount: fakeValue(item.visitorsCount)
  }));
  data.sort((a, b) => b.visitorsCount - a.visitorsCount);
  return Promise.resolve(data);
}

export function getVisitorsChartData(utmSourceData) {
  const colors = innerRingChartColors.slice(0, utmSourceData.length);

  return {
    labels: utmSourceData.map((c) => c.utmSource),
    datasets: [
      {
        backgroundColor: colors,
        data: utmSourceData.map((c) => c.visitorsCount)
      }
    ]
  };
}

export function getLeadsChartData(utmSourceData) {
  const colors = innerRingChartColors.slice(0, utmSourceData.length);

  return {
    labels: utmSourceData.map((c) => c.utmSource),
    datasets: [
      {
        backgroundColor: colors,
        data: utmSourceData.map((c) => c.leadsCount)
      }
    ]
  };
}
