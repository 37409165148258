import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collapse, Row, Col } from 'reactstrap';
import { IoIosArrowDown } from 'react-icons/io';
import { Link } from 'gatsby';
import { MdFiberNew } from 'react-icons/md';
import v from 'voca';

import { formatTimestamp, isRecently } from '../../../../../utils/dateUtils';
import { saveMessage } from '../../../../../store/actions/messageActions';
import EntityInfo from '../../../../global/ClientMessage/EntityInfo';

const ListItem = ({ message, ...props }) => {
  const [opened, setOpened] = useState(false);
  const { hasBeenRead } = message;

  const toggleMessage = () => {
    if (!hasBeenRead) {
      const input = { id: message.id, hasBeenRead: true };
      const variables = { input };
      props.saveMessage(variables);
    }
    setOpened(!opened);
  };

  return (
    <>
      <tr>
        <td>
          <div>
            <IoIosArrowDown
              onClick={toggleMessage}
              size="1.2rem"
              className={classNames('mr-3 toggle-arrow', { up: opened })}
              role="button"
            />
            <span>
              {v.isBlank(message.contactId) ? (
                <span>
                  {message.name}
                  {!hasBeenRead && (
                    <span className="ml-2 col-warning"><MdFiberNew size="1.3rem" /></span>
                  )}
                </span>
              ) : (
                <Link to={`/admin/customers/${message.contactId}`}>
                  {message.name}
                  {!hasBeenRead && (
                    <span className="ml-2 col-warning"><MdFiberNew size="1.3rem" /></span>
                  )}
                </Link>
              )}
            </span>
          </div>
        </td>
        <td>{message.email}</td>
        <td>{message.phoneNumber}</td>
        <td>
          <span className={classNames({ 'text-success': isRecently(message.createdAt) })}>
            {formatTimestamp(message.createdAt)}
          </span>
        </td>
      </tr>

      <tr className="border-0">
        <td colSpan={4} className={classNames('p-0', { 'border-0': !opened })}>
          <Collapse isOpen={opened}>
            <Row>
              <Col>
                <div className="p-3">
                  {message.message}
                </div>
              </Col>
              <Col>
                <div className="p-3">
                  <EntityInfo message={message} />
                </div>
              </Col>
            </Row>
          </Collapse>
        </td>
      </tr>
    </>
  );
};

ListItem.propTypes = {
  message: PropTypes.objectOf(PropTypes.any).isRequired
};

export default connect(() => ({}), {
  saveMessage
})(ListItem);
