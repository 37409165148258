import React from 'react';
import PropTypes from 'prop-types';

import UtmCampaignSection from './UtmCampaignSection';
import UtmSourceSection from './UtmSourceSection';
import UtmMediumSection from './UtmMediumSection';
import UtmContentSection from './UtmContentSection';

const Marketing = ({ company, dateRange }) => (
  <>
    <UtmCampaignSection company={company} dateRange={dateRange} />
    <UtmSourceSection company={company} dateRange={dateRange} />
    <UtmMediumSection company={company} dateRange={dateRange} />
    <UtmContentSection company={company} dateRange={dateRange} />
  </>
);

Marketing.propTypes = {
  company: PropTypes.objectOf(PropTypes.any),
  dateRange: PropTypes.objectOf(PropTypes.any).isRequired
};

Marketing.defaultProps = {
  company: null
};

export default Marketing;
