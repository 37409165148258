import React from 'react';
import { connect } from 'react-redux';

import BaseColumnsPickerModal from '../../../../../global/ColumnsPickerModal';
import { ModalNames, UnitColumns } from '../../../../../../constants';
import { getUnitColumnName } from '../../../../../../utils/enumUtils';
import { setUnitColumns } from '../../../../../../store/actions/unitActions';

const ColumnsPickerModal = ({ selectedColumns }) => (
  <BaseColumnsPickerModal
    modalName={ModalNames.UNIT_COLUMNS_PICKER}
    getColumnName={getUnitColumnName}
    columnSettingsKey="units"
    selectedColumns={selectedColumns}
    selectableColumns={Object.values(UnitColumns)}
    setColumnsAction={setUnitColumns}
  />
);

ColumnsPickerModal.propTypes = {};

ColumnsPickerModal.defaultProps = {};

export default connect((store) => ({
  selectedColumns: store.units.columns || []
}))(ColumnsPickerModal);
