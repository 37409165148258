import React from 'react';
import i18n from 'i18n-js';
import moment from 'moment';
import { connect } from 'react-redux';
import v from 'voca';

import { DateFormats } from '../../../../../../constants';

const i18nOpts = { scope: 'components.admin.customers.financialReport.components.reportHeader.index' };

const ReportHeader = ({ customer }) => {
  const { companyContact } = customer;
  const { affordability } = companyContact;
  const { assessedAt } = affordability;
  const reportDate = moment(assessedAt).format(DateFormats.MM_DD_YYYY_2);

  return (
    <div className="text-right">
      <div>
        <span className="font-24 col-gray-800 font-weight-500">{i18n.t('reportDate', i18nOpts)}</span>
        <span className="font-24 col-gray-800 font-weight-500 ml-1">{reportDate}</span>
      </div>
      {!v.isBlank(customer.email) && (
        <div>
          <span className="font-18 col-gray-800">{i18n.t('email', i18nOpts)}</span>
          <span className="font-18 col-gray-800 ml-1">{customer.email}</span>
        </div>
      )}
      {!v.isBlank(customer.phone) && (
        <div>
          <span className="font-18 col-gray-800">{i18n.t('phone', i18nOpts)}</span>
          <span className="font-18 col-gray-800 ml-1">{customer.phone}</span>
        </div>
      )}
    </div>
  );
};

ReportHeader.propTypes = {};

ReportHeader.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer
}))(ReportHeader);
