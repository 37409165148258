import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import i18n from 'i18n-js';

import styles from './MonthlyAmountsTable.module.scss';
import { formatCurrency } from '../../../../../../../utils/currencyUtils';

const i18nOpts = { scope: 'components.admin.customers.financialReport.financialReportFlinks.components.monthlyAmountsTable.index' };

const MonthlyAmountsTable = ({ monthlyTotals }) => {
  const indexedMonthlyTotals = useMemo(() => monthlyTotals.map((monthlyTotal, index) => (
    { ...monthlyTotal, key: `monthly-total-${index}` })), []);

  return (
    <div className={styles.container}>
      <Table bordered className={styles.table}>
        <thead>
          <tr>
            <th className={styles.header}>{i18n.t('amount', i18nOpts)}</th>
            {
              indexedMonthlyTotals.map((monthlyTotal) => (
                <th key={`amount-${monthlyTotal.key}`} className="text-center">
                  {formatCurrency(monthlyTotal.amount)}
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{i18n.t('count', i18nOpts)}</td>
            {
              indexedMonthlyTotals.map((monthlyTotal) => (
                <td key={`count-${monthlyTotal.key}`} className="text-center">
                  {monthlyTotal.count}
                </td>
              ))
            }
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

MonthlyAmountsTable.propTypes = {
  monthlyTotals: PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.number,
    count: PropTypes.number
  })).isRequired
};

MonthlyAmountsTable.defaultProps = {};

export default MonthlyAmountsTable;
