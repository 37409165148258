import React from 'react';
import { Card } from 'reactstrap';
import { connect } from 'react-redux';
import i18n from 'i18n-js';

import StatCardValue from '../../components/StatCardValue';
import { formatCurrency } from '../../../../../../utils/currencyUtils';

const i18nOpts = { scope: 'components.admin.customers.financialReport.financialReportPlaid.incomesSection.index' };

const IncomesSection = ({ financialReport }) => {
  const {
    incomeWages,
    incomeDividends,
    incomeInterestEarned,
    incomeRetirementPension,
    incomeOtherIncome
  } = financialReport;

  return (
    <Card body>
      <div className="d-flex align-items-center justify-content-around">
        <StatCardValue title={i18n.t('wages', i18nOpts)}>
          {formatCurrency(incomeWages)}
        </StatCardValue>
        <StatCardValue title={i18n.t('dividends', i18nOpts)} showSeparator>
          {formatCurrency(incomeDividends)}
        </StatCardValue>
        <StatCardValue title={i18n.t('interestEarned', i18nOpts)} showSeparator>
          {formatCurrency(incomeInterestEarned)}
        </StatCardValue>
        <StatCardValue title={i18n.t('retirementPension', i18nOpts)} showSeparator>
          {formatCurrency(incomeRetirementPension)}
        </StatCardValue>
        <StatCardValue title={i18n.t('otherIncome', i18nOpts)} showSeparator>
          {formatCurrency(incomeOtherIncome)}
        </StatCardValue>
      </div>
    </Card>
  );
};

IncomesSection.propTypes = {};

IncomesSection.defaultProps = {};

export default connect((store) => ({
  financialReport: store.customers.financialReport
}))(IncomesSection);
