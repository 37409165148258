import {
  START,
  SUCCESS,
  ERROR,
  GET_UNIT,
  GET_UNITS,
  SAVE_UNIT,
  DELETE_UNIT,
  CLEAN_UNITS,
  SET_UNIT_VIEW_MODE,
  SET_UNIT_FILTERS,
  SET_UNIT_COLUMNS
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { DefaultUnitColumns, ViewModes } from '../../constants';

const INITIAL_STATE = {
  units: [],
  unit: null,
  viewMode: ViewModes.LIST,
  pagination: null,
  columns: DefaultUnitColumns,
  filters: {},

  getUnits: {},
  getUnit: {},
  saveUnit: {},
  deleteUnit: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_UNITS + START:
      return { ...state, getUnits: { loading: true } };
    case GET_UNITS + SUCCESS:
      return {
        ...state,
        getUnits: { loading: false },
        units: data.result.items,
        pagination: data.result.pagination
      };
    case GET_UNITS + ERROR:
      return { ...state, getUnits: { loading: false, error }, pagination: null };

    case CLEAN_UNITS + SUCCESS:
      return { ...state, getUnits: { loading: false }, units: [] };

    case GET_UNIT + START:
      return { ...state, getUnit: { loading: true } };
    case GET_UNIT + SUCCESS:
      return { ...state, getUnit: { loading: false }, unit: data.item };
    case GET_UNIT + ERROR:
      return { ...state, getUnit: { loading: false, error } };

    case SAVE_UNIT + START:
      return { ...state, saveUnit: { loading: true } };
    case SAVE_UNIT + SUCCESS:
      return { ...state, saveUnit: { loading: false }, unit: data.item };
    case SAVE_UNIT + ERROR:
      return { ...state, saveUnit: { loading: false, error } };

    case DELETE_UNIT + START:
      return { ...state, deleteUnit: { loading: true } };
    case DELETE_UNIT + SUCCESS:
      return {
        ...state,
        deleteUnit: { loading: false },
        units: state.units.filter((u) => u.id !== data.item.id)
      };
    case DELETE_UNIT + ERROR:
      return { ...state, deleteUnit: { loading: false, error } };

    case SET_UNIT_VIEW_MODE:
      return { ...state, viewMode: action.payload.viewMode };

    case SET_UNIT_COLUMNS:
      return { ...state, columns: action.payload.columns };

    case SET_UNIT_FILTERS:
      return { ...state, filters: action.payload.filters };

    default:
      return { ...state };
  }
}
