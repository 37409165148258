import React from 'react';
import PropTypes from 'prop-types';

import HomeCard from '../../HomeCard';

const EntityInfo = ({ message }) => {
  const { community, project, home } = message;

  const communityOrProject = community || project;
  if (communityOrProject) {
    return <div className="col-gray-900 font-weight-500">{communityOrProject.name}</div>;
  }

  if (home) {
    return <HomeCard home={home} />;
  }

  return null;
};

EntityInfo.propTypes = {
  message: PropTypes.objectOf(PropTypes.any).isRequired
};

EntityInfo.defaultProps = {};

export default EntityInfo;
