import React from 'react';
import i18n from 'i18n-js';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';

import { formatCurrency } from '../../../../../../../utils/currencyUtils';

const i18nOpts = { scope: 'components.admin.customers.financialReport.financialReportFlinks.liabilitiesBreakdown.monthlyLoanPaymentsTable.index' };

const MonthlyLoanPaymentsTable = ({ financialReport }) => {
  const {
    averageMonthlyLoanPaymentsAmount,
    averageMonthlyMicroLoanPaymentsAmount,
    averageMonthlyMortgagePaymentsAmount,
    averageMonthlyAutomotiveLoanPaymentsAmount,
    averageMonthlyStudentLoanPaymentsAmount,
    averageMonthlyOtherLoanPaymentsAmount
  } = financialReport;

  return (
    <Table bordered responsive className="mt-4">
      <thead>
        <tr>
          <th>{i18n.t('loanType', i18nOpts)}</th>
          <th>{i18n.t('averageMonthlyAmount', i18nOpts)}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{i18n.t('averageMonthlyLoanPaymentsAmount', i18nOpts)}</td>
          <td>{formatCurrency(averageMonthlyLoanPaymentsAmount)}</td>
        </tr>
        <tr>
          <td>{i18n.t('averageMonthlyMicroLoanPaymentsAmount', i18nOpts)}</td>
          <td>{formatCurrency(averageMonthlyMicroLoanPaymentsAmount)}</td>
        </tr>
        <tr>
          <td>{i18n.t('averageMonthlyMortgagePaymentsAmount', i18nOpts)}</td>
          <td>{formatCurrency(averageMonthlyMortgagePaymentsAmount)}</td>
        </tr>
        <tr>
          <td>{i18n.t('averageMonthlyAutomotiveLoanPaymentsAmount', i18nOpts)}</td>
          <td>{formatCurrency(averageMonthlyAutomotiveLoanPaymentsAmount)}</td>
        </tr>
        <tr>
          <td>{i18n.t('averageMonthlyStudentLoanPaymentsAmount', i18nOpts)}</td>
          <td>{formatCurrency(averageMonthlyStudentLoanPaymentsAmount)}</td>
        </tr>
        <tr>
          <td>{i18n.t('averageMonthlyOtherLoanPaymentsAmount', i18nOpts)}</td>
          <td>{formatCurrency(averageMonthlyOtherLoanPaymentsAmount)}</td>
        </tr>
      </tbody>
    </Table>
  );
};

MonthlyLoanPaymentsTable.propTypes = {};

MonthlyLoanPaymentsTable.defaultProps = {};

export default connect((store) => ({
  financialReport: store.customers.financialReport,
}))(MonthlyLoanPaymentsTable);
