import React from 'react';
import {
  Navbar,
  NavbarBrand,
} from 'reactstrap';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import * as v from 'voca';
import { MdFiberNew } from 'react-icons/md';
import classNames from 'classnames';

import styles from './TopBar.module.scss';
import CompanySelect from './CompanySelect';
import UserMenuDropdown from './UserMenuDropdown';
import OwnlyDarkText from '../../../assets/images/OwnlyDarkText';
import OwnlyIcon from '../../../assets/icons/OwnlyIcon';
import SidebarToggleButton from './SidebarToggleButton';
import InviteButton from './InviteButton';

const i18nOpts = { scope: 'layout.admin.topBar' };

const TopBar = ({ currentUser, currentCompany }) => {
  const companyDomain = currentCompany?.domains[0];
  const newUiPreviewPath = !v.isBlank(companyDomain) ? `${companyDomain}/beta/new-ui` : null;
  const needsNewUILink = newUiPreviewPath && !currentCompany.enableNewClientUI;

  if (!currentUser) return null;

  return (
    <header className={classNames('topbar', styles.container)}>
      <div className={styles.sidebarToggle}>
        <SidebarToggleButton />
      </div>

      <Navbar className={classNames('top-navbar', styles.navbar)} expand="md">
        <NavbarBrand href="/" className="d-flex">
          <b className="logo-icon pl-0 pl-md-3">
            <OwnlyIcon />
          </b>
          <div className="logo-text ml-2 d-none d-sm-block">
            <OwnlyDarkText />
          </div>
        </NavbarBrand>

        <div className="ml-auto">
          <ul className="navbar-nav align-items-center">
            <li className="nav-item">
              <CompanySelect />
            </li>
            { needsNewUILink && (
              <li className="nav-item d-none d-md-block pl-3">
                <a href={newUiPreviewPath} target="_blank" rel="noreferrer" className="btn btn-outline-secondary">
                  <i className="mr-2 ml-1">
                    <MdFiberNew size="1.2rem" />
                  </i>
                  {i18n.t('previewNewUi', i18nOpts)}
                </a>
              </li>
            )}
            <li className="nav-item d-none d-md-block pl-3">
              <InviteButton />
            </li>
            { !v.isBlank(companyDomain) && (
              <li className="nav-item d-none d-md-block pl-3">
                <a href={companyDomain} target="_blank" rel="noreferrer" className="btn btn-outline-secondary">
                  {i18n.t('viewSite', i18nOpts)}
                </a>
              </li>
            )}
            <li className="dropdown nav-item pl-3 d-none d-md-inline-block">
              <UserMenuDropdown />
            </li>
            <li className="nav-item d-inline-block d-md-none pl-3">
              <SidebarToggleButton />
            </li>
          </ul>
        </div>
      </Navbar>
    </header>
  );
};

TopBar.propTypes = {};

TopBar.defaultProps = {};

export default connect((store) => ({
  currentUser: store.users.currentUser,
  currentCompany: store.companies.currentCompany
}), {})(TopBar);
