import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import i18n from 'i18n-js';
import { TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';

import { Tabs } from './utils';
import {
  getLastSevenDaysRange,
  getLastSevenDaysRangeOutput,
  setDaysRangeInput
} from '../../../utils/dateUtils';
import DateFilter from '../../common/DateFilter';
import NavTabs from './NavTabs';
import Verifications from './Verifications';
import Marketing from './Marketing';
import { isMarketingReportEnabled } from '../../../utils/companyUtils';

const i18nOpts = { scope: 'components.admin.insights.index' };

const Insights = ({ currentCompany }) => {
  const [activeTab, setActiveTab] = useState(Tabs.VERIFICATIONS);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [dateRange, setDateRange] = useState(getLastSevenDaysRange());
  const [customDateRange, setCustomDateRange] = useState(getLastSevenDaysRangeOutput());

  const onPickedDate = (event, picker) => {
    const pickedDate = [
      picker.startDate.startOf('day').toDate(),
      picker.endDate.endOf('day').toDate()
    ];

    setDateRange(pickedDate);
    setCustomDateRange(setDaysRangeInput(pickedDate));
  };

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />

      <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
        <div className="flex-grow-1">
          <h2 className="mb-0">{i18n.t('title', i18nOpts)}</h2>
        </div>
        <div className="ml-auto d-flex justify-content-end flex-wrap gap-3">
          <DateFilter dateRange={dateRange} onDatePicked={onPickedDate} containerClassName="my-0" />
        </div>
      </div>

      <NavTabs activeTab={activeTab} onChange={onTabChange} />

      <TabContent activeTab={activeTab} className="py-4">
        <TabPane tabId={Tabs.VERIFICATIONS}>
          <Verifications company={currentCompany} dateRange={customDateRange} />
        </TabPane>
        {isMarketingReportEnabled() && (
          <TabPane tabId={Tabs.MARKETING}>
            <Marketing company={currentCompany} dateRange={customDateRange} />
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};

Insights.propTypes = {};

Insights.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany
}))(Insights);
