import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import { navigate } from 'gatsby';
import i18n from 'i18n-js';
import classNames from 'classnames';

import styles from './FinancialReport.module.scss';
import { getCustomer, getCustomerFinancialReport } from '../../../../store/actions/customerActions';
import ReportHeader from './components/ReportHeader';
import { isMasterAdmin } from '../../../../utils/authUtils';
import useHomeRental from '../../../../hooks/useHomeRental';
import { BankVerificationProviders } from '../../../../constants';
import FinancialReportFlinks from './FinancialReportFlinks';
import FinancialReportPlaid from './FinancialReportPlaid';

const i18nOpts = { scope: 'components.admin.customers.financialReport.index' };

function hasAffordability(customer) {
  const { isFinancialVerified, companyContact } = customer;
  const isFinancialVerifiedAndGranted = isFinancialVerified
    && companyContact.verifiedFinancialGranted;
  const { affordability } = companyContact;

  if (!isFinancialVerifiedAndGranted) return false;
  return !!affordability;
}

const FinancialReport = ({
  id, customer, financialReport, currentCompany, ...props
}) => {
  const [mounted, setMounted] = useState(false);
  const reportSettings = currentCompany.reportSettings || {};
  const { typeI18nKey } = useHomeRental();

  const loadCustomerAndReport = () => {
    setMounted(false);

    const customerId = parseInt(id, 10);

    const variables = {
      id: customerId,
      withParty: true,
      withAffordability: true,
      withPropertyInfo: true,
      companyId: currentCompany.id,
      excludedPartyFromCompanyContact: true
    };

    props.getCustomer(variables)
      .then(({ value: { data: { item } } }) => {
        if (!hasAffordability(item)) return Promise.reject(new Error('Customer without Bank Verification'));

        return props.getCustomerFinancialReport({ contactId: customerId });
      })
      .then(() => {
        setMounted(true);
      })
      .catch(() => navigate('/admin/customers'));
  };

  useEffect(() => {
    if (!reportSettings.enabledFinancialReport || !isMasterAdmin()) {
      navigate('/admin/customers');
      return;
    }

    loadCustomerAndReport();
  }, []);

  if (!mounted) return <div className="text-center my-5"><Spinner size="lg" /></div>;

  return (
    <div>
      <Helmet title={customer.name} />

      <div className="d-flex justify-content-between align-items-end flex-wrap">
        <h1 className={classNames(styles.title)}>{i18n.t(`${typeI18nKey}.title`, i18nOpts)}</h1>
        <div>
          <ReportHeader />
        </div>
      </div>

      <hr className={styles.headerSeparator} />

      {financialReport?.provider === BankVerificationProviders.FLINKS && <FinancialReportFlinks />}

      {financialReport?.provider === BankVerificationProviders.PLAID && <FinancialReportPlaid />}
    </div>
  );
};

FinancialReport.propTypes = {};

FinancialReport.defaultProps = {};

export default connect((store) => ({
  customer: store.customers.customer,
  financialReport: store.customers.financialReport,
  currentCompany: store.companies.currentCompany
}), {
  getCustomer,
  getCustomerFinancialReport
})(FinancialReport);
